<template>
  <div class="switch__container" v-bind:class="{'switch__type': type}">
    <div
      v-on:click="switchStatusF"
      class="switch__text"
      v-bind:class="{'switch__text--active': !status, _mobile: isMobile, 'text__type--mobile': type}"
    >{{text_1}}</div>
    <div class="switch__wrapper" v-bind:class="{'switch__wrapper-type': type}">
      <label class="switch" :for="id">
        <input class="switch__button" :id="id" type="checkbox" :class="{_active: status}"/>
        <span class="switch__span" v-on:click="switchStatus"></span>
      </label>
    </div>
    <div
      v-on:click="switchStatusT"
      class="switch__text"
      v-bind:class="{'switch__text--active': status, _mobile: isMobile, 'text__type--mobile': type}"
    >{{text_2}}</div>
  </div>
</template>
<style lang="scss" scoped>
$green: #4cd964;
$grey: #e5e5e5;
$lightgrey: #fefefe;
$white: #fff;
$black: #000;

*,
*:after,
*:before {
  box-sizing: border-box;
}

input[type="checkbox"]:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.switch__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 16px; // ie 11 Fix
}

.switch {
  &__type {
    & .switch__span::after {
      background: #e5190a;
      box-shadow: none;
    }

    & .switch__span::before {
      background: rgba(151, 151, 151, 0.3);
    }

    & .switch__text {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      color: #7a7a7c;
      padding-bottom: 1px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.4);
      cursor: pointer;
    }

    & .switch__text--active {
      color: #242428;
      border-bottom: 1px solid transparent;
    }
  }

  &__wrapper {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;

    &-type {
      margin: -3px 20px 0;
    }
  }

  &__text {
    display: inline;
    color: #ffffff;
    font-size: 14px;
    font-family: "PT Root UI", sans-serif;
    font-weight: 400;
    opacity: 0.6;
    mix-blend-mode: normal;
    cursor: pointer;
    &--active {
      opacity: 1;
    }
  }

  &__button {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &._active + .switch__span:before {
      //background: $green;
      //border: 48px solid $green;
    }

    &._active + .switch__span:after {
      content: "";
      left: 15px;
    }
  }

  &__span {
    position: relative;
    display: block;
    width: 30px;
    height: 15px;
    cursor: pointer;
    border-radius: 100%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      transition: 0.25s ease all;
      -moz-transition: 0.25s ease all;
      -webkit-transition: 0.25s ease all;
    }

    &:before {
      display: block;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background: rgba(#ffffff, 0.1);
    }

    &:after {
      content: "";
      top: 0px;
      left: 0px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: $white;
      box-shadow: 0px 9px 3px rgba($black, 0.05), 0px 6px 6px rgba($black, 0.1),
        0px 9px 9px rgba($black, 0.05), 0px 0px 0px 2px rgba($black, 0.04);
    }
  }
}

@media (max-width: 767px) {
  .header .switch__container, .header .switch__wrapper {
    display: none;
  }
  .header .switch__container._openMobile {
    display: flex;
    width: 150px;
  }
  .header .switch__container {
    position: relative;
    min-height: 36px;
    width: 180px;
  }
  .header .switch__text._mobile {
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    z-index: 1;
    opacity: 1;
    justify-content: center;
    &.switch__text--active {
      z-index: -1;
      opacity: 0;
    }
  }

  .header .switch__text._mobile.text__type--mobile {
    background-color: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    width: 180px;
  }
  .switch__type .switch__text {
    line-height: 110%;
    text-align: center;
    border: none;
  }
}
</style>
<script>
export default {
  name: "component_switch_button",
  props: {
    id: {
      default: () => {
        return "switch";
      }
    },
    text_1: {
      default: () => {
        if (window.innerWidth < 768) {
          return "Switch to Russian";
        } else {
          return "Ru";
        }
      }
    },
    text_2: {
      default: () => {
        if (window.innerWidth < 768) {
          return "Switch to English";
        } else {
          return "Eng";
        }
      }
    },
    type: {
      default: () => {
        return false;
      }
    },
    isMobile: {
      default: () => {
        if (window.innerWidth < 768) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  data: function() {
    return {
      en: 'en',
      status: location.href.split('/')[3] == 'en',
      statusType: false
    };
  },
  watch: {
    status: function($args) {

      if (!this.$props.type) {
        let urlArr = location.href.split('/');
        urlArr = urlArr.slice(3);
        if ($args) {
          if ( urlArr[0] != this.en) {
            location.href = '/' + this.en + '/' + urlArr.join('/');
          }
        } else  {
          if ( urlArr[0] == this.en) {
            urlArr = urlArr.slice(1);
            location.href = '/' + urlArr.join('/');
          }
        }
      } else {
        this.$emit("status", this.statusType); // для переключения в модальном окне
      }
    }
  },

  beforeCreate() {
    window.onresize = () => {
      this.resize();
    };
  },
  created() {
    if (this.$props.type) {
      this.status = false;
    }
  },
  methods: {
    switchStatusF() {
      this.status = false;
      if (this.$props.type) {
        this.statusType = false;
      }
    },
    switchStatusT() {
      this.status = true;
      if (this.$props.type) {
        this.statusType = true;
      }
    },
    switchStatus() {
      this.status = !this.status;
      if (this.$props.type) {
        this.statusType = !this.statusType;
      }
    },
    resize() {
      window.innerWidth < 768
        ? (this.isMobile = true)
        : (this.isMobile = false);
    }
  }
};
</script>