<template>
    <div id="file-drag-drop" class="dad">
        <form ref="fileform" class="dad__form"
              :class="{
                  'dad__form--active': DropState !== 'NoDrag',
                  'dad__form--active--active': DropState === 'DragFromInput'
                }"
        >
            <input type="file" :disabled="disabled" id="FileInput" hidden v-on:change="uploadFile" multiple/>
            <!-- STATES -->
            <!--    0 - no drag-->
            <div :disabled="disabled" v-if="DropState === 'NoDrag'"  class="dad__nodrag" >
                <label class="dad__header dad__header--first" for="FileInput">
                    <img class="dad__header-image" src="./../../../assets/image/icon/modal-clip.png" alt="file">
                    <svg class="dad__header-image _gray" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.2906 8.92043C8.5641 8.64516 8.5641 8.19785 8.2906 7.92258C8.01709 7.64731 7.57265 7.64731 7.29915 7.92258L3.58974 11.6559C2.95726 12.2925 2.61538 13.1183 2.61538 14.0129C2.61538 14.9075 2.95726 15.7333 3.58974 16.3699C4.88889 17.6774 6.99145 17.6774 8.2735 16.3699L16.6496 7.93979C17.5214 7.06237 18 5.89247 18 4.65376C18 3.41505 17.5214 2.24516 16.6496 1.36774C14.8376 -0.455914 11.9145 -0.455914 10.1026 1.36774L1.74359 9.78065C0.615385 10.9161 0 12.4129 0 14.0129C0 15.6129 0.615385 17.1269 1.74359 18.2452C2.87179 19.3806 4.35897 20 5.94872 20C7.53846 20 9.04273 19.3806 10.1538 18.2452L13.8632 14.5118C14.1368 14.2366 14.1368 13.7892 13.8632 13.514C13.5897 13.2387 13.1453 13.2387 12.8718 13.514L9.16239 17.2473C8.30769 18.1075 7.16239 18.5892 5.94872 18.5892C4.73504 18.5892 3.58974 18.1075 2.73504 17.2473C1.88034 16.3871 1.40171 15.2344 1.40171 14.0129C1.40171 12.7914 1.88034 11.6387 2.73504 10.7785L11.094 2.36559C11.7094 1.74624 12.5128 1.41935 13.3675 1.41935C14.2222 1.41935 15.0427 1.76344 15.641 2.36559C16.2564 2.98495 16.5812 3.79355 16.5812 4.65376C16.5812 5.51398 16.2393 6.33979 15.641 6.94194L7.26496 15.372C6.52991 16.1118 5.31624 16.1118 4.5641 15.372C4.20513 15.0108 4 14.529 4 14.0129C4 13.4968 4.20513 13.0151 4.5641 12.6538L8.2906 8.92043Z" fill="#D3D3D4"/>
                    </svg>
                    <template>
                        {{title}}
                    </template>
                </label>
                <div class="dad__body">
                    <template v-if="isSupport">
                        {{description}}
                    </template>
                    <template v-else>

                        {{elseDescription}}
                    </template>
                </div>
            </div>
            <!--    1 - drag-->
            <div v-if="DropState === 'DragStart' || DropState === 'DragFromInput'"  class="dad__dragstart">
                <div class="dad__header">
                    <img src="./../../../assets/image/cloud.png" alt="cloud">
                    {{fileZone}}
                </div>
                <div class="dad__body">
                    {{clickToDownload[0]}}
                    <span>{{clickToDownload[1]}}</span>
                    {{clickToDownload[2]}}
                </div>
            </div>
            <!--      <div v-if="DropState === 'DragStart' || DropState === 'DragFromInput'" class="dad__info">Суммарный вес файлов не должен превышать 25мб</div>-->

            <!--    2 - drop-->
            <div v-if="DropState === 'DropSuccess'" class="dad__success">
                <div class="dad__header">
                    <div class="dad__count">{{files.length}} {{getCurrentText}}</div>
                    <label for="FileInput" class="dad__more" v-if="files.length < 3">
                        <img src="./../../../assets/image/cloud.png" alt="cloud">
                        {{loadMoreFiles}}
                    </label>
                </div>
                <div class="dad__body">
                    <div class="dad__item"
                         v-for="(item, key) in files"
                    >

                        <div class="dad__item-col">
                            <img src="./../../../assets/image/icon/file.svg" alt="file">
                        </div>

                        <div class="dad__item-col">
                            <span>{{item.name}}</span>
                            <span>{{getCurrentFileSize(item.size)}}</span>
                        </div>

                        <div class="dad__item-col">
                            <button class="dad__button" type="button" v-on:click="removeFile(key)">
                                <img src="./../../../assets/image/icon/file_plus.svg" alt="file_plus" />
                            </button>
                        </div>

                    </div>
                    <div class="dad__item"
                         v-for="(item, key) in errors"
                    >

                        <div class="dad__item-col">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.95827 19.9995C4.99562 19.9995 0.958496 15.9624 0.958496 10.9998C0.958496 6.03712 4.99562 2 9.95827 2C14.9209 2 18.958 6.0377 18.958 10.9998C18.958 11.2838 18.728 11.5144 18.4434 11.5144C18.1594 11.5144 17.9288 11.2843 17.9288 10.9998C17.9288 6.60449 14.353 3.02812 9.95769 3.02812C5.56183 3.02812 1.98603 6.60449 1.98603 10.9998C1.98603 15.3951 5.56183 18.9709 9.95769 18.9709C12.7696 18.9709 15.4037 17.4635 16.8323 15.0375C16.9766 14.7923 17.2918 14.7118 17.5364 14.8555C17.781 14.9992 17.8621 15.3145 17.719 15.5596C16.1073 18.298 13.133 19.9995 9.95827 19.9995Z" fill="#E5190A"/>
                                <path d="M18.4431 11.5143C18.3544 11.5143 18.264 11.4911 18.1823 11.443L14.9594 9.53977C14.7148 9.39546 14.6337 9.08019 14.778 8.83562C14.9229 8.59105 15.2382 8.50991 15.4827 8.6548L18.2947 10.3152L20.1046 7.72289C20.2675 7.48991 20.588 7.43312 20.8204 7.59539C21.0533 7.75766 21.1101 8.07815 20.9479 8.31113L18.865 11.2935C18.7653 11.4366 18.6059 11.5143 18.4431 11.5143Z" fill="#E5190A"/>
                            </svg>
                        </div>

                        <div class="dad__item-col">
                            <span>{{item.name}}</span>
                            <span>{{item.description}}</span>
                        </div>

                        <div class="dad__item-col">
                            <button class="dad__button" type="button" v-on:click="removeError(key)">
                                <img src="./../../../assets/image/icon/file_plus.svg" alt="file_plus" />
                            </button>
                        </div>

                    </div>
                </div>
            </div>

<!--                  <div v-if="showCountError" class="dad__info">Нельзя загрузить больше 3-х файлов</div>-->
            <!--    3 - -->

        </form>
        <div class="dad__item"
             v-if="showCountError"
        >

            <div class="dad__item-col">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16.5C10.7048 16.5 10.4619 16.4153 10.2714 16.2458C10.0905 16.0678 10 15.8559 10 15.6102C10 15.3644 10.0905 15.1568 10.2714 14.9873C10.4619 14.8093 10.7048 14.7203 11 14.7203C11.2952 14.7203 11.5333 14.8093 11.7143 14.9873C11.9048 15.1568 12 15.3644 12 15.6102C12 15.8559 11.9048 16.0678 11.7143 16.2458C11.5333 16.4153 11.2952 16.5 11 16.5ZM10.3286 7.5H11.6714V13.8559H10.3286V7.5Z" fill="#F29718"/>
                    <path d="M21.4375 19.6875V20.1875C21.6155 20.1875 21.7801 20.0929 21.8696 19.939C21.9592 19.7852 21.9602 19.5953 21.8723 19.4405L21.4375 19.6875ZM11 1.3125L11.4348 1.06555C11.3459 0.909131 11.1799 0.8125 11 0.8125C10.8201 0.8125 10.6541 0.909131 10.5652 1.06555L11 1.3125ZM0.5625 19.6875L0.127743 19.4405C0.0398244 19.5953 0.040824 19.7852 0.130368 19.939C0.219911 20.0929 0.384494 20.1875 0.5625 20.1875V19.6875ZM21.8723 19.4405L11.4348 1.06555L10.5652 1.55945L21.0027 19.9345L21.8723 19.4405ZM10.5652 1.06555L0.127743 19.4405L0.997257 19.9345L11.4348 1.55945L10.5652 1.06555ZM0.5625 20.1875H21.4375V19.1875H0.5625V20.1875Z" fill="#F29718"/>
                </svg>
            </div>

            <div class="dad__item-error dad__item-col" >
                <span v-for="(item, index) in errorMsg" :key="index">{{item}}</span>
            </div>

        </div>
    </div>


</template>
<script>

    export default {
        name: "drag-and-drop",
        props: {
            disabled: {
                default: () => {
                    return false;
                }
            },
            title: {
                default: () => {
                    return "";
                }
            },
            description: {
                default: () => {
                    return "";
                }
            },
            elseDescription: {
                default: () => {
                    return "";
                }
            },
            fileZone: {
                default: () => {
                    return "";
                }
            },
            clickToDownload: {
                default: () => {
                    return "";
                }
            },
            loadMoreFiles: {
                default: () => {
                    return "";
                }
            },
            errorMsg: {
                default: () => {
                    return [];
                }
            },
            filesText: {
                default: () => {
                    return ["файл", "файла"];
                }
            },
            tooBig:{
                default: () => {
                    return {
                        name: 'Суммарный вес файлов превышает лимит в 25 Мб.',
                        description: 'Загрузите другие файлы',
                    }
                }
            },
            otherFormat:{
                default: () => {
                    return {
                        name: 'Формат файла(ов) не подходит',
                        description: 'попробуйте загрузить другие файлы',
                    }
                }
            }
        },
        data() {
            return {
                events: [
                    "drag",
                    "dragstart",
                    "dragend",
                    "dragover",
                    "dragenter",
                    "dragleave",
                    "drop"
                ],
                timeout: null,
                dragAndDropCapable: false,
                files: [],
                errors: [],
                showCountError: false,
                isSupport: window.isSupport || false,
                DropState: "NoDrag",
                DropArray: {
                    dragend: "NoDrag",
                    drop: "DropSuccess",
                    dragover: "DragStart"
                },

                FileValidation: ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']
            };
        },

        watch: {
            disabled: function(e) {
                if (e) {
                    this.files = [];
                    this.DropState = "NoDrag";
                }
            },

            files: function (e) {

            }
        },

        computed: {

            getCurrentText: function() {
                return this.files.length <= 1 ? this.filesText[0] : this.filesText[1];
            },
            /**
             * @return {number}
             */
            MaxFilesSize: function () {
                let current_size = 25; // метров
                this.files.map(
                    (item, n) => {
                        current_size = current_size - item.size / 1024 / 1024;
                    }
                );
                return parseInt(current_size);
            }
        },

        mounted: function() {
            this.dragAndDropCapable = this.determineDragAndDropCapable();

            if (this.dragAndDropCapable) {

                this.events.forEach(evt => {
                    this.$refs.fileform.addEventListener(
                        evt,
                        e => {
                            e.preventDefault();
                            e.stopPropagation();
                        },
                        false
                    );
                });

                this.$refs.fileform.addEventListener(
                    "dragover",
                    () => {
                        if(!this.disabled) {
                            clearTimeout(this.timeout);
                            this.DropState = "DragFromInput";
                        }
                    },
                    false
                );

                document.addEventListener(
                    "dragover",
                    () => {
                        if(!this.disabled) {
                            clearTimeout(this.timeout);
                            this.DropState = this.DropArray["dragover"];
                        }
                    },
                    false
                );

                this.$refs.fileform.addEventListener(
                    "drop",
                    e => {
                        if(!this.disabled) {
                            for(let i = 0; i < e.dataTransfer.files.length; i++) {
                                // this.FileValidation.map(
                                //     (item, n) => {
                                //         console.log(e.dataTransfer.files[i].type.indexOf(item) === -1);
                                //         console.log(item);
                                //         if(e.dataTransfer.files[i].type.indexOf(item) === -1) {
                                //             e.dataTransfer.files[i].error = false;
                                //         } else {
                                //             e.dataTransfer.files[i].error = true;
                                //             return true;
                                //         }
                                //
                                //     }
                                // )
                                if(this.FileValidation.indexOf( e.dataTransfer.files[i].type) === -1) {
                                    e.dataTransfer.files[i].error = false;
                                } else {
                                    e.dataTransfer.files[i].error = true;
                                }
                            }

                                for (let i = 0; i < e.dataTransfer.files.length; i++) {

                                    if(e.dataTransfer.files[i].error) {
                                        if (e.dataTransfer.files[i].size <= this.MaxFilesSize * 1024 * 1024) {
                                            if (this.files.length <= 2) {
                                                this.DropState = this.DropArray["drop"];
                                                this.files.push(e.dataTransfer.files[i]);
                                                if(e.dataTransfer.files.length === 1) {
                                                    this.errors = [];
                                                }
                                            } else {
                                                this.DropState = this.DropArray["drop"];
                                                this.showCountError = true;
                                            }
                                        } else {
                                            this.errors = [];
                                            this.DropState = this.DropArray["drop"];
                                            this.errors.push(this.tooBig);
                                        }
                                    } else {
                                            this.errors = [];
                                            this.DropState = this.DropArray["drop"];
                                            this.errors.push(this.otherFormat);
                                    }

                                }

                        }
                    },
                    false
                );

                document.addEventListener(
                    "dragleave",
                    () => {
                        if(!this.disabled) {
                            this.timeout = setTimeout(() => {
                                if (this.files.length <= 0) {
                                    this.DropState = this.DropArray["dragend"];
                                } else {
                                    this.DropState = this.DropArray["drop"];
                                }
                            }, 500);
                        }
                    },
                    false
                );
            }

        },

        methods: {
            determineDragAndDropCapable: function() {
                let div = document.createElement("div");

                return (
                    ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
                    "FormData" in window &&
                    "FileReader" in window
                );
            },

            removeFile: function(key) {
                this.files.splice(key, 1);

                this.showCountError = false;

                if (this.files.length === 0) {
                    this.DropState = "NoDrag";
                }
            },

            removeError: function() {
                this.errors = [];

                if (this.files.length === 0) {
                    this.DropState = "NoDrag";
                }
            },

            getCurrentFileSize: function(byte) {
                if (parseInt(byte / 1024) <= 1000) {
                    return parseInt(byte / 1024) + " Кб";
                }
                return (byte / 1024 / 1024).toFixed(1) + " Мб";
            },

            uploadFile: function($event) {
                //this.files.push($event.target.files[0]);
                this.DropState = "DropSuccess";


                for(let i = 0; i < $event.target.files.length; i++) {
                    if(this.FileValidation.indexOf( $event.target.files[i].type) === -1) {
                        $event.target.files[i].error = false;
                    } else {
                        $event.target.files[i].error = true;
                    }
                }

                for (let i = 0; i < $event.target.files.length; i++) {

                    if($event.target.files[i].error) {
                        if ($event.target.files[i].size <= this.MaxFilesSize * 1024 * 1024) {
                            if (this.files.length <= 2) {
                                this.DropState = this.DropArray["drop"];
                                this.files.push($event.target.files[i]);
                                if($event.target.files.length === 1) {
                                    this.errors = [];
                                }
                            } else {
                                this.DropState = this.DropArray["drop"];
                                this.showCountError = true;
                            }
                        } else {
                            this.errors = [];
                            this.DropState = this.DropArray["drop"];
                            this.errors.push(this.tooBig);
                        }
                    } else {
                        this.errors = [];
                        this.DropState = this.DropArray["drop"];
                        this.errors.push(this.otherFormat);

                    }

                }
            }
        }
    };


</script>
<style scoped lang="scss">
    .dad {
        &__form {
            display: block;
            min-height: 90px;
            height: auto;
            width: 320px;
            margin: auto;
            text-align: center;
            border-radius: 4px;

            &--active {
                background: #FFF8F8;
                border: 1px dashed #D3D3D4;
                box-sizing: border-box;
                border-radius: 3px;

                &--active {
                    border: 1px solid #D3D3D4;
                }
            }
        }

        &__nodrag {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .dad__header-image._gray {
                display: none;
                margin-right: 12px;
            }

            &[disabled] {
                .dad__header, .dad__body {
                    color: #242428;
                }
                .dad__header-image:not(._gray) {
                    display: none;
                }
                .dad__header-image._gray {
                    display: block;
                }
            }
        }

        &__nodrag:not([disabled]) {

            & .dad__header {
                transition: opacity 0.25s;
                cursor: pointer;
                &:hover {
                    opacity:0.5;
                }
            }
        }

        &__dragstart {
            & .dad__header {
                justify-content: center;
                margin-top:10px;
            }

            & .dad__body {
                text-align: center;

                & span {
                    font-weight: bold;
                }
            }
        }

        &__success {
            & .dad__header {
                justify-content: space-between;
                align-items: center;
            }
        }

        &__header {

            font-family: 'PT Root UI', sans-serif;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            color: #E5190A;

            display: flex;
            align-items: center;


            & img {
                margin-right:12px;
            }
        }

        &__count {
            margin: 10px 0 0 20px;
            color:#242428;
            font-size:12px;
        }

        &__more {
            margin-top:9px;
            margin-right:24px;
            font-size: 12px;
            line-height: 15px;

            color: #7A7A7C;
            font-weight: 300;
            display: flex;
            transition: opacity 0.25s;
            cursor: pointer;
            align-items: center;
            & img {
                margin-right:4px;
                width:16px;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        &__item {
            display: flex;
            justify-content: space-between;
            margin-top:16px;

            &:last-child {
                padding-bottom:20px;
            }

            &-col {
                &:nth-child(1) {
                    margin-left:22px;
                }

                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 50%;
                    margin-left: 8px;
                    max-width: 225px;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    & :nth-child(1) {
                        font-size: 12px;
                        line-height: 15px;

                        color: #E5190A;
                    }
                }

                &:nth-child(3) {
                    margin-right: 24px;
                }
            }

            &-error {
                & span:first-child {
                    font-size: 12px;
                    line-height: 15px;
                    color:#F29718;
                    text-align: left;
                }

                & span:last-child {
                    font-size: 12px;
                    line-height: 15px;
                    text-align: left;
                    color: #7A7A7C;
                }
            }
        }

        &__button {
            border:none;
            outline: none;
            background-color: transparent;
            cursor: pointer;

            opacity:1;

            transition: opacity 0.25s;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 0.5;
            }

        }

        &__body {
            margin-top:5px;
            font-family: 'PT Root UI', sans-serif;
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            width: 100%;
            /* or 167% */

            color: #242428;
        }

        &__info {
            position: relative;
            top:23px;
            font-family: 'PT Root UI', sans-serif;
            font-size: 12px;
            line-height: 15px;
            text-align: center;

            /* Серый текст */
            color: #B3B3B3;
        }
    }


    @media (max-width: 767px) {
        .dad__nodrag .dad__body {
            display: none;
        }
        .dad__form {
            min-height: 30px;
        }
    }
</style>