<template>
    <div class="clients__column" v-bind:class="{'clients__column-type': type, 'clients__column-type-cols': type && cols}">
        <slot v-bind:type="type" v-bind:cols="cols" v-bind:now="now"></slot>
    </div>
</template>
<script>
    export default {
        props: ['type', 'cols', 'now']
    }
</script>
<style lang="scss" scoped>


</style>