export const data = {
  breadcrumbs: {
      arr: [
          {
              title: 'Главная',
              link: '/'
          },
          {
              title: 'Тестовые данные 1',
              link: '/'
          },
          {
              title: 'Тестовые данные 2',
              link: '/'
          }
      ]
  }
};