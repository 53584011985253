var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "questions" }, [
    _vm.show_title
      ? _c("h2", { staticClass: "questions__title h2" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "questions__wrapper" },
      _vm._l(_vm.data, function(item, n) {
        return _c(
          "div",
          { key: n, staticClass: "question" },
          [
            _c(
              "div",
              {
                staticClass: "question__problem h5",
                on: {
                  click: function($event) {
                    _vm.question_now === n
                      ? (_vm.question_now = null)
                      : (_vm.question_now = n)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(item.problem) +
                    "\n                "
                ),
                _c(
                  "button",
                  {
                    staticClass: "question__button",
                    class: {
                      "question__button--active": _vm.question_now === n
                    },
                    attrs: { type: "button" }
                  },
                  [
                    _c("img", {
                      staticClass: "question__minus",
                      attrs: {
                        alt: "close-answer",
                        src: require("./../../../assets/image/minus.svg")
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "question__plus",
                      attrs: {
                        alt: "close-answer",
                        src: require("./../../../assets/image/minus.svg")
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("transition-height", [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.question_now === n,
                    expression: "question_now === n"
                  }
                ],
                staticClass: "question__answer p html",
                domProps: { innerHTML: _vm._s(item.answer) }
              })
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }