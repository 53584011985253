<template>
    <section class="feedback" :class="{'feedback--type': type, 'feedback--white': color === 'white'}">

        <swiper class="feedback__check"  :options="swiperOption" ref="mySwiper">

                <slot name="items">

                </slot>
            <div class="feedback__nav"  slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev">
                <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z"
                          :fill="color !== 'white' ? '#E5190A': 'white'"/>
                </svg>
            </div>
            <div class="swiper-button-next" slot="button-next">
                <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z"
                          :fill="color !== 'white' ? '#E5190A': 'white'"/>
                </svg>
            </div>
        </swiper>
        <div class="feedback__cols" v-if="!type">
            <div class="feedback__cols-row" v-for="n in countCols">
                <div  class="feedback__col" 
                      :style="{'opacity': now_hover === n ? '0': '1'}"
                      v-for="m in 2"
                      :key="m">
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    import "swiper/dist/css/swiper.css";

    import { swiper, swiperSlide } from "vue-awesome-swiper";

    export default {
        props: {
            type: {
                default: () => {
                    return false;
                }
            },
            color: {
                default: () => {
                    return "red";
                }
            }
        },
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                show: false,
                loadedRatio: 0,
                page: 1,
                numPages: 0,
                rotate: 0,
                progress_start: true,
                timeout: null,
                row_arr: [],
                anim_state: true,


                now_hover: null,

                swiperOption: {
                    slidesPerView: 5,
                    loop: false,
                    spaceBetween: 30,
                    pagination: {
                        el: ".feedback__nav",
                        clickable: true,
                        //modifierClass: "feedback__nav-"
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        hiddenClass: ".swiper-button-hidden"
                    },
                    breakpoints: {
                        1200: {
                            slidesPerView: 3,
                        },
                        767: {
                            //loop: true,
                            //freeMode: true,
                            pagination: {
                                type: 'bullets',
                                dynamicBullets: true,
                                dynamicMainBullets: 3,
                            },
                            slidesPerView: 1,
                        }
                    }
                }
            };
        },

        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            },
            countCols() {
                if (window.innerWidth < 1200 && window.innerWidth >= 767) {
                    return 3;
                } else if (window.innerWidth < 768) {
                    return 1;
                } else {
                    return 5;
                }
            }
        },

        mounted() {
            // setTimeout(() => {
            //     [].slice.call(
            //         document.querySelectorAll('.feedback img')
            //     ).forEach(function (element) {
            //         element.bicubicImgInterpolation()
            //     })
            // }, 100)
        },
        methods: {

            hide() {
                this.$modal.hide("hello-world");
            }
        }
    };

</script>
<style lang="scss">
    .feedback {
        position: relative;
        margin-top:120px;
        margin-bottom:calc(210px + 65px);

        &--white {
            & .swiper-pagination-bullet {
                background-color: rgba(255,255,255, 0.2);

            }

            & .swiper-pagination-bullet.swiper-pagination-bullet-active {
                &::after {
                    background-color: #fff;
                }
            }

            & .swiper-button-prev {

                border:1px solid rgba(255, 255, 255, 0.2);
                cursor: pointer;

                &:hover {

                    border:1px solid #fff;


                }
            }

            & .swiper-button-next {

                border:1px solid rgba(255, 255, 255, 0.2);
                cursor: pointer;

                &:hover {

                    border:1px solid #fff;


                }
            }
        }
        & .swiper-slide {
            height:300px;
        }
        &--type {
            margin-bottom:0;
        }
        &__wrapper {
            display: flex;
            justify-content: space-between;
        }

        &__column {
            //padding-top:400px;
            //margin-top:-400px;
            //border-right:1px solid #000;
            //border-left:1px solid #000;
        }

        &__item {
            //border: 7px solid #F2F2F2;
            box-sizing: border-box;
            cursor:pointer;
            transition: 0.5s;
            position: relative;
            top: 0;
            z-index: 4;
            user-select: none; -moz-user-select: none;
            height:100%;

            &:hover::after {
                opacity: 1;
            }
            &:hover::before {
                opacity:0;
            }
            &::before {
                content: "";
                opacity:1;
                transition: 0.5s;
                display: block;
                width:calc(100% - 14px);
                height:calc(100% - 14px);
                border:7px solid #F2f2f2;
                position: absolute;
                left:0;
                top:0;

            }
            &::after {
                transition: 0.5s;
                opacity: 0;
                content: "";
                width:94px;
                height:94px;
                background-color: #E5190A;
                box-shadow: 0 10px 50px rgba(229, 25, 10, 0.5);
                border-radius: 100%;
                display: block;
                background-image: url("./../../../assets/image/icon/view.svg");
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                left:calc((100% / 2) - (94px / 2));
                top:calc((100% / 2) - (94px / 2));
            }

            &:hover {
                box-shadow: 0 20px 28px rgba(36, 36, 40, 0.152146);
                top:-10px;
                //border: 7px solid #fff;
            }

            & > img {
                width:100%;
                height:100%;
            }
        }

        &__nav {
            margin-top:70px;
            display: flex;
            justify-content: center;

        }

        &__button {

            &-item {
                width:70px;
                height:70px;
                box-sizing: border-box;
                border-radius: 35px;

                display: flex;
                justify-content: center;
                align-items: center;

                position: absolute;
                cursor: pointer;
            }

            &-left {
                left:calc(-70px - 35px);
                top:calc((270px / 2) - (70px / 2));

                border:1px solid #979797;
                transform: rotate(180deg);
                transform: 0.5s;
                &:hover {

                    border:1px solid #E5190A;

                }
            }

            &-right {
                right:calc(-70px - 35px);
                top:calc((270px / 2) - (70px / 2));
            }
        }

        &__cols {
            display: flex;
            justify-content: space-between;
            &-row {
                /*width: 208px;*/
                width: calc((100% - 120px)/5);
                height:1px;
                position: relative;
                &:not(:last-of-type) {
                    margin-right: 20px;
                }
            }
        }

        &__col {
            position: absolute;
            top: -900px;
            //top:-100vh;
            //height:150vh;
            height: 1300px;
            width: 1px;
            background: rgba(36, 36, 40, 0.1);
            z-index: -1;
            transition: 0.5s;

            &:first-child {
                left:0;
            }

            &:last-child {
                right:0;
            }
        }
    }

    #buttons {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    /* Page content */
    .content {
        padding: 16px;
    }

    #pdfvuer {
        z-index: 9999;
        position: fixed;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:#242428;
        display: flex;
        align-items: center;
        flex-direction: column;

        & #buttons {
            height:200px;
            width:100%;
            background-color:#fff;
        }

        & .options {
            width:100%;
            height:100px;
            color:#fff;

            display: flex;
            align-items: center;
            justify-content: center;

        }
    }

    .swiper-pagination-bullet {
        width:30px;
        height:1px;
        background-color:rgba(229, 25, 10, .2);
        margin-right:10px;
        cursor: pointer;
        opacity:1;
        transition: 0.5s;
        position: relative;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width:60px;
        &::after {
            content: '';
            //animation: line-anim 5s linear;
            //animation-fill-mode: forwards;
            background-color:rgba(229, 25, 10, 1);
            height:1px;
            width:100%;
            display: block;
            position: absolute;
            top:0;
        }
    }

    .swiper-slide {
        height:auto;
    }
    .swiper-scrollbar {
        display: none;
    }
    .swiper-button-next {
        width:70px;
        height:70px;
        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        cursor: pointer;
        border:1px solid rgba(229, 25, 10, 0.2);
        right:calc(-70px - 35px);
        top:calc((410px / 2) - (70px / 2));
        background-image: none;
        transition: 0.5s;
        border-radius: 100%;
        z-index: 1;
        outline: none;


        & > svg * {
            transition: 0.5s;
        }

        &:hover {

            border:1px solid #E5190A;


        }
    }

    .swiper-button-prev {
        transition: 0.5s;
        width:70px;
        height:70px;
        box-sizing: border-box;
        border-radius: 35px;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        position: absolute;
        border:1px solid rgba(229, 25, 10, 0.2);
        left:calc(-70px - 35px);
        top:calc((410px / 2) - (70px / 2));
        background-image: none;
        transform: rotate(180deg);
        z-index: 1;
        outline: none;

        & > svg * {
            transition: 0.5s;
        }

        &:hover {

            border:1px solid #E5190A;


        }
    }

    .swiper-container {
        position: static;
        padding: 25px 25px 25px 25px;
        margin: -25px -25px -25px -25px
        //margin-right: -30px;
        //marign-left: -30px;
    }
    .slider-wrapper {
        width: calc(100% + 30px); //we need more width for the shadows on the edges
        padding: 0 15px; //space for the shadows
        margin-left: -15px; //put the div in its original position
        overflow: hidden; //hide the overflow
    }
    .slick-list {
        box-sizing: initial;
        padding: 25px 0px;
    }

    //.feedback__check:hover ~ .feedback__cols .feedback__cols-row {
    //  opacity:0;
    //}
    .feedback__check .feedback__item  {

    }
    @media (max-width: 1400px) {
        .swiper-button-next {
            right: calc(-70px - 4px);
            //right: -17vw;
        }
        .swiper-button-prev {
            left: calc(-70px - 4px);
            //left: -17vw;
        }

        .feedback__col {
            //top: -115vh;
            //height: 160vh;
        }
    }
    @media (max-width: 1200px) {
        .feedback {
            max-width: 616px;
            margin-left: auto;
            margin-right: auto;
        }
        .feedback__cols-row:not(:last-of-type) {
            margin-right: 28px;
        }
        .feedback__cols-row {
            width: calc((100% - 64px)/3);
        }
        .feedback__col {
            top: -750px;
            height: 1050px;
        }
        .swiper-button-next {
            //right: calc(-70px - 4px);
            right: -9.5vw;
        }
        .swiper-button-prev {
            //left: calc(-70px - 4px);
            left: -9.5vw;
        }
    }
    @media (max-width: 767px) {
        .feedback {
            max-width: 210px;
            margin-top: 64px;
            margin-bottom: 20px;

            &--white {
                .feedback__nav {
                    display: flex;
                    justify-content: flex-start;
                    width: 100% !important;

                    span {
                        &.swiper-pagination-bullet-active {
                            background: #fff;
                        }
                    }
                }
                .swiper-pagination-bullet {
                    background-color: rgba(255,255,255, 0.2);
                }

                .swiper-pagination-bullet {
                    width:30px;
                    min-width: auto;
                    height:1px !important;
                    left: 0 !important;
                    transform: scale(1) !important;
                    transition: 0.5s !important;
                }
                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    width: 60px;
                }
            }

        }
        .main__clients .feedback {
            margin-bottom: 215px;
        }
        .feedback__nav {
            //margin-top: 50px;
            display: block;

            margin: 50px auto 0;
            left: 0 !important;
            transform: none !important;
            white-space: nowrap;
            span {
                margin: 0 5px 0 0;
                transition: all .2s;
                &.swiper-pagination-bullet-active {
                    background: #E5190A;
                }
            }
        }
        .swiper-pagination-bullet {
            width: 17px;
            height: 2px;
            min-width: 17px;
            border-radius: 0;
            background-color:rgba(229, 25, 10, .2);
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 23px;
            &::after {
                display: none;
            }


        }
        .feedback__cols-row {
            width: 100%;
        }
        .feedback__col {
            top: -650px;
            height: 850px;
        }
        .swiper-button-prev, .swiper-button-next {
            width: 50px;
            height: 50px;
        }
        .swiper-container {
            padding: 8px;
            margin: -8px;
        }
        .swiper-button-next {
            right: -17.5vw;
        }
        .swiper-button-prev {
            left: -17.5vw;
        }
    }

</style>

