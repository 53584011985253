var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dad", attrs: { id: "file-drag-drop" } }, [
    _c(
      "form",
      {
        ref: "fileform",
        staticClass: "dad__form",
        class: {
          "dad__form--active": _vm.DropState !== "NoDrag",
          "dad__form--active--active": _vm.DropState === "DragFromInput"
        }
      },
      [
        _c("input", {
          attrs: {
            type: "file",
            disabled: _vm.disabled,
            id: "FileInput",
            hidden: "",
            multiple: ""
          },
          on: { change: _vm.uploadFile }
        }),
        _vm._v(" "),
        _vm.DropState === "NoDrag"
          ? _c(
              "div",
              { staticClass: "dad__nodrag", attrs: { disabled: _vm.disabled } },
              [
                _c(
                  "label",
                  {
                    staticClass: "dad__header dad__header--first",
                    attrs: { for: "FileInput" }
                  },
                  [
                    _c("img", {
                      staticClass: "dad__header-image",
                      attrs: {
                        src: require("./../../../assets/image/icon/modal-clip.png"),
                        alt: "file"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "dad__header-image _gray",
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M8.2906 8.92043C8.5641 8.64516 8.5641 8.19785 8.2906 7.92258C8.01709 7.64731 7.57265 7.64731 7.29915 7.92258L3.58974 11.6559C2.95726 12.2925 2.61538 13.1183 2.61538 14.0129C2.61538 14.9075 2.95726 15.7333 3.58974 16.3699C4.88889 17.6774 6.99145 17.6774 8.2735 16.3699L16.6496 7.93979C17.5214 7.06237 18 5.89247 18 4.65376C18 3.41505 17.5214 2.24516 16.6496 1.36774C14.8376 -0.455914 11.9145 -0.455914 10.1026 1.36774L1.74359 9.78065C0.615385 10.9161 0 12.4129 0 14.0129C0 15.6129 0.615385 17.1269 1.74359 18.2452C2.87179 19.3806 4.35897 20 5.94872 20C7.53846 20 9.04273 19.3806 10.1538 18.2452L13.8632 14.5118C14.1368 14.2366 14.1368 13.7892 13.8632 13.514C13.5897 13.2387 13.1453 13.2387 12.8718 13.514L9.16239 17.2473C8.30769 18.1075 7.16239 18.5892 5.94872 18.5892C4.73504 18.5892 3.58974 18.1075 2.73504 17.2473C1.88034 16.3871 1.40171 15.2344 1.40171 14.0129C1.40171 12.7914 1.88034 11.6387 2.73504 10.7785L11.094 2.36559C11.7094 1.74624 12.5128 1.41935 13.3675 1.41935C14.2222 1.41935 15.0427 1.76344 15.641 2.36559C16.2564 2.98495 16.5812 3.79355 16.5812 4.65376C16.5812 5.51398 16.2393 6.33979 15.641 6.94194L7.26496 15.372C6.52991 16.1118 5.31624 16.1118 4.5641 15.372C4.20513 15.0108 4 14.529 4 14.0129C4 13.4968 4.20513 13.0151 4.5641 12.6538L8.2906 8.92043Z",
                            fill: "#D3D3D4"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.title) +
                          "\n                    "
                      )
                    ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dad__body" },
                  [
                    _vm.isSupport
                      ? [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.description) +
                              "\n                    "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n\n                        " +
                              _vm._s(_vm.elseDescription) +
                              "\n                    "
                          )
                        ]
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.DropState === "DragStart" || _vm.DropState === "DragFromInput"
          ? _c("div", { staticClass: "dad__dragstart" }, [
              _c("div", { staticClass: "dad__header" }, [
                _c("img", {
                  attrs: {
                    src: require("./../../../assets/image/cloud.png"),
                    alt: "cloud"
                  }
                }),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.fileZone) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dad__body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.clickToDownload[0]) +
                    "\n                    "
                ),
                _c("span", [_vm._v(_vm._s(_vm.clickToDownload[1]))]),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.clickToDownload[2]) +
                    "\n                "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.DropState === "DropSuccess"
          ? _c("div", { staticClass: "dad__success" }, [
              _c("div", { staticClass: "dad__header" }, [
                _c("div", { staticClass: "dad__count" }, [
                  _vm._v(
                    _vm._s(_vm.files.length) + " " + _vm._s(_vm.getCurrentText)
                  )
                ]),
                _vm._v(" "),
                _vm.files.length < 3
                  ? _c(
                      "label",
                      { staticClass: "dad__more", attrs: { for: "FileInput" } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("./../../../assets/image/cloud.png"),
                            alt: "cloud"
                          }
                        }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.loadMoreFiles) +
                            "\n                    "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dad__body" },
                [
                  _vm._l(_vm.files, function(item, key) {
                    return _c("div", { staticClass: "dad__item" }, [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("div", { staticClass: "dad__item-col" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.getCurrentFileSize(item.size)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dad__item-col" }, [
                        _c(
                          "button",
                          {
                            staticClass: "dad__button",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeFile(key)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./../../../assets/image/icon/file_plus.svg"),
                                alt: "file_plus"
                              }
                            })
                          ]
                        )
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function(item, key) {
                    return _c("div", { staticClass: "dad__item" }, [
                      _c("div", { staticClass: "dad__item-col" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "22",
                              height: "22",
                              viewBox: "0 0 22 22",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M9.95827 19.9995C4.99562 19.9995 0.958496 15.9624 0.958496 10.9998C0.958496 6.03712 4.99562 2 9.95827 2C14.9209 2 18.958 6.0377 18.958 10.9998C18.958 11.2838 18.728 11.5144 18.4434 11.5144C18.1594 11.5144 17.9288 11.2843 17.9288 10.9998C17.9288 6.60449 14.353 3.02812 9.95769 3.02812C5.56183 3.02812 1.98603 6.60449 1.98603 10.9998C1.98603 15.3951 5.56183 18.9709 9.95769 18.9709C12.7696 18.9709 15.4037 17.4635 16.8323 15.0375C16.9766 14.7923 17.2918 14.7118 17.5364 14.8555C17.781 14.9992 17.8621 15.3145 17.719 15.5596C16.1073 18.298 13.133 19.9995 9.95827 19.9995Z",
                                fill: "#E5190A"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d:
                                  "M18.4431 11.5143C18.3544 11.5143 18.264 11.4911 18.1823 11.443L14.9594 9.53977C14.7148 9.39546 14.6337 9.08019 14.778 8.83562C14.9229 8.59105 15.2382 8.50991 15.4827 8.6548L18.2947 10.3152L20.1046 7.72289C20.2675 7.48991 20.588 7.43312 20.8204 7.59539C21.0533 7.75766 21.1101 8.07815 20.9479 8.31113L18.865 11.2935C18.7653 11.4366 18.6059 11.5143 18.4431 11.5143Z",
                                fill: "#E5190A"
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dad__item-col" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.description))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dad__item-col" }, [
                        _c(
                          "button",
                          {
                            staticClass: "dad__button",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeError(key)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./../../../assets/image/icon/file_plus.svg"),
                                alt: "file_plus"
                              }
                            })
                          ]
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.showCountError
      ? _c("div", { staticClass: "dad__item" }, [
          _c("div", { staticClass: "dad__item-col" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "22",
                  height: "22",
                  viewBox: "0 0 22 22",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M11 16.5C10.7048 16.5 10.4619 16.4153 10.2714 16.2458C10.0905 16.0678 10 15.8559 10 15.6102C10 15.3644 10.0905 15.1568 10.2714 14.9873C10.4619 14.8093 10.7048 14.7203 11 14.7203C11.2952 14.7203 11.5333 14.8093 11.7143 14.9873C11.9048 15.1568 12 15.3644 12 15.6102C12 15.8559 11.9048 16.0678 11.7143 16.2458C11.5333 16.4153 11.2952 16.5 11 16.5ZM10.3286 7.5H11.6714V13.8559H10.3286V7.5Z",
                    fill: "#F29718"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d:
                      "M21.4375 19.6875V20.1875C21.6155 20.1875 21.7801 20.0929 21.8696 19.939C21.9592 19.7852 21.9602 19.5953 21.8723 19.4405L21.4375 19.6875ZM11 1.3125L11.4348 1.06555C11.3459 0.909131 11.1799 0.8125 11 0.8125C10.8201 0.8125 10.6541 0.909131 10.5652 1.06555L11 1.3125ZM0.5625 19.6875L0.127743 19.4405C0.0398244 19.5953 0.040824 19.7852 0.130368 19.939C0.219911 20.0929 0.384494 20.1875 0.5625 20.1875V19.6875ZM21.8723 19.4405L11.4348 1.06555L10.5652 1.55945L21.0027 19.9345L21.8723 19.4405ZM10.5652 1.06555L0.127743 19.4405L0.997257 19.9345L11.4348 1.55945L10.5652 1.06555ZM0.5625 20.1875H21.4375V19.1875H0.5625V20.1875Z",
                    fill: "#F29718"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dad__item-error dad__item-col" },
            _vm._l(_vm.errorMsg, function(item, index) {
              return _c("span", { key: index }, [_vm._v(_vm._s(item))])
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dad__item-col" }, [
      _c("img", {
        attrs: {
          src: require("./../../../assets/image/icon/file.svg"),
          alt: "file"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }