var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("h2", { staticClass: "list__title h2" }, [_vm._v("Заголовок")]),
      _vm._v(" "),
      _c("ul", { staticClass: "list__ul" }, [
        _c("li", [_vm._v("1")]),
        _vm._v(" "),
        _c("li", [_vm._v("1")]),
        _vm._v(" "),
        _c("li", [_vm._v("1")]),
        _vm._v(" "),
        _c("li", [_vm._v("1")]),
        _vm._v(" "),
        _c("li", [_vm._v("1")]),
        _vm._v(" "),
        _c("li", [_vm._v("1")])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "list__subtitle p" }, [
        _vm._v("Тут Может быть опционально текст")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }