<template>
       <transition v-if="now[i]" mode="out-in" :name="n % 2 ? 'flip': 'flip1'">

           <div class="clients__group"
                v-if="n === now[i].now || n === now[i].now + 1"
           >
               <a class="clients__logo"
                  v-bind:class="{'clients__logo--hover': hover_id === i + 1}"
                  :href="link" target="__blank"
               >
                   <img :alt="imageAlt" :src="image"/>
               </a>
           </div>
       </transition>
</template>
<style lang="scss" scoped>
    .clients__group {
        position: absolute;
        left: 0;
        right: auto;
        bottom: auto;
        width:100%;
        &:nth-child(1) {
            top:340px
        }
        &:nth-child(2) {
            top:500px;
        }
        &:nth-child(3) {
            top:340px;
        }
        &:nth-child(4) {
            top:500px;
        }
    }

    .clients__logo {
        //transform: translateY(0);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        //&:nth-child(2n - 1) {
        //  animation: item-in-2 2s;
        //  animation-play-state: paused;
        //}
        //&:nth-child(2n) {
        //  animation: item-in 2s;
        //  animation-play-state: paused;
        //}

        & > img {
            transition: 0.5s;
            // filter: url('./../../../assets/image/filters.svg#grayscale');
            -webkit-filter: grayscale(1); /* Webkit Nightlies, Google Chrome Canary and Microsoft Edge*/
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
            opacity: 0.3;
        }

        &--hover, &:hover {
            & > img {
                -webkit-filter: grayscale(0);
                opacity: 1;
            }
        }

    }
    .flip-enter-active, .flip-leave-active {
        transition: all 0.3s ease-in-out; //ease-in-quad
    }

    .flip-enter-active {
        opacity:1;
    }

    .flip-enter {
        //transform: translateY(-300px);
        opacity:0;
    }

    .flip-leave-to {
        //transform: translateY(200px);
        opacity:0;

    }

    .flip1-enter-active, .flip1-leave-active {
        transition: all 0.3s ease-in-out; //ease-in-quad
    }

    .flip1-enter-active {
        opacity:1;
    }

    .flip1-enter {
        //transform: translateY(-250px);
        opacity:0;
    }

    .flip1-leave-to {
        //transform: translateY(250px);
        opacity:0;

    }
</style>
<script>
    export default {
        props: ['link', 'image', 'imageAlt', 'n', 'i', 'now', 'hover_id']
    }
</script>