var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "screen",
      class: { screen__main: _vm.url === "/" || _vm.url === "/en/" },
      style: {
        "clip-path":
          "polygon(0 0, 100% 0, 100% " + _vm.getClipPath + ", 0% 100%)",
        "-webkit-clip-path":
          "polygon(0 0, 100% 0, 100% " + _vm.getClipPath + ", 0% 100%)"
      }
    },
    [
      _c(
        "div",
        { staticClass: "screen__container container" },
        [
          _vm._t("information", null, {
            current: _vm.CurrentTime,
            iteration: _vm.Iteration
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("backgrounds"),
      _vm._v(" "),
      _vm.url === "/" || _vm.url === "/en/"
        ? _c(
            "div",
            { staticClass: "screen__cols" },
            _vm._l(_vm.getCols, function(item, n) {
              return _c(
                "div",
                {
                  key: "item_" + n,
                  staticClass: "screen__col",
                  class: { "screen__col--hover": n == _vm.col_now },
                  style: {
                    opacity: _vm.cols_arr[n].opacity,
                    transform: "translateY(" + _vm.cols_arr[n].data + "px"
                  },
                  attrs: { "data-n": n }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.cols_arr[n].percent,
                          expression: "cols_arr[n].percent"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.getPercent(n)))]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "screen__animation" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }