import detect from 'detect.js';
import Vue from 'vue';
import imagesLoaded from 'vue-images-loaded';
// import {parse} from '../node_modules/vue/src/compiler/parser/index.js';
import VTooltip from 'v-tooltip';
import VModal from "vue-js-modal";
import YmapPlugin from 'vue-yandex-maps';
import {Power1, TimelineMax, TweenMax} from "gsap";
import VueScrollmagic from "vue-scrollmagic";
import {swiper, swiperSlide} from "vue-awesome-swiper";
//import VueLazyload from 'vue-lazyload';
import {
    data as header_data,
    methods as header_methods,
    mounted as header_mounted,
} from './components/html/header/script';

import {data as footer_data, methods as footer_methods,} from './components/html/footer/script';

import {
    beforeCreate as main_beforeCreate,
    data as main_data,
    methods as main_methods,
    mounted as main_mounted,
} from './components/html/main-page/script';

import {data as main_client_data, methods as main_client_methods} from "./components/html/main-page-client/script";

import {
    data as about_data,
    methods as about_methods,
    mounted as about_mounted
} from './components/html/about-page/script';

import {
    beforeCreate as contact_beforeCreate,
    data as contact_data,
    methods as contact_methods,
} from "./components/html/contact-page/script";

import {data as methods_data,} from "./components/html/methods-page/script";

import {data as dones_data,} from "./components/html/dones-page/script";

import {data as questions_data, mounted as questions_mounted} from "./components/html/questions/script";

import {data as recruting_data} from "./components/html/recruting-page/script";

import {data as work_data, methods as work_methods} from "./components/html/work-page/script";

import {data as breadcrumbs_data} from "./components/html/breadcrumbs/script";

import pageFirstScreen from './components/vue/page-first-screen/page-first-screen.vue';
import lineCircleGraph from './components/vue/line-circle-graph/index.vue';
import lineDots from './components/vue/line-circle-graph/dots.vue';
import readMore from './components/vue/read-more/read-more.vue';
import lineChart from './components/vue/line-chart/index.vue';
import CmpClients from './components/vue/clients/clients.vue';
import feedback from './components/vue/feedback/index.vue';
import socialIcon from './components/vue/social-icon/index.vue';
import articleSlider from './components/vue/article-slider/article-slider.vue';
import transitionHeight from './components/vue/transition-height/index.vue';
import breadcrumbs from './components/vue/breadcrumbs/breadcrumbs.vue';
import CmpArticle from './components/vue/article/article.vue';
import SwitchButton from "./components/vue/switch_button/switch_button.vue";
import CmpList from "./components/vue/list/list.vue";
import CmpQuestions from './components/vue/questions/questions.vue';
import CmpQuestionItem from './components/vue/questions/questions-item.vue';
import InfoBlock from './components/vue/info-block/index.vue';
import ClientColumn from './components/vue/clients/client-column.vue';
import ClientItem from './components/vue/clients/client-item.vue';
import RadialProgressBar from "./components/vue/vue-radial-progress/RadialProgressBar.vue";
import CustomScrollbar from "./components/vue/custom-scrollbar/custom-scrollbar.vue";

import CallBackModal from './components/vue/modals/call-back-modal/index.vue';
import CallBackModalRec from './components/vue/modals/call-back-modal-recruting/index.vue';
import CallBackModalSuccess from './components/vue/modals/call-back-success-modal/index.vue';
import PdfModal from './components/vue/modals/pdf-modal/index.vue';
import FeedBackModal from './components/vue/modals/feedback-modal/index.vue';

import smoothscroll from 'smoothscroll-polyfill';

window.isSupport = false;

smoothscroll.polyfill();
(function browserCheck() {
    let browserName = detect.parse(navigator.userAgent).browser.family;
    let browserVersion = detect.parse(navigator.userAgent).browser.version;

    window.isSupport = browserName !== 'IE';
})();

Vue.use(YmapPlugin);
Vue.use(VueScrollmagic);
Vue.use(VTooltip);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
//Vue.use(VueLazyload);
VTooltip.options.autoHide = false;
VTooltip.options.disposeTimeout = 100;

// require('./main.scss');
// require('normalize-scss');


// console.log(parse);

Vue.directive("scroll", {
    inserted: function(el, binding) {
        let f = function(evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener("scroll", f);
            }
        };
        window.addEventListener("scroll", f);
    }
});

Vue.config.ignoredElements = ['script'];

window.onload = () => {
    new Vue({
        el: "#app",
        components: {
            RadialProgressBar,
            pageFirstScreen,
            lineDots,
            lineCircleGraph,
            lineChart,
            readMore,
            feedback,
            socialIcon,
            articleSlider,
            swiper,
            swiperSlide,
            transitionHeight,
            breadcrumbs,
            CmpClients,
            CallBackModal,
            CallBackModalSuccess,
            PdfModal,
            SwitchButton,
            CmpList,
            CmpQuestions,
            ClientItem,
            CmpQuestionItem,
            FeedBackModal,
            InfoBlock,
            CmpArticle,
            ClientColumn,
            TimelineMax,
            TweenMax,
            Power1,
            CallBackModalRec,
            CustomScrollbar
        },
        directives: {
            imagesLoaded
        },
        data: {
            ...header_data,
            ...footer_data,
            ...main_data,
            ...main_client_data,
            ...about_data,
            ...contact_data,
            ...methods_data,
            ...dones_data,
            ...questions_data,
            ...recruting_data,
            ...work_data,
            ...breadcrumbs_data,

            promoOnLoad: false,
            preload: true,
            scroll: {
                prev: null,
                now: null,
                status: false
            },
            
            other_clients_arr: [[],[],[],[],[],[],[]],
            isSupport: window.isSupport || false,
            url: window.location.pathname,
            mouseEvent: null,
            arr: [],
            donesOpened: true,
            scrollmagic: null,
            isCooldown: false
        },

        created() {
            this.scrollmagic = this.$scrollmagic;
            window.addEventListener("scroll", this.m_scroll, {passive: true});
            this.preload = false;
        },

        mounted() {
            header_mounted();
            main_mounted();
            about_mounted();
            questions_mounted();

            // preload image for header

            if(document.querySelector(".recruting")) {
                let a = document.querySelectorAll(".recruting .system__info-block")[3].offsetLeft - 100;

                const tween = new TimelineMax().add(
                    TweenMax.to("#computer", 1.2, {
                        css: {
                            transform: `translate(${-a}px, 40px)`
                        },
                        ease: Power1.linear
                    })
                );

                //if(window.isSupport) {
                    const scene = this.scrollmagic
                    .scene({
                        triggerElement: ".system",
                        duration: "35%",
                        reverse: true,
                        offset: 900
                    })
                    .setTween(tween);
                    this.scrollmagic.addScene(scene);
                //}
            }
     
            let script_elems = document.querySelectorAll('#app script');

            if(window.isSupport) {
                script_elems.forEach(function(item) {
                    eval(item.innerHTML);
                });
            }

            if(document.querySelector('.main__about')) {
                if(!this.donesOpened && window.pageYOffset > document.querySelector('.main__about').offsetTop) {
                    this.donesOpened = true;
                }
            }
        },

        methods: {
            ...header_methods,
            ...footer_methods,
            ...main_methods,
            ...main_client_methods,
            ...about_methods,
            ...work_methods,
            ...contact_methods,

            GoToLink(link){
                location.href = link;
            },

            imageProgress(instance, image) {
                this.promoOnLoad = true;
            },
            setOtherClientsItems(n, $json) {
                for(let i = 0; i < this.other_clients_arr.length; i++) {
                    let col = this.other_clients_arr[i];

                    if(col.length < 2) {
                        col.push($json);
                        return;
                    }
                }
            },
            mousemove(evt) {
                if (this.isCooldown) return;
                this.mouseEvent = {
                    clientX: evt.clientX,
                    clientY: evt.clientY
                };
				this.isCooldown = true;
				setTimeout(() => this.isCooldown = false, 60);
            },
            promoShowModal: function(to = 'none') {

                if (to === "none") {
                    this.$modal.show("call_back");
                } else {
                    window.scrollTo({
                        top: document.querySelector(to).offsetTop,
                        behavior: "smooth"
                    });
                }
            },

            scroll_up: function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            },

            m_scroll: function (e) {
                if(window.isSupport) {
                    this.scroll.prev = this.scroll.now;
                    this.scroll.now = window.pageYOffset || document.documentElement.scrollTop;

                    this.scroll.status = this.scroll.now >= 400;
                    if((document.body.getBoundingClientRect().height - window.innerHeight) < this.scroll.now) {

                    } else {
                        this.status = this.scroll.now < this.scroll.prev;

                        if (this.scroll.now <= 150) {
                            this.status = false;
                        }
                    }

                } else {
                    this.scroll.now = window.pageYOffset || document.documentElement.scrollTop;

                    this.scroll.status = this.scroll.now >= 400;
                    this.status = this.scroll.now > 150;
                }
            }
        },

        beforeCreate() {
            main_beforeCreate();
            contact_beforeCreate();
        }
    });
};