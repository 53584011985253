import detect from "detect.js";
import axios from 'axios';

export const data = {
    main: {
        slider: {
            now: 1,
            data: [
                {
                    img: require("./../../../assets/image/icon/eight.svg"),
                    text: "Боллее 8 лет успешно выполняем исследовательские проекты с 2011 года в интересах российских и зарубежных компаний"
                },
                {
                    img: require("./../../../assets/image/icon/cube.svg"),
                    text: "Собрали данные о важности предствленных элементов и функций на сайте заказчика с точки зрения потребителей…"
                },
                {
                    img: require("./../../../assets/image/icon/ISO logo.svg"),
                    text:"Собрали данные о важности предствленных элементов и функций на сайте заказчика с точки зрения потребителей…"
                },
                {
                    img: require("./../../../assets/image/icon/ESOMAR_member_blk_RGB.svg"),
                    text: "Собрали данные о важности предствленных элементов и функций на сайте заказчика с точки зрения потребителей…"
                }
            ]
        },
        tooltip_x: 0,
        tooltip_y: 0,
        page: {
            background: [
                require("./../../../assets/image/background-image/main-page-promo-image-1.png"),
                require("./../../../assets/image/background-image/main-page-promo-image-2.png"),
                require("./../../../assets/image/background-image/main-page-promo-image-3.png"),
                require("./../../../assets/image/background-image/main-page-promo-image-4.png")
            ],
            title: "Выявляем предпочтения<br/>ваших клиентов",
            subtitle: "Современные маркетинговые исследования по РФ и СНГ",
            button_text: "Получить расчет стоимости",
            text: {
                big: "«Русопрос» — маркетинговое агентство, специализирующееся<br/>на проведении качественных и количественных маркетинговых<br/>исследований, аналитике в области потребительских предпочтений.",
                small: ""
            }
        },
        text_data: {
            true: [{"id": "", "title": "", "text": "", "link": "", "check": ""}],
            false: [{"id": "", "title": "", "text": "", "link": "", "check": ""}]
        },
        now: {
            true: '',
            false: ''
        },
        dones_status: true,
        max_show: 2,
        // mouseEvent: null,
        totalSteps: 4,
        progress: [
            {
                completedSteps: 3
            },
            {
                completedSteps: 0
            },
            {
                completedSteps: 0
            },
            {
                completedSteps: 0
            }
        ],
        wave_tooltip: {
            options: {
                content: "",
                hideOnTargetClick: true,
                targetClasses: "tooltip",
                trigger: "hover",
                delay: { show: '500', hide: '100' },
            },

            data: [
                {
                    title: "Экспертность",
                    text:
                        "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                },
                {
                    title: "Достоверность",
                    text:
                        "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                },
                {
                    title: "Охват",
                    text:
                        "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                },
                {
                    title: "Онлайн-панель",
                    text:
                        "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                },
                {
                    title: "Рекрутинг",
                    text:
                        "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                }
            ]
        },
        status_chart: false,
        status_chart2: false,
        timeout: null,
        animateSpeed: 100,

        ieclients: false,

        mobileBig: false,
        mobileSmall: false,
        visibleText: 0
    }

};

export const beforeCreate = () => {
    if (window.innerWidth < 1200) {
        data.main.mobileBig = true;
        data.main.mobileSmall = false;

        if (window.innerWidth < 768) {
            data.main.mobileSmall = true;
        }
    } else {
        data.main.mobileBig = false;
        data.main.mobileSmall = false;
    }

    window.onresize = () => {
        methods.resize();
    };
};

export const mounted = () => {
    data.main.ieclients = window.isSupport || false;
    if (document.querySelector('.main__dones')) {
        methods.getDonesMethod();
        methods.getDonesDones();
    }
    if (window.isSupport) {
        setTimeout(() => {
            data.main.animateSpeed = 6500;
            data.main.totalSteps = 1;

            methods.autoplay();

            methods.setAutoPlayTimeout();
        }, 4150);
        setTimeout(() => {
            data.main.progress[0].completedSteps = 0;
        }, 4000);
    }


    // для IE/Edge - отключение запуска автоматической анимации кнопок для промо-блока
    let browserName = detect.parse(navigator.userAgent).browser.family;

    if (!window.isSupport || browserName === 'Edge') {
        data.main.progress[0].completedSteps = 0;
        data.main.timeout = "clear";
    }
};

export const methods = {
    getDonesMethod() {
        let url = location.href.split('/')[3] == 'en' ? '/en': '';
        url = url + '/encode/method.php';
        axios({url: url, method: 'get', responseType: 'json'})
            .then(response => {
                let responseData = response.data.filter(el => el.check.toLowerCase() === 'да');
                data.main.text_data.false = responseData;
                data.main.status_chart = true;
                data.main.now.false = responseData[0];
            })
            .catch(error => {
                console.log(error);
            });
    },
    getDonesDones() {

        let url = location.href.split('/')[3] == 'en' ? '/en': '';
        url = url + '/encode/dones.php';
        axios({url: url, method: 'get', responseType: 'json'})
            .then(response => {
                let responseData = response.data.filter(el => el.check.toLowerCase() === 'да');
                data.main.text_data.true = responseData;
                data.main.status_chart2 = true;
                data.main.now.true = responseData[0];
            })
            .catch(error => {
                console.log(error);
            });
    },
    showText(index) {
        if (data.main.mobileSmall) {
            if (data.main.visibleText === index) {
                data.main.visibleText = -1;
            } else {
                data.main.visibleText = index;
            }
        }
    },
    resize() {
        if (window.innerWidth < 1200) {
            data.main.mobileBig = true;
            data.main.mobileSmall = false;

            if (window.innerWidth < 768) {
                data.main.mobileSmall = true;
            }
        } else {
            data.main.mobileBig = false;
            data.main.mobileSmall = false;
        }
    },
    setAutoPlayTimeout() {
        if (data.main.timeout !== 'clear') {
            data.main.timeout = setTimeout(() => {
                methods.autoplay();

                methods.setAutoPlayTimeout();
            }, 6500);
        }
    },
    autoplay() {
        if (data.main.progress[data.main.slider.now - 1].completedSteps === data.main.totalSteps) {
            data.main.progress[data.main.slider.now - 1].completedSteps = 0;
            if (data.main.slider.now >= data.main.slider.data.length) {
                data.main.slider.now = 1;
            } else {
                data.main.slider.now++;
            }
        }
        data.main.progress[data.main.slider.now - 1].completedSteps++;
    },
    slider_goto(n) {

        if (n !== data.main.slider.now) {
            data.main.progress[data.main.slider.now - 1].completedSteps = 0;
            clearTimeout(data.main.timeout);
            data.main.timeout = "clear";
            if (data.main.slider.now !== data.main.slider.now.length) {
                data.main.progress[data.main.slider.now - 1].completedSteps = 0;
            } else {
                data.main.progress[data.main.progress.length - 1].completedSteps = 0;
            }
            data.main.slider.now = n;
        }
    },
    getOptions(iterator) {
        let a = this.wave_tooltip.options;
        let b = `
        <div class=custom_tooltip>
                        <h4 class=h4>${
            this.wave_tooltip.data[iterator].title
            }</h4>
                        <p class=p>${this.wave_tooltip.data[iterator].text}</p>
                    </div>
      `;

        return (a.content = b);
    },
    show_more() {
        this.max_show = this.max_show + 2;
    },
    donesHandler(e) {
        this.dones_n = e;
    },
    // mousemove($event) {
    //     // let a = this.debounce(() => {
    //         this.mouseEvent = $event;
    //         console.log('change mouseEvent')
    //     // }, 50);
    //     // a();
    // },
    // debounce(f, ms) {
    //     let timer = null;

    //     return function (...args) {
    //         const onComplete = () => {
    //             f.apply(this, args);
    //             timer = null;
    //         }

    //         if (timer) {
    //             clearTimeout(timer);
    //         }

    //         timer = setTimeout(onComplete, ms);
    //     };
    // },
    lineChartListener(node) {
        data.main.now[data.main.dones_status] = node;
    },
    DonesClick($status) {
        if (data.main.dones_status !== $status) {
            data.main.dones_status = $status;

            if (window.innerWidth > 1024) {
                data.main.status_chart = false;
                data.main.status_chart2 = false;
                setTimeout(() => {
                    data.main.status_chart = true;
                    data.main.status_chart2 = true;
                }, 100);
            }
        }
    },
};