export const data = {
    dones: {

        list: {
            title: "Возможности онлайн-панели Rus-panel:",
            arr: [
                "Более 1 млн. респондентов по РФ",
                "Более 400 тыс. респондентов по СНГ и странам Балтии",
                "Верифицированные участники (проверка паспортных данных)",
                "Response Rate >20%",
                "20 тыс. новых панелистов каждый месяц"
            ],
            subtitle:
                "Заказчику предоставляется доступ в личный кабинет, где в режиме реального времени присутствует возможность следить за сбором квот опроса, <br/> а также выборочно выгружать уже заполненные анкеты. "
        },

        page_text: {
            title:
                "Онлайн-исследования являются одним из наиболее современных и широко применяемых методов сбора количественных данных в настоящее время.",
            subtitle:
                "Ключевыми преимуществами данной методики является оперативность сбора данных, возможность проведения географически распределённых исследований с огромным охватом и относительно не высокая стоимость."
        },

        carousel: {
            array: [
                {
                    companyName: "ЛСР",
                    specialization: "Недвижимость",
                    logo: require("../../../assets/image/logos/lsr.png"),
                    description:
                        "«Группа ЛСР» стабильно входит в число крупнейших девелоперов столичного региона. По итогам 2017 года компания заняла пятую строчку рейтинга с четвертой частью сданной по всей Москве недвижимости.",
                    task:
                        "Выявление оптимального количества и стоимостных параметров парковочных мест в ЖК Бизнес-класса, тестирование гипотез заказчика.",
                    methods: {
                        type: 'text',
                        content: 'Онлайн-панель (CAWI) — выборка 250 респондентов, телефонный опрос (CATI) — выборка 250 респондентов.',
                        button: {
                            name: 'Телефонные опросы (CATI)',
                            link: '/CATI'
                        }
                    }
                },
                {
                    companyName: "ЛСР",
                    specialization: "Недвижимость",
                    logo: require("../../../assets/image/logos/lsr.png"),
                    description:
                        "«Группа ЛСР» стабильно входит в число крупнейших девелоперов столичного региона. По итогам 2017 года компания заняла пятую строчку рейтинга с четвертой частью сданной по всей Москве недвижимости.",
                    task:
                        "Выявление оптимального количества и стоимостных параметров парковочных мест в ЖК Бизнес-класса, тестирование гипотез заказчика.",
                    methods: {
                        type: 'list',
                        content: ['Онлайн-панель (CAWI);', 'Выборка 1067 респондентов (Москва / СПБ / города-миллионеры / города < 1 млн.)'],
                        link: ''
                    }
                },
                {
                    companyName: "ЛСР",
                    specialization: "Недвижимость",
                    logo: require("../../../assets/image/logos/lsr.png"),
                    description:
                        "«Группа ЛСР» стабильно входит в число крупнейших девелоперов столичного региона. По итогам 2017 года компания заняла пятую строчку рейтинга с четвертой частью сданной по всей Москве недвижимости.",
                    task:
                        "Выявление оптимального количества и стоимостных параметров парковочных мест в ЖК Бизнес-класса, тестирование гипотез заказчика.",
                    methods: {
                        type: 'list',
                        content: ['Онлайн-панель (CAWI);', 'Выборка 1067 респондентов (Москва / СПБ / города-миллионеры / города < 1 млн.)'],
                        link: ''
                    }
                }
            ]
        },

        swiperOption: {
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto",
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 185,
                depth: 150,
                modifier: 1,
                slideShadows: false
            },
            navigation: {
                nextEl: ".carousel__button--right",
                prevEl: ".carousel__button--left"
            },
            pagination: {
                el: ".carousel__navigation",
                type: "bullets",
                dynamicMainBullets: 4,
                clickable: true,
                bulletClass: "carousel__navigation-item",
                bulletActiveClass: "carousel__navigation-item--active",
                renderBullet: function (index, className) {
                    return `<li class="${className}"><span class="carousel__navigation-item--animation"></span></li>`;
                }
            }
        }
    }
}
