<template>
    <transition
            @after-enter="afterEnter"
            @enter="enter"
            @leave="leave"
            name="expand">
        <slot></slot>
    </transition>
</template>
<script>
    export default {
        name: "transition-height",
        components: {},
        props: [],
        data() {
            return {};
        },
        computed: {},
        mounted() {},
        methods: {
            enter(element) {
                const width = getComputedStyle(element).width;

                element.style.width = width;
                element.style.position = "absolute";
                element.style.visibility = "hidden";
                element.style.height = "auto";

                const height = getComputedStyle(element).height;

                element.style.width = 'unset';
                element.style.position = 'static';
                element.style.visibility = 'visible';
                element.style.height = 0;

                // Force repaint to make sure the
                // animation is triggered correctly.
                getComputedStyle(element).height;

                // Trigger the animation.
                // We use `setTimeout` because we need
                // to make sure the browser has finished
                // painting after setting the `height`
                // to `0` in the line above.
                setTimeout(() => {
                    element.style.height = height;
                });
            },
            afterEnter(element) {
                element.style.height = "auto";
            },
            leave(element) {
                const height = getComputedStyle(element).height;

                element.style.height = height;

                // Force repaint to make sure the
                // animation is triggered correctly.
                getComputedStyle(element).height;

                setTimeout(() => {
                    element.style.height = 0;
                });
            }
        }
    };

</script>
<style lang="scss" scoped>

    .expand-enter-active,
    .expand-leave-active {
        transition: height 0.4s linear;
        overflow: hidden;
        //box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
    }

    .expand-enter,
    .expand-leave-to {
        height: 0;
        //box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
    }
</style>

