<template>
  <div class="svg__container">
    <svg class="svg" style="width:650px; height:800px;">
      <radialGradient xmlns="http://www.w3.org/2000/svg" id="background-gradient">
        <stop offset="0" />
        <stop offset="1" />
      </radialGradient>
      <rect
              xmlns="http://www.w3.org/2000/svg"
              width="1"
              height="1"
              fill="url(#background-gradient)"
      />
      <g stroke-width="1">
        <line
              v-for="(edge, n) in edges"
              :key="'edge' + n"
              :x1="getX1(edge)"
              :y1="getY1(edge)"
              :x2="getX2(edge)"
              :y2="getY2(edge)"
              :stroke="'rgba(255,255,255, '+ getOpacity(edge).toFixed(3) +')'"
        />
        <g
                v-for="(node,n) in nodes"
                :key="node.id"
                :transform="'translate(' + node.posX * 1000 + ',' + node.posY * 1000 + ')'"
                :class="{
            'svg-node--active': node.selected,
            'svg-node--up': node.posZ < 0.25
          }"
                class="svg-node"
                :id="'text_' + n"
                @mouseenter="mouseEnter($event, node)"
                @mouseleave="mouseLeave(node)"
                @click="selectNode(node, n)"
          >
          <circle
                  :cx="0"
                  :cy="0"
                  :r="(node.radius) * 1000"
                  class="svg-node__cirle"
          />
          <text
                  :class="{
              'svg__text--active': select_now === n,
              'svg__text--up': node.opacity < 0.5
            }"
                  class="svg-node__text"
                  text-anchor="middle"
          >
            <tspan :dy="-10">{{text_data[n] ? text_data[n].title : ""}}</tspan>
            <tspan
                    class="another"
                    :class="{'select': node.selected}"
                    dy="-7"
                    dx="0.15">&#8226;</tspan>
          </text>
        </g>

      </g>
    </svg>

    <!-- для мобилльной версии -->
    <div class="main__dones-nav _mobile">
      <div class="main__dones-nav-item h3"
           style="opacity: 1"
           @click="donesStatus(true)"
           ref="jsItem1">
        Решения
      </div>
      <div class="main__dones-nav-item h3"
           @click="donesStatus(false)" ref="jsItem2">
        Методы
      </div>
    </div>

    <!-- для мобилльной версии - решения -->
    <div class="swiperMobileLineChart" ref="_swiper1">
      <swiper :options="swiperOptionThumbs" class="gallery-thumbs _mobile" ref="swiperThumbs">
        <swiper-slide
                class="svg-mobile"
                :class="{active: n === 0}"
                v-for="(node, n) in text_data2[true]"
                :key="node.id">
          <span class="svg-mobile__text" @click="SlideTo(n)">{{ node.title }}</span>
        </swiper-slide>
      </swiper>

      <swiper
              :options="swiperOptionTop"
              class="gallery-top _mobile"
              ref="swiperTop"
              @slideChange="slideChange()"
      >
        <swiper-slide v-for="node in text_data2[true]" :key="node.id">
          <p class="main__dones-content-text p" v-html="node.text"></p>
        </swiper-slide>
      </swiper>
    </div>

    <!-- для мобилльной версии - методы -->
    <div class="swiperMobileLineChart" ref="_swiper2" style="height: 0; overflow: hidden; margin: 0; padding: 0;">
      <swiper :options="swiperOptionThumbs2" class="gallery-thumbs _mobile" ref="swiperThumbs2">
        <swiper-slide
                class="svg-mobile"
                :class="{active: n === 0}"
                v-for="(node, n) in text_data2[false]"
                :key="node.id">
          <span class="svg-mobile__text" @click="SlideTo2(n)">{{ node.title }}</span>
        </swiper-slide>
      </swiper>

      <swiper
              :options="swiperOptionTop2"
              class="gallery-top _mobile"
              ref="swiperTop2"
              @slideChange="slideChange2()"
      >
        <swiper-slide v-for="(node, n) in text_data2[false]">
          <p class="main__dones-content-text p" v-html="node.text"></p>
        </swiper-slide>
      </swiper>
    </div>

  </div>
</template>

<script>
    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import { GObject, GNode, GEdge, DisjointSet, coordinates } from "./line-chart.js";


    export default {
        name: "line-chart",
        props: ["text_data", "text_data2"],
        components: {
            swiper,
            swiperSlide
        },
        data () {
            return {
                swiperOptionTop: {
                    slidesPerView: 1,
                    spaceBetween: 40
                },
                swiperOptionThumbs: {
                    spaceBetween: 40,
                    slidesPerView: "auto",
                    freeMode: true,
                    //mousewheel: true
                },
                swiperOptionTop2: {
                    slidesPerView: 1,
                    spaceBetween: 40
                },
                swiperOptionThumbs2: {
                    spaceBetween: 40,
                    slidesPerView: "auto",
                    freeMode: true,
                    //mousewheel: true
                },
                dones_status2: true,

                pageLoaded: false,
                select_now: 0,
                // GRAPH
                idealNumNodes: "",
                extraEdgeProportion: "",
                radiiWeightPower: 0,
                driftSpeed: "",
                repulsionForce: "",
                text_arr: [],
                borderFade: -0.02,
                fadeInPerFrame: 0.06, // В диапазоне (0.0, 1.0]
                fadeOutPerFrame: -0.06, // В диапазоне [-1.0, 0.0]
                stop_item: null,
                stop_id: null,
                frameNumber: 0,
                nodes: [],
                edges: [],
                pos_arr: []
            };
        },
        created () {
            switch (this.text_data.length) {
                case 9:
                    this.pos_arr = coordinates.pos_arr9;
                    break;
                case 10:
                    this.pos_arr = coordinates.pos_arr10;
                    break;
                case 11:
                    this.pos_arr = coordinates.pos_arr11;
                    break;
                case 12:
                    this.pos_arr = coordinates.pos_arr12;
                    break;
                default:
                    this.pos_arr = coordinates.pos_arr8;
            }

            this.pos_arr.map(node => {
                return node.selected = false;
            })
        },
        mounted () {
            if (window.innerWidth > 1200) {
                let svg = document.querySelector(".svg__container .svg");
                this.initInputHandlers(); // инициализируем настройки
                this.setOutput(svg); // задаем размеры контейнера
                this.redrawOutput(); // проверяем наличие svg контейнера(?)
                this.updateGraph(); // стартуем!
            } else {

                this.$nextTick(() => {
                    const swiperTop = this.$refs.swiperTop.swiper;
                    const swiperThumbs = this.$refs.swiperThumbs.swiper;
                    swiperTop.controller.control = swiperThumbs;

                    const swiperTop2 = this.$refs.swiperTop2.swiper;
                    const swiperThumbs2 = this.$refs.swiperThumbs2.swiper;
                    swiperTop2.controller.control = swiperThumbs2;

                    this.$refs.swiperThumbs.swiper.slides[0].style.cssText = 'margin-right: 40px; min-width:' + this.$refs.swiperThumbs.swiper.slides[0].clientWidth + 'px';
                    this.$refs.swiperThumbs2.swiper.slides[0].style.cssText = 'margin-right: 40px; min-width:' + this.$refs.swiperThumbs2.swiper.slides[0].clientWidth + 'px';
                });

            }

            this.pageLoaded = true;
        },
        methods: {
            updateGraph() {
                this.stepFrame();
            },

            //  такая функция нужна, потому что если использовать классы, то swiper пересчитывает слайды и сбивается выравнивание
            donesStatus(val) {
                if (val) {
                    this.$refs.jsItem2.style.opacity = '0.2';
                    this.$refs.jsItem1.style.opacity = '1';

                    this.$refs._swiper2.style.cssText = "height: 0; overflow: hidden; margin: 0; padding: 0;";
                    this.$refs._swiper1.style.cssText = "";
                } else {
                    this.$refs.jsItem2.style.opacity = '1';
                    this.$refs.jsItem1.style.opacity = '0.2';

                    this.$refs._swiper2.style.cssText = "";
                    this.$refs._swiper1.style.cssText = "height: 0; overflow: hidden; margin: 0; padding: 0;";
                }
            },
            slideChange() {
                for (let i = 0; i < this.$refs.swiperThumbs.swiper.slides.length; i++) {
                    this.$refs.swiperThumbs.swiper.slides[i].classList.remove("active");
                }

                this.$refs.swiperThumbs.swiper.slides[this.$refs.swiperTop.swiper.activeIndex].classList.add("active");
            },
            SlideTo(i) {
                this.$refs.swiperTop.swiper.slideTo(i);
                this.$refs.swiperThumbs.swiper.slideTo(i);
            },
            slideChange2() {
                for (let i = 0; i < this.$refs.swiperThumbs2.swiper.slides.length; i++) {
                    this.$refs.swiperThumbs2.swiper.slides[i].classList.remove("active");
                }

                this.$refs.swiperThumbs2.swiper.slides[this.$refs.swiperTop2.swiper.activeIndex].classList.add("active");
            },
            SlideTo2(i) {
                this.$refs.swiperTop2.swiper.slideTo(i);
                this.$refs.swiperThumbs2.swiper.slideTo(i);
            },
            initInputHandlers() {
                this.idealNumNodes = this.pos_arr.length;
                this.extraEdgeProportion = 180 / 100;
                this.driftSpeed = 3 * 0.0001; // 3  speed
                this.repulsionForce = 3.5 * 0.001;
            },

            //  GRAPH
            stepFrame() {
                setTimeout(()=>{
                    window.requestAnimationFrame(this.stepFrame);
                    // Drawing code goes here
                }, 15);
                // requestAnimationFrame(this.stepFrame);

                this.updateNodes(); // обновляем координаты точек
                this.updateEdges(); // обновляем координаты точек
              
                this.frameNumber++; // не знаю что это но чем дальше тем быстрее становится диаграма и больше ее разносит
            },
            updateNodes() {
                // Обновление положения каждого узла, скорости, непрозрачности. Удаление полностью прозрачных узлов.
                let newNodes = [];
                let curIdealNumNodes = Math.min(Math.floor(this.frameNumber / 3), this.idealNumNodes);

                this.nodes.forEach(node => {
                    if (node.id < 9 && node.id !== parseInt(this.stop_id)) {
                        node.posX += node.velX * this.driftSpeed;
                        node.posY += node.velY * this.driftSpeed;
                        node.posZ += node.velZ * this.driftSpeed;
                        // Randomly perturb velocity, with damping
                        node.velX = node.velX * 0.99 + (Math.random() - 0.5) * 0.3;
                        node.velY = node.velY * 0.99 + (Math.random() - 0.5) * 0.3;
                        node.velZ = node.velZ * 0.99 + (Math.random() - 0.5) * 0.3;
                    }
                    // Fade out nodes near the borders of the rectangle, or exceeding the target number of nodes
                    // const insideness = Math.min(
                    //   node.posX,
                    //   node.posZ - node.posX,
                    //   node.posY,
                    //   node.posZ - node.posY
                    // );

                    if (node.id !== parseInt(this.stop_id)) {
                        node.fade(node.posZ > 0.2 ? this.fadeInPerFrame : this.fadeOutPerFrame);
                    }
                    // Only keep visible nodes
                    let nextPosX = node.posX + node.velX * this.driftSpeed
                    if ( nextPosX > this.pos_arr[node.id].posX + 0.03 || nextPosX < this.pos_arr[node.id].posX - 0.03) {
                        node.velX = -node.velX;
                    }
                    let nextPosY = node.posY + node.velY * this.driftSpeed
                    if ( nextPosY > this.pos_arr[node.id].posY + 0.03 || nextPosY < this.pos_arr[node.id].posY - 0.03) {
                        node.velY = -node.velY;
                    }
                    let nextPosZ = node.posZ + node.velZ * this.driftSpeed
                    if ( nextPosZ >  0.4 || nextPosZ < 0) {
                        node.velZ = -node.velZ;
                    }

                    newNodes.push(node);
                });

                while (newNodes.length < curIdealNumNodes) {
                    newNodes.push(
                        new GNode(
                            this.pos_arr[newNodes.length].posX,
                            this.pos_arr[newNodes.length].posY,
                            this.pos_arr[newNodes.length].posZ, // Position X and Y
                            0.004, // Radius skewing toward smaller values
                            0,
                            0,
                            0,
                            this.pos_arr[newNodes.length].id
                        )
                    ); // Velocity
                }

                // Spread out nodes a bit
                this.nodes = newNodes;
                this.doForceField();
            },

            doForceField() {
                // For aesthetics, we perturb positions instead of velocities
                for (let i = 0; i < this.nodes.length; i++) {
                    let a = this.nodes[i];
                    if (a.id < 0) {
                        a.dPosX = 0;
                        a.dPosY = 0;
                        for (let j = 0; j < i; j++) {
                            let b = this.nodes[j];
                            let dx = a.posX - b.posX;
                            let dy = a.posY - b.posY;
                            const distSqr = dx * dx + dy * dy;
                            // Notes: The factor 1/sqrt(distSqr) is to make (dx, dy) into a unit vector.
                            // 1/distSqr is the inverse square law, with a smoothing constant added to prevent singularity.
                            const factor = this.repulsionForce / (Math.sqrt(distSqr) * (distSqr + 0.00001));
                            dx *= factor;
                            dy *= factor;
                            a.dPosX += dx;
                            a.dPosY += dy;
                            b.dPosX -= dx;
                            b.dPosY -= dy;
                        }
                    }
                }

                this.nodes.forEach(node => {
                    node.posX += node.dPosX;
                    node.posY += node.dPosY;
                });
            },

            updateEdges() {
                // Calculate array of spanning tree edges, then add some extra low-weight edges
                let allEdges = this.calcAllEdgeWeights();
                const idealNumEdges = Math.round(
                    (this.nodes.length - 1) * (1 + this.extraEdgeProportion)
                );
                let idealEdges = this.calcSpanningTree(allEdges);

                allEdges.forEach(([_, i, j]) => {
                    if (idealEdges.length >= idealNumEdges) return false;
                    let edge = new GEdge(this.nodes[i], this.nodes[j]); // Convert data formats
                    if (!this.containsEdge(idealEdges, edge)) idealEdges.push(edge);
                });

                // Classify each current edge, checking whether it is in the ideal set; prune faded edges
                let newEdges = [];

                this.edges.forEach(edge => {
                    edge.fade(this.containsEdge(idealEdges, edge) ? this.fadeInPerFrame : this.fadeOutPerFrame);

                    if (Math.min(edge.opacity, edge.nodeA.opacity, edge.nodeB.opacity) > 0) {
                        newEdges.push(edge);
                    }
                });

                // If there's room for new edges, add some missing spanning tree edges (higher priority), then extra edges
                idealEdges.forEach(edge => {
                    if (newEdges.length >= idealNumEdges) return false;
                    if (!this.containsEdge(newEdges, edge)) newEdges.push(edge);
                });

                this.edges = newEdges;
            },

            calcAllEdgeWeights() {
                // Each entry has the form [weight,nodeAIndex,nodeBIndex], where nodeAIndex < nodeBIndex
                let result = [];

                for (let i = 0; i < this.nodes.length; i++) {
                    // Calculate all n * (n - 1) / 2 edges
                    const a = this.nodes[i];
                    for (let j = 0; j < i; j++) {
                        const b = this.nodes[j];
                        let weight = Math.hypot(a.posX - b.posX, a.posY - b.posY); // Евклидово расстояние
                        weight /= Math.pow(a.radius * b.radius, this.radiiWeightPower); // Погрешность на радиуса узла (?)
                        result.push([weight, i, j]);
                    }
                }

                return result.sort((a, b) => a[0] - b[0]); // Sort by ascending weight
            },

            calcSpanningTree(allEdges) {
                // Kruskal's MST algorithm
                let result = [];
                let ds = new DisjointSet(this.nodes.length);
                allEdges.forEach(([_, i, j]) => {
                    if (ds.mergeSets(i, j)) {
                        result.push(new GEdge(this.nodes[i], this.nodes[j])); // Convert data formats
                        if (result.length >= this.nodes.length - 1) return false;
                    }
                });

                return result;
            },

            containsEdge(edges, edge) {
                //Проверка на вмещение элемента в зону видимости
                let elementContains = edges.some(e => {
                    return ((e.nodeA == edge.nodeA && e.nodeB == edge.nodeB) ||
                        (e.nodeA == edge.nodeB && e.nodeB == edge.nodeA));
                });

                return elementContains;
            },

            setOutput(svg) {
                let br = svg.getBoundingClientRect(), // берем размеры объекта
                    br_width = br.width / 2 / Math.max(br.width, br.height) || 0, // выделяем ширину
                    br_height = br.height / Math.max(br.width, br.height) || 0; // и высоту

                this.svgElem = svg;
                svg.setAttribute("viewBox", `0 0 ${(br_width * 1000).toFixed(3)} ${(br_height * 1000).toFixed(3)}`);
                svg.setAttribute("width", (br_width.toString() * 1000).toFixed(3));
                svg.setAttribute("height", (br_height.toString() * 1000).toFixed(3));
                svg.querySelectorAll("stop")[0].setAttribute("stop-color", "transparent");
                svg.querySelectorAll("stop")[1].setAttribute("stop-color", "transparent");

                return this;
            },

            redrawOutput() {
                if (this.svgElem === null) throw "Invalid state";
            },

            getX1(edge) {
                const a = edge.nodeA;
                const b = edge.nodeB;
                let dx = a.posX - b.posX;
                let dy = a.posY - b.posY;
                const mag = Math.hypot(dx, dy);

                if (mag > a.radius + b.radius) {
                    dx /= mag;
                    dy /= mag;
                }
                return (a.posX - dx * a.radius) * 1000;
            },

            getX2(edge) {
                const a = edge.nodeA;
                const b = edge.nodeB;
                let dx = a.posX - b.posX;
                let dy = a.posY - b.posY;
                const mag = Math.hypot(dx, dy);

                if (mag > a.radius + b.radius) {
                    dx /= mag;
                    dy /= mag;
                }

                return (b.posX + dx * b.radius) * 1000;
            },

            getY1(edge) {
                const a = edge.nodeA;
                const b = edge.nodeB;
                let dx = a.posX - b.posX;
                let dy = a.posY - b.posY;
                const mag = Math.hypot(dx, dy);
                if (mag > a.radius + b.radius) {
                    dx /= mag;
                    dy /= mag;
                }
                return (a.posY - dy * a.radius) * 1000;
            },

            getY2(edge) {
                const a = edge.nodeA;
                const b = edge.nodeB;
                let dx = a.posX - b.posX;
                let dy = a.posY - b.posY;
                const mag = Math.hypot(dx, dy);

                if (mag > a.radius + b.radius) {
                    dx /= mag;
                    dy /= mag;
                }

                return (b.posY + dy * b.radius) * 1000;
            },

            getOpacity(edge) {
                const a = edge.nodeA;
                const b = edge.nodeB;

                return Math.min(Math.min(a.opacity, b.opacity), edge.opacity);
            },

            selectNode(node, n) {
                this.select_now = node;
                node.selected = true;

                let selectNode = this.text_data[n];
                let otputNode = {
                    title: selectNode.title,
                    text: selectNode.text,
                    link: selectNode.link
                }

                this.$emit("select-node", otputNode);
            },

            mouseEnter($event, node) {
                let a = $event.target.id.split("_");

                this.stop_item = $event.target;
                this.stop_id = a[1];
                node.radius = 0.005;
                node.opacity = 1
            },

            mouseLeave(node) {
                this.stop_id = null;
                node.radius = 0.004;
            }
        }
    };
</script>

<style lang="scss">
  @font-face {
    font-family: "font69df74781f7f3190cec3222cfdac96c0";
    font-display: auto;
    src: url("https://nomail.com.ua/files/eot/69df74781f7f3190cec3222cfdac96c0.eot?#iefix")
    format("embedded-opentype"),
    url("https://nomail.com.ua/files/woff/69df74781f7f3190cec3222cfdac96c0.woff")
    format("woff"),
    url("https://nomail.com.ua/files/woff2/69df74781f7f3190cec3222cfdac96c0.woff2")
    format("woff2");
  }

  .svg-node {
    cursor: pointer;
    &__text {
      display: inline-block;
      margin-bottom: 5px;
      font-family: "PT Root UI", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: .8 !important;
      transform: scale(1);
      fill: rgba(255, 255, 255, .78);
      will-change: transform;
      transition: all .2s;

      &--up {
        font-size: 18px;
        transition: all 0.2s;
        .another {
          font-size: 5px;
        }
      }

      &--active {
        fill: rgba(255, 255, 255, 1);
      }

      .another {
        font-family: "font69df74781f7f3190cec3222cfdac96c0", sans-serif;
        font-size: 7px;
        fill: transparent;
        &.select {
          fill: red;
        }
      }
    }

    &__cirle {
      fill: rgba(255, 255, 255, 1);
      transition: fill .1s;
    }
    &.svg-node--active {

    }
    &.svg-node--up {
      .svg-node__text {
        font-size: 18px;
        fill: rgba(255, 255, 255, .5);
      }

      .svg-node__cirle {
        fill: rgba(255, 255, 255, .5);
      }
    }
    &:hover {
      .svg-node__text, &.svg-node--up .svg-node__text {
        font-size: 26px;
        fill: rgba(255, 255, 255, 1);
      }
      .svg-node__cirle, &.svg-node--up .svg-node__circle {
        fill: rgba(255, 255, 255, 1) ;
      }
    }
  }

  .svg-mobile {
    display: none;
  }

  .swiperMobileLineChart {
    width: 100%;
  }

  @media (max-width: 1200px) {
    .svg__container .svg {
      display: none;
    }
    .svg__container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: calc(100% + 40px);
      margin: 62px 0 0 0;
      .gallery-thumbs {
        padding: 0;
        margin: 0 0 43px;
        > div {
          transform: translate3d(0px, 0px, 0px);
        }
        .swiper-slide.active .svg-mobile__text {
          color: #ffffff;
          font-size: 32px;
          padding-bottom: 5px;
          border-bottom: 1px solid rgba(#ffffff, 0.2);
        }
      }
      .gallery-top {
        width: calc(100% - 25px) !important;
      }
    }
    .svg-mobile {
      display: inline-flex;
      width: fit-content !important;
      &:last-child {
        width: 100% !important;
      }
    }
    .svg-mobile__text {
      cursor: pointer;
      font-size: 32px;
      line-height: 40px;
      font-weight: 800;
      color: #a7a6a4;
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    .svg-mobile__text {
      font-size: 20px;
      line-height: 25px;
    }
    .svg__container {
      width: calc(100% + 25px);
      margin: 0;
      .gallery-thumbs {
        margin-bottom: 30px;

        .swiper-slide.active .svg-mobile__text {
          font-size: 20px;
        }
      }
    }
  }
</style>

