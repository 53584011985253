var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "breadcrumbs", class: { "breadcrumbs-white": _vm.type } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("li", { staticClass: "breadcrumbs__item" }, [
        _c("a", { staticClass: "breadcrumbs__link", attrs: { href: "link" } }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumbs__item" }, [
      _c("a", { staticClass: "breadcrumbs__link", attrs: { href: "/" } }, [
        _vm._v("Главная")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumbs__item" }, [
      _c("img", {
        attrs: {
          alt: "triangle",
          src: require("./../../../assets/image/Triangle.svg")
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }