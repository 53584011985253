var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "clients__column",
      class: {
        "clients__column-type": _vm.type,
        "clients__column-type-cols": _vm.type && _vm.cols
      }
    },
    [_vm._t("default", null, { type: _vm.type, cols: _vm.cols, now: _vm.now })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }