var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch__container", class: { switch__type: _vm.type } },
    [
      _c(
        "div",
        {
          staticClass: "switch__text",
          class: {
            "switch__text--active": !_vm.status,
            _mobile: _vm.isMobile,
            "text__type--mobile": _vm.type
          },
          on: { click: _vm.switchStatusF }
        },
        [_vm._v(_vm._s(_vm.text_1))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "switch__wrapper",
          class: { "switch__wrapper-type": _vm.type }
        },
        [
          _c("label", { staticClass: "switch", attrs: { for: _vm.id } }, [
            _c("input", {
              staticClass: "switch__button",
              class: { _active: _vm.status },
              attrs: { id: _vm.id, type: "checkbox" }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "switch__span",
              on: { click: _vm.switchStatus }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "switch__text",
          class: {
            "switch__text--active": _vm.status,
            _mobile: _vm.isMobile,
            "text__type--mobile": _vm.type
          },
          on: { click: _vm.switchStatusT }
        },
        [_vm._v(_vm._s(_vm.text_2))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }