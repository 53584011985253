var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "modal modal__success",
      attrs: { name: "call_back_success", width: "100%", height: "auto" },
      on: {
        "before-open": _vm.beforeOpen,
        closed: _vm.beforeClose,
        opened: _vm.opened
      }
    },
    [
      _c("div", { staticClass: "modal__closer" }, [
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              },
              on: {
                click: function($event) {
                  return _vm.$modal.hide("call_back_success")
                }
              }
            },
            [
              _c("path", {
                attrs: {
                  opacity: "0.3",
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M9 8.30769L17.3077 0L18 0.692308L9.69231 9L18 17.3077L17.3077 18L9 9.69231L0.692308 18L0 17.3077L8.30769 9L0 0.692308L0.692308 0L9 8.30769Z",
                  fill: "#0E131F"
                }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__container" }, [
        _c("h3", { staticClass: "modal__title h3" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "modal__text p",
          domProps: { innerHTML: _vm._s(_vm.text[_vm.status]) }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "modal__button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$modal.hide("call_back_success")
              }
            }
          },
          [_c("span", [_vm._v("Ок")])]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }