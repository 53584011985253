var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "svg__container" }, [
    _c(
      "svg",
      { staticClass: "svg", staticStyle: { width: "650px", height: "800px" } },
      [
        _c(
          "radialGradient",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              id: "background-gradient"
            }
          },
          [
            _c("stop", { attrs: { offset: "0" } }),
            _vm._v(" "),
            _c("stop", { attrs: { offset: "1" } })
          ],
          1
        ),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "1",
            height: "1",
            fill: "url(#background-gradient)"
          }
        }),
        _vm._v(" "),
        _c(
          "g",
          { attrs: { "stroke-width": "1" } },
          [
            _vm._l(_vm.edges, function(edge, n) {
              return _c("line", {
                key: "edge" + n,
                attrs: {
                  x1: _vm.getX1(edge),
                  y1: _vm.getY1(edge),
                  x2: _vm.getX2(edge),
                  y2: _vm.getY2(edge),
                  stroke:
                    "rgba(255,255,255, " + _vm.getOpacity(edge).toFixed(3) + ")"
                }
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.nodes, function(node, n) {
              return _c(
                "g",
                {
                  key: node.id,
                  staticClass: "svg-node",
                  class: {
                    "svg-node--active": node.selected,
                    "svg-node--up": node.posZ < 0.25
                  },
                  attrs: {
                    transform:
                      "translate(" +
                      node.posX * 1000 +
                      "," +
                      node.posY * 1000 +
                      ")",
                    id: "text_" + n
                  },
                  on: {
                    mouseenter: function($event) {
                      return _vm.mouseEnter($event, node)
                    },
                    mouseleave: function($event) {
                      return _vm.mouseLeave(node)
                    },
                    click: function($event) {
                      return _vm.selectNode(node, n)
                    }
                  }
                },
                [
                  _c("circle", {
                    staticClass: "svg-node__cirle",
                    attrs: { cx: 0, cy: 0, r: node.radius * 1000 }
                  }),
                  _vm._v(" "),
                  _c(
                    "text",
                    {
                      staticClass: "svg-node__text",
                      class: {
                        "svg__text--active": _vm.select_now === n,
                        "svg__text--up": node.opacity < 0.5
                      },
                      attrs: { "text-anchor": "middle" }
                    },
                    [
                      _c("tspan", { attrs: { dy: -10 } }, [
                        _vm._v(
                          _vm._s(_vm.text_data[n] ? _vm.text_data[n].title : "")
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "tspan",
                        {
                          staticClass: "another",
                          class: { select: node.selected },
                          attrs: { dy: "-7", dx: "0.15" }
                        },
                        [_vm._v("•")]
                      )
                    ]
                  )
                ]
              )
            })
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main__dones-nav _mobile" }, [
      _c(
        "div",
        {
          ref: "jsItem1",
          staticClass: "main__dones-nav-item h3",
          staticStyle: { opacity: "1" },
          on: {
            click: function($event) {
              return _vm.donesStatus(true)
            }
          }
        },
        [_vm._v("\n      Решения\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "jsItem2",
          staticClass: "main__dones-nav-item h3",
          on: {
            click: function($event) {
              return _vm.donesStatus(false)
            }
          }
        },
        [_vm._v("\n      Методы\n    ")]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "_swiper1", staticClass: "swiperMobileLineChart" },
      [
        _c(
          "swiper",
          {
            ref: "swiperThumbs",
            staticClass: "gallery-thumbs _mobile",
            attrs: { options: _vm.swiperOptionThumbs }
          },
          _vm._l(_vm.text_data2[true], function(node, n) {
            return _c(
              "swiper-slide",
              {
                key: node.id,
                staticClass: "svg-mobile",
                class: { active: n === 0 }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "svg-mobile__text",
                    on: {
                      click: function($event) {
                        return _vm.SlideTo(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(node.title))]
                )
              ]
            )
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "swiper",
          {
            ref: "swiperTop",
            staticClass: "gallery-top _mobile",
            attrs: { options: _vm.swiperOptionTop },
            on: {
              slideChange: function($event) {
                return _vm.slideChange()
              }
            }
          },
          _vm._l(_vm.text_data2[true], function(node) {
            return _c("swiper-slide", { key: node.id }, [
              _c("p", {
                staticClass: "main__dones-content-text p",
                domProps: { innerHTML: _vm._s(node.text) }
              })
            ])
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "_swiper2",
        staticClass: "swiperMobileLineChart",
        staticStyle: {
          height: "0",
          overflow: "hidden",
          margin: "0",
          padding: "0"
        }
      },
      [
        _c(
          "swiper",
          {
            ref: "swiperThumbs2",
            staticClass: "gallery-thumbs _mobile",
            attrs: { options: _vm.swiperOptionThumbs2 }
          },
          _vm._l(_vm.text_data2[false], function(node, n) {
            return _c(
              "swiper-slide",
              {
                key: node.id,
                staticClass: "svg-mobile",
                class: { active: n === 0 }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "svg-mobile__text",
                    on: {
                      click: function($event) {
                        return _vm.SlideTo2(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(node.title))]
                )
              ]
            )
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "swiper",
          {
            ref: "swiperTop2",
            staticClass: "gallery-top _mobile",
            attrs: { options: _vm.swiperOptionTop2 },
            on: {
              slideChange: function($event) {
                return _vm.slideChange2()
              }
            }
          },
          _vm._l(_vm.text_data2[false], function(node, n) {
            return _c("swiper-slide", [
              _c("p", {
                staticClass: "main__dones-content-text p",
                domProps: { innerHTML: _vm._s(node.text) }
              })
            ])
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }