var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition-height", [
    _c(
      "div",
      { staticClass: "t-read-more" },
      [
        _c(
          "transition-group",
          {
            ref: "textWrapper",
            staticClass: "t-read-more__wrapper",
            attrs: { mode: "out-in", tag: "div", name: "read-more-fade" }
          },
          [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showText,
                    expression: "!showText"
                  }
                ],
                key: "1",
                staticClass: "t-read-more__text"
              },
              [_vm._t("text")],
              2
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showText,
                    expression: "showText"
                  }
                ],
                key: "2",
                staticClass: "t-read-more__text t-read-more__text_open"
              },
              [_vm._t("text")],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "t-read-more__text-button",
            class: { "t-read-more__text-button-active": _vm.time_active },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.toggleMore()
              }
            }
          },
          [_vm._v(_vm._s(_vm.button_text))]
        ),
        _vm._v(" "),
        _c("div", { ref: "text", staticClass: "t-read-more__hidden" }, [
          _c("div", [_vm._t("text")], 2)
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }