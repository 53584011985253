export const data = {
    optionSwiperMobilePartners: {
        //spaceBetween: 80,
        //centeredSlides: true,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.swiperMobile .swiper-button-next',
            prevEl: '.swiperMobile .swiper-button-prev',
        },
        // breakpoints: {
        //     767: {
        //         slidesPerView: 2,
        //     },
        //     400: {
        //         slidesPerView: 1,
        //     }
        // }
    },
    contact: {
        partners_open: false,
        icon: {
            layout: "default#image",
            imageHref: require("./../../../assets/image/geo.png"),
            imageSize: [36, 56],
            imageOffset: [-22, -55]
        },
        coords: [55.70643437428607, 37.596196195866874],
        ctrlKey: false,
        timer: null,
        show_rule: false,
        image: require('./../../../assets/image/contact-image.png'),
        visibleMapMobile: true
    }
};

export const methods = {
    initHandler: function($map) {
        this.$refs.map.$el.addEventListener("wheel", () => {
            if (!data.contact.ctrlKey) {
                // Ctrl не нажат, показываем уведомление
                clearTimeout(data.contact.timer); // Очищаем таймер, чтобы продолжать показывать уведомление
                data.contact.show_rule = true;
                data.contact.timer = setTimeout(() => {
                    data.contact.show_rule = false;
                }, 1500);
            } else {
                // Ctrl нажат, скрываем сообщение
                data.contact.show_rule = false;
            }
        });

        this.$refs.map.$el.addEventListener("mousedown", () => {
            data.contact.show_rule = false;
        });

        document.addEventListener("keydown", e => {
            if (e.which === 17 && !data.contact.ctrlKey) {
                data.contact.ctrlKey = true;
                $map.behaviors.enable("scrollZoom");
            }
        });

        document.addEventListener("keyup", e => {
            if (e.which === 17) {
                data.contact.ctrlKey = false;
                $map.behaviors.disable("scrollZoom");
            }
        });
    },
    openMapMobile() {
        if (window.innerWidth < 768) {
            data.contact.visibleMapMobile = !data.contact.visibleMapMobile;
            if (data.contact.visibleMapMobile === true) {
                window.scrollTo(0, 800);
            }
        }
    }
};
export const beforeCreate = () => {
    if (window.innerWidth < 768) {
        data.contact.visibleMapMobile = false;
    }
};