<template>
    <div class="list">
        <h2 class="list__title h2">Заголовок</h2>
        <ul class="list__ul">
            <li>1</li>
            <li>1</li>
            <li>1</li>
            <li>1</li>
            <li>1</li>
            <li>1</li>
        </ul>
        <p class="list__subtitle p">Тут Может быть опционально текст</p>
    </div>
</template>
<style lang="scss" scoped>
    .list {
        &__title {
            color: #242428;
            margin: 0 0 30px 0;
        }

        &__ul {
            list-style-type: none;

        }

        &__item {
            color: #242428;
            margin-top: 16px;
            margin-left: 30px;

            &:first-child {
                margin-top: 0;
            }

            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: -30px;
                border-radius: 50%;
                top: calc(50% - 4px);
                width: 8px;
                height: 8px;
                background-color: #E5190A;
            }
        }

        &__subtitle {
            color: #242428;
            margin-top: 24px;
        }

    }
</style>
<script>
    export default {
        props: {
            title: {
                default: () => {
                    return "Исследование Customer Journey позволяет:";
                }
            },
            array: {
                default: () => {
                    return ["Исследование Customer Journey позволяет"];
                }
            },
            subtitle: {
                default: () => {
                    return "none";
                }
            }
        },

        name: "component_list"
    };

</script>