var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "articles__item article", attrs: { href: "/" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "article__content" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "article-text" }, [
          _c("h3", { staticClass: "article-text__title" }, [
            _vm._v(" Заголовок ")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "article-text__description" }, [
            _vm._v(
              " Описание " + _vm._s(_vm.SliceString(_vm.description, 125)) + " "
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article__image-block" }, [
      _c("img", {
        staticClass: "article__image",
        attrs: {
          alt: "alt",
          src: require("./../../../assets/image/main-page-feedback/alan1s.png")
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article-date" }, [
      _c("span", { staticClass: "article-date__day" }, [_vm._v("День")]),
      _vm._v(" "),
      _c("span", { staticClass: "article-date__month" }, [_vm._v("Месяц")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }