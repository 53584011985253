<template>
  <modal
    name="call_back"
    :width="getWidth"
    :height="'auto'"
    class="modal"
    @closed="beforeClose"
    @opened="opened"
  >
    <div class="modal__closer">
      <div v-on:click="$modal.hide('call_back')">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 8.30769L17.3077 0L18 0.692308L9.69231 9L18 17.3077L17.3077 18L9 9.69231L0.692308 18L0 17.3077L8.30769 9L0 0.692308L0.692308 0L9 8.30769Z"
            fill="#0E131F"
          />
        </svg>
      </div>
    </div>
    <div class="modal__container">
      <h3 class="modal__title h3">{{title}}</h3>
      <div class="modal__switch">
        <switch-button
          v-on:status="getFormStatus"
          v-bind:id="'modal'"
          :type="true"
          :text_1="switchTextLeft"
          :text_2="switchTextRight"
        ></switch-button>
      </div>
      <form class="modal__form">
        <div class="modal__form-row">
          <div class="modal__form-group">
            <input
              class="modal__form-input"
              id="CallBackModalName"
              type="text"
              name="name"
              :placeholder="form.name.placeholder ? textName : ''"
              v-model="form.name.value"
              :class="{'modal__form-input--error': form.name.error}"
              @blur="form.name.placeholder = true"
              @focus="NameFocus()"
            />
            <label for="CallBackModalName" class="modal__form-label modal__form-label--dot">
              {{enterName}}
              <svg width="6" height="6">
                <circle r="2" fill="#E5190A" cx="3" cy="3" />
              </svg>
            </label>
          </div>
          <div class="modal__form-group">
            <input
              class="modal__form-input"
              id="CallBackModalPhone"
              type="text"
              name="name"
              v-on:input="PhoneInputHandler"
              :placeholder="form.phone.placeholder ? '+7 (___)___-__-__*': ''"
              v-model="form.phone.value"
              :class="{'modal__form-input--error': form.phone.error}"
              @blur="form.phone.placeholder = true"
              @focus="PhoneFocus()"
            />
            <label for="CallBackModalPhone" class="modal__form-label modal__form-label--dot">
              {{enterPhone}}
              <svg width="6" height="6">
                <circle r="2" fill="#E5190A" cx="3" cy="3" />
              </svg>
            </label>
          </div>
          <div class="modal__form-group">
            <input
              class="modal__form-input"
              id="CallBackModalEmail"
              type="text"
              name="name"
              :placeholder="form.email.placeholder ? 'Email*': ''"
              :disabled="form_status"
              v-model="form.email.value"
              :class="{'modal__form-input--error': form.email.error}"
              @blur="form.email.placeholder = true"
              @focus="EmailFocus()"
            />
            <label for="CallBackModalEmail" class="modal__form-label modal__form-label--dot">
              Email
              <svg width="6" height="6">
                <circle r="2" fill="#E5190A" cx="3" cy="3" />
              </svg>
            </label>
          </div>
        </div>
        <div class="modal__form-row">
          <textarea
            class="modal__form-textarea"
            name="message"
            v-model="form.textarea.value"
            :placeholder="placeholder"
            :disabled="form_status"
          ></textarea>
        </div>
        <div class="modal__form-row">
          <p class="modal__form-text very-small _textNotMobile">{{description}}</p>
        </div>
        <div class="modal__form-row" :class="{_noIndent: form_status}">
          <div :disabled="form_status" class="modal__form-column">
            <div class="modal__form-dropzone">
              <drag-and-drop
                ref="draganddrop"
                :title="DragAndDropTitle"
                :description="DragAndDropDescription"
                :disabled="form_status"
                :else-description="elseDescription"
                :file-zone="fileZone"
                :click-to-download="clickToDownload"
                :load-more-files="loadMoreFiles"
                :error-msg="errorMsg"
                :files-text="filesText"
                :too-big="tooBig"
                :other-format="otherFormat"
              ></drag-and-drop>
            </div>
          </div>
          <div :disabled="form_status" class="modal__form-column modal__form-column-brif">
            <!--<div class="modal__form-item modal__form-item" v-on:click="download()">-->
            <a :href="file" class="modal__form-item modal__form-item" download="download">
              <img
                class="modal__form-image"
                src="./../../../../assets/image/icon/modal-download.png"
                alt="Прикрепить файл"
              />
              <svg
                class="modal__form-image _gray"
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.0025 15C10.8314 15 10.6552 14.937 10.5243 14.8201L6.2001 10.961C5.9333 10.7226 5.9333 10.3403 6.2001 10.1019C6.4669 9.86357 6.89479 9.86357 7.16159 10.1019L10.3229 12.9265V0.607196C10.3229 0.269865 10.625 0 11.0025 0C11.3801 0 11.6821 0.269865 11.6821 0.607196V12.9265L14.8384 10.1019C15.1052 9.86357 15.5331 9.86357 15.7999 10.1019C16.0667 10.3403 16.0667 10.7226 15.7999 10.961L11.4807 14.8201C11.3499 14.9415 11.1737 15 11.0025 15ZM20.7394 10.6864C20.7394 10.3543 21.0195 10.0886 21.3697 10.0886C21.7199 10.0886 22 10.3587 22 10.6864V16.1249C22 18.2595 20.1698 20 17.9147 20H4.08531C1.83489 20 0 18.264 0 16.1249V10.5979C0 10.2657 0.280136 10 0.630306 10C0.980475 10 1.26061 10.2657 1.26061 10.5979V16.1249C1.26061 17.5996 2.52589 18.8043 4.08531 18.8043H17.9147C19.4694 18.8043 20.7394 17.6041 20.7394 16.1249V10.6864Z"
                  fill="#D3D3D4"
                />
              </svg>
              <!--<img class="modal__form-image _gray" src="./../../../../assets/image/icon/modal-download.png" alt="Прикрепить файл"/>-->
              <div class="modal__form-title">{{brifTitle}}</div>
            </a>
            <div class="modal__form-item">
              <p class="modal__form-text very-small _textNotMobile">{{brifDescription}}</p>
            </div>
          </div>
        </div>
        <div class="modal__form-row" :class="{_noIndent: form_status}">
          <button class="modal__form-button" type="button" v-on:click="onSubmit()">
            <span> {{ textSend }} </span>
          </button>
        </div>
        <div class="modal__form-row">
          <div class="modal__form-text very-small">
            <slot name="under-text"></slot>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import DragAndDrop from "./../../drag-and-drop/index.vue";
import SwitchButton from "./../../switch_button/switch_button.vue";
import axios from "axios";

export default {
  props: [
    "title",
    "switchTextLeft",
    "switchTextRight",
    "description",
    "button",
    "brifTitle",
    "brifDescription",
    "placeholder",
    "DragAndDropTitle",
    "DragAndDropDescription",
    "file",
    "textSuccessSwitchLeft",
    "textSuccessSwitchRight",
    "elseDescription",
    "fileZone",
    "clickToDownload",
    "loadMoreFiles",
    "errorMsg",
    "filesText",
    "tooBig",
    "otherFormat",
    "enterName",
    "enterPhone"
  ],
  name: "call_back_modal",
  components: {
    DragAndDrop,
    SwitchButton
  },
  data() {
    return {
      textSend: location.href.split('/')[3] == 'en' ? 'Send' : 'Отправить',
      textName: location.href.split('/')[3] == 'en' ? 'Name*' : 'Имя*',
      form_status: false,
      drop_start: false,
      form: {
        name: {
          value: "",
          error: false,
          placeholder: true
        },
        phone: {
          value: "",
          error: false,
          placeholder: true,
          mask: ""
        },
        email: {
          value: "",
          error: false,
          placeholder: true,
          reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        },
        textarea: {
          value: ""
        }
      },
      file_count: 0
    };
  },
  computed: {
    getWidth() {
      if (window.innerWidth >= 768) {
        return "760px";
      } else {
        return "100%";
      }
    }
  },
  mounted() {},
  methods: {
    NameErrorHandler() {
      this.form.name.error = this.form.name.value === "";
    },
    PhoneErrorHandler() {
      this.form.phone.error = this.form.phone.value === "";
    },
    /**
     * @return {boolean}
     */
    PhoneInputHandler(e) {
      // this.form.phone.value = this.form.phone.value.replace(/[^0-9]/g, '');
      // switch (this.form.phone.value[0]) {
      //   case "+":
      //     this.form.phone.mask = "+7 (###) ###-##-##";
      //     break;
      //   case "7":
      //     this.form.phone.mask = "+7 (###) ###-##-##";
      //     break;
      //   case "8":
      //     this.form.phone.mask = "8 (###) ###-##-##";
      //     break;
      //   case "9":
      //     this.form.phone.mask = "+7 (###) ###-##-##";
      //     break;
      //   default:
      //     this.form.phone.mask = "+7 (###) ###-##-##";
      //     break;
      // }
    },
    EmailErrorHandler() {
      // this.form.email.error = this.form.email.value === "";
      this.form.email.error = !this.form.email.reg.test(this.form.email.value);
    },
    NameFocus() {
      this.form.name.placeholder = false;
      this.form.name.error = false;
    },
    PhoneFocus() {
      this.form.phone.placeholder = false;
      this.form.phone.error = false;
    },
    EmailFocus() {
      this.form.email.placeholder = false;
      this.form.email.error = false;
    },
    getFormStatus($status) {
      this.form_status = $status;
      if ($status) {
        this.form.email.value = "";
        this.form.textarea.value = "";
      }
    },
    download() {
      if (!this.form_status) {
        let a = document.createElement("a");
        a.setAttribute("href", this.$props.file);
        a.setAttribute("download", "download");
        a.click();
      }
    },
    onSubmit: function() {
      this.NameErrorHandler();
      this.PhoneErrorHandler();

      if (!this.form_status) {

        this.EmailErrorHandler();
        if (
          !this.form.phone.error &&
          !this.form.name.error &&
          !this.form.email.error
        ) {
          let fd = new FormData();

          this.$refs.draganddrop.$data.files.forEach(file => {
            fd.append("files[]", file, file.name);
          });

          fd.append("name", this.form.name.value);
          fd.append("phone", this.form.phone.value);
          fd.append("email", this.form.email.value);
          fd.append("description", this.form.textarea.value);

          // let body =
          //   "name=" +
          //   encodeURIComponent(this.form.name.value) +
          //   "&phone=" +
          //   encodeURIComponent(this.form.phone.value) +
          //   "&email=" +
          //   encodeURIComponent(this.form.email.value) +
          //   "&description=" +
          //   encodeURIComponent(this.form.textarea.value);

		  let _this = this;
		  
		  // console.log(fd)

          axios({
			method: "post",
			headers: {
				content: 'Content-type: multipart/form-data'
			},
            url: "/ajax/callback.php",
            data: fd
          })
            .then(response => {
              _this.onSuccess();
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        if (!this.form.phone.error && !this.form.name.error) {
          let body =
            "name=" +
            encodeURIComponent(this.form.name.value) +
            "&phone=" +
            encodeURIComponent(this.form.phone.value);

          let _this = this;

          axios({
            method: "post",
            url: "/ajax/call.php",
            data: body
          })
            .then(response => {
              _this.onSuccess();
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    onSuccess: function() {
      this.$modal.hide("call_back");
      setTimeout(()=>{
        this.$modal.show("call_back_success", {
          status: this.form_status,
          textLeft: this.$props.textSuccessSwitchLeft,
          textRight: this.$props.textSuccessSwitchRight
        });
      }, 500)
    },
    beforeClose: function() {
      this.form.phone.value = "";
      this.form.name.value = "";
      this.form.email.value = "";
      this.form_status = true;
      // костыль ввиде костыля ( нужно для обнуления дропдауна )
      setTimeout(() => {
        this.form_status = false;
      }, 200);

      if (window.innerWidth > 767) {
        document.getElementsByTagName("html")[0].style.overflowY = "auto";
      }
      // else {
      let elem = document.querySelector("#app");
      let heightScroll = elem.style.top;

      elem.style.position = "static";
      elem.style.width = "100%";
      elem.style.top = "";

      window.scrollTo({
        top: -parseInt(heightScroll),
        behavior: "instant"
      });
      // }
    },
    opened: function() {
      if (window.innerWidth > 767) {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
      }
      // else {
      let heightScroll = window.pageYOffset;
      let elem = document.querySelector("#app");

      elem.style.position = "fixed";
      elem.style.width = "100%";
      elem.style.top = -heightScroll + "px";
      // }
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #242428;

  display: flex;
  align-items: center;
  justify-content: center;

  &__closer {
    display: flex;
    justify-content: flex-end;
    //margin: 20px 20px 0 0;

    & div {
      cursor: pointer;
      width: 18px;
      height: 18px;
      user-select: none;
      padding: 20px;
    }
    & div:hover {
      & svg {
        transform: rotate(90deg);
      }
    }
    & svg {
      cursor: pointer;
      transition: transform 0.5s;
    }
  }

  &__title {
    text-align: center;
    margin: 0 0 0 0;
  }

  &__container {
    padding: 0 40px 40px;
    width: 690px;
    min-height: 600px;
    height: auto;
    background: #fff;
  }

  &__switch {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  &__form {
    margin-top: 25px;
    width: 100%;

    &-item {
      display: flex;
      width: 100%;
      /*width: fit-content;*/
      /*width: -moz-fit-content;*/

      &:nth-child(1) {
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.25s;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &-image {
      margin-right: 10px;

      &._gray {
        display: none;
      }
    }

    &-title {
      font-size: 14px;
      line-height: 20px;
      color: #e5190a;
      font-family: "PT Root UI", sans-serif;
      cursor: pointer;
      &-addfile {
        margin-top: -25px;
      }
    }

    &-column {
      width: 320px;
      text-decoration: none;

      &-brif {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &[disabled] {
        //filter: grayscale(100%);
        pointer-events: none;

        .modal__form-title,
        .modal__form-text {
          color: #242428;
        }
        .modal__form-image:not(._gray) {
          display: none;
        }
        .modal__form-image._gray {
          display: block;
        }
      }
    }

    &-row {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-top: 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(4) {
        margin-top: 40px;
      }

      &:nth-child(5) {
        justify-content: center;
        margin-top: 34px;
      }

      &:last-child {
        text-align: center;
        justify-content: center;
      }
    }

    &-text {
      color: #242428;
      margin-top: 5px;
      text-align: left;
    }

    &-button {
      mix-blend-mode: normal;
      border: 1px solid rgba(151, 151, 151, 0.4);
      box-sizing: border-box;
      border-radius: 35px;
      font-family: "PT Root UI", sans-serif;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      background-color: transparent;
      width: 175px;
      height: 70px;
      font-weight: 700;
      cursor: pointer;

      color: #242428;
      transition: 0.25s;

      &:hover {
        background-color: #e5190a;
        box-shadow: 0px 20px 20px rgba(229, 25, 10, 0.166667);
        color: #fff;
        border: 1px solid transparent;

        span {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }

    &-link {
      color: #e5190a;
    }

    &-input {
      width: 200px;
      height: 40px;
      border: 1px solid #d3d3d4;
      box-sizing: border-box;
      border-radius: 3px;
      color: #242428;
      font-size: 14px;
      font-family: "PT Root UI", sans-serif;
      font-weight: 400;
      padding-left: 20px;

      &[disabled] {
        background: rgba(211, 211, 212, 0.2);
        cursor: default;
        border-color: rgba(211, 211, 212, 0.2);
      }

      &:last-child {
        margin-right: 0;
      }

      &::placeholder {
        color: #d3d3d4;
        font-size: 14px;
        font-family: "PT Root UI", sans-serif;
        font-weight: 400;
      }
      &::-ms-input-placeholder {
        color: rgba(211, 211, 212, 0.4);
        font-size: 14px;
        font-family: "PT Root UI", sans-serif;
        font-weight: 400;
      }
      &::-moz-placeholder {
        color: #d3d3d4;
        font-size: 14px;
        font-family: "PT Root UI", sans-serif;
        font-weight: 400;
      }
      &::-webkit-input-placeholder {
        color: #d3d3d4;
        font-size: 14px;
        font-family: "PT Root UI", sans-serif;
        font-weight: 400;
      }

      &--error {
        border-color: #e5190a;
      }
      &:focus {
        outline: none;
        & ~ .modal__form-label {
          opacity: 1;
          top: -11px;
        }
      }
    }

    &-label {
      font-family: "PT Root UI", sans-serif;
      font-size: 12px;
      line-height: 20px;

      cursor: text;

      padding: 0 4px;

      color: #242428;

      position: absolute;
      left: 20px;
      top: 10px;

      opacity: 0;

      text-align: center;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;

      &--active {
        opacity: 1;
        top: -10px;
      }

      &--dot {
        & > svg {
          //margin-top:1px;
          margin-left: 3px;
        }
        &::after {
          //content: '';
          //width: 3.1px;
          //height: 3.1px;
          //background: #E5190A;
          //border-radius: 100%;
          //margin-left: 4px;
          //display: block;
          //position: relative;
          //top:1px;
        }
      }
    }

    &-group {
      position: relative;
    }

    &-textarea {
      width: 100%;
      height: 105px;
      border: 1px solid #d3d3d4;
      resize: none;
      padding-left: 20px;
      padding-top: 8px;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 14px;
      line-height: 20px;
      font-family: "PT Root UI", sans-serif;
      font-weight: 400;
      color: #242428;

      &[disabled] {
        background: rgba(211, 211, 212, 0.2);
        border-color: rgba(211, 211, 212, 0.2);
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        display: flex;
        align-items: center;
        color: #d3d3d4;
        font-size: 14px;
        line-height: 20px;
        font-family: "PT Root UI", sans-serif;
        font-weight: 400;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }

    &-dropzone {
      position: relative;
    }
  }
}

.dropzone {
  width: 320px;
  height: 90px;
  min-height: 90px;
  z-index: 100;
  padding: 0;

  border: 1px dashed #fff;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "PT Root UI", sans-serif;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #7a7a7c;

  &__out {
    & .modal__form-title-addfile {
      margin-top: 0;
    }
    margin-bottom: 29px;
    margin-left: 1px;
    letter-spacing: 0.2px;
  }

  &:hover {
    background: transparent;
  }
}
.dropzone_dropped {
  background: #fff8f8 !important;
  border: 1px dashed #d3d3d4 !important;
  opacity: 1 !important;

  & .dropzone_false {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.dropzone .dz-preview {
  height: 50px;
  min-height: 50px;
}
.vue-dropzone {
  z-index: 100;
  position: relative;
  opacity: 0 !important;
}
.vue-dropzone > .dz-preview .dz-remove {
  bottom: 2px;
  right: 5px;
}
.dropzone_true {
  position: absolute;
  left: 0;
  top: 27px;
}
.dropzone_false {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff8f8 !important;
  border: 1px dashed #d3d3d4 !important;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .modal__form-title-addfile {
    margin-top: 0;
  }
}
.dz-started {
  opacity: 1 !important;

  & ~ .dropzone_false {
    background: none !important;
    border: 1px dashed transparent !important;
    opacity: 1 !important;
  }
}
.dz-image {
  display: none !important;
}
.dropzone .dz-preview.dz-image-preview {
  background: transparent;
  width: 224px;
}
.dropzone .dz-preview .dz-details {
  opacity: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding: 0;

  &::before {
    position: absolute;
    left: -22px;
    top: 0;
    content: "";
    display: block;
    height: 22px;
    width: 20px;
    background-image: url("./../../../../assets/image/icon/file.svg");
  }
}
.vue-dropzone > .dz-preview .dz-remove {
  opacity: 1;
  border: none;
  color: #000;
  padding: 0;
  top: 2px;
  right: -13px;
  margin: 0;
  height: 13px;

  &::before {
    content: "";
    display: block;
    width: 13px;
    transition: opacity 0.25s;
    height: 13px;
    background-image: url("./../../../../assets/image/icon/file_plus.svg");
    opacity: 0.3;
    //background-size:cover;
  }

  &:hover::before {
    opacity: 1;
  }
}
.dropzone .dz-preview .dz-details .dz-size {
  font-size: 12px;
  font-family: "PT Root UI", sans-serif;
  line-height: 15px;
  color: #7a7a7c;
}
.dropzone .dz-preview .dz-details .dz-filename {
  color: #e5190a;
  font-size: 12px;
  font-family: "PT Root UI", sans-serif;
  line-height: 15px;
}
.modal__form-column[disabled] .modal__form-item,
.modal__form-column[disabled] .modal__form-item .modal__form-title,
.modal__form-column[disabled] .modal__form-item .modal__form-image {
  cursor: default;
}
.modal__form-column[disabled] .modal__form-item .modal__form-text {
  cursor: text;
}
.modal__form-column[disabled] .modal__form-item:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .modal {
    align-items: flex-start;
  }
  .modal__container {
    padding: 0 40px 100px;
    width: calc(100% - 80px);
    min-width: calc(100% - 80px);
    min-height: auto;
  }
  .modal__form-row {
    flex-direction: column;
    &._noIndent {
      margin: 0 !important;
    }
  }
  .modal__form-input {
    width: 100%;
  }
  .modal__form-group {
    margin-bottom: 16px;
  }

  .modal__form-text._textNotMobile {
    display: none;
  }
  .modal__form-button,
  .modal__button {
    font-size: 18px;
    height: 56px;
    margin: 20px auto;
  }
  .modal__form-row {
    margin-top: 0;
  }
  .modal__form-input[disabled="disabled"],
  .modal__form-textarea[disabled="disabled"],
  .modal__form-column[disabled="disabled"] {
    display: none;
  }
  .modal__form-item {
    //width: 125px;
    margin-top: 20px;
  }
  .modal__switch {
    margin-top: 32px;
  }
}
</style>