<template>
    <a href="/" class="articles__item article">
        <div class="article__image-block">
            <img alt="alt" class="article__image" src="./../../../assets/image/main-page-feedback/alan1s.png">
        </div>
        <div class="article__content">
            <div class="article-date">
                <span class="article-date__day">День</span>
                <span class="article-date__month">Месяц</span>
            </div>
            <div class="article-text">
                <h3 class="article-text__title"> Заголовок </h3>
                <p class="article-text__description"> Описание {{ SliceString(description, 125) }} </p>
            </div>
        </div>
    </a>
</template>
<style lang="scss" scoped>

</style>
<script>
    export default {
        name: "component_article",
        props: {
            href: {
                default: "",
            },
            day: {
                default: ""
            },
            month: {
                default: ""
            },
            title: {
                required: false,
                default: ""
            },
            description: {
                required: false,
                default: ""
            },
            img: {
                required: true,
                default: "http://placehold.it/360x300"
            }
        },
        data: function() {
            return {};
        },
        methods: {
            /**
             * @return {string}
             */
            SliceString(string, count) {
                if (string.length >= count) {
                    return `${string.slice(0, count)}...`;
                } else {
                    return string;
                }
            }
        }
    };

</script>