<template>
    <div class="">
        <slot name="test"></slot>
    <div class="clients">
        <h2 class="clients__title h2" v-if="main === false">Клиенты</h2>
        <div class="clients__wrapper">
            <!--<div class="clients__column" v-bind:class="{'clients__column-type': nowUrl === '/method/detail.php' || type, 'clients__column-type-cols': type && cols}" v-for="(column, i) in clients_arr">-->
            <div class="clients__column" v-bind:class="{'clients__column-type': type, 'clients__column-type-cols': type && cols}" v-for="(column, i) in clients_arr">

                <transition v-if="now[i]" mode="out-in" :name="n % 2 ? 'flip': 'flip1'" v-for="(item, n) in column" :key="'client' + n">
                    <div class="clients__group"
                         v-if="n === now[i].now || n === now[i].now + 1"
                         v-bind:key="n"
                    >
                        <div class="clients__logo"
                           v-bind:class="{'clients__logo--hover': hover_id === i + 1}"
                        >
<!--                            <img :alt="item.imageAlt" :src="item.image"/>-->
                            <img alt="" :src="item.image"/>
                        </div>
                    </div>
                </transition>
            </div>
        </div>

        <!-- для мобильной версии -->
        <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
            <swiper-slide class="clients__column" v-bind:class="{'clients__column-type': type, 'clients__column-type-cols': type && cols}" v-for="(column, i) in clients_arr">

                <transition v-if="now[i]" mode="out-in" :name="n % 2 ? 'flip': 'flip1'" v-for="(item, n) in column" :key="'client' + n">
                    <div class="clients__group"
                         v-if="n === now[i].now || n === now[i].now + 1"
                         v-bind:key="n"
                    >
                        <div class="clients__logo"
                             v-bind:class="{'clients__logo--hover': hover_id === i + 1}"
                        >
                            <img :alt="item.imageAlt" :src="item.image"/>
                        </div>
                    </div>
                </transition>
            </swiper-slide>
            <div v-if="clients_arr.length > 0" class="swiper-button-next-clients" slot="button-next">
                <svg width="53" height="22" viewBox="0 0 53 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M51.0149 10.4815L41.2671 0.733333L42.0004 0L53 11L42.0004 22L41.2671 21.2667L51.0149 11.5185H0V10.4815H51.0149Z" fill="#7A7A7C"/>
                </svg>
            </div>
            <div v-if="clients_arr.length > 0" class="swiper-button-prev-clients" slot="button-prev">
                <svg width="53" height="22" viewBox="0 0 53 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M1.98513 10.4815L11.7329 0.733333L10.9996 0L0 11L10.9996 22L11.7329 21.2667L1.98513 11.5185H53V10.4815H1.98513Z" fill="#7A7A7C"/>
                </svg>
            </div>
        </swiper>


    </div>
        <!-- для мобильной версии -->
        <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
            <swiper-slide v-for="(item,n) in cols_arr" class="main-page-client-wrap"
                          :style="{'height': getHeightCols(n) + 'px',}">
                          <!--v-on:click="clickMainClientHandler(n)"-->
                <div
                        class="main-page-client-col main-page-client-col-1"
                        :style="{'height': getHeightCols(n) + 'px', 'opacity': getOpacity(n)}"></div>
                        <!--@mouseenter="HoverEnter(n + 1, $event)"-->
                        <!--@mouseleave="HoverLeave"-->

                <span class="main-page-client-col-text" v-html="item.text"></span>
            </swiper-slide>
        </swiper>

    </div>

</template>
<style lang="scss" scoped>


    @keyframes item-out {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(100px) rotateX(90deg);
        }
    }

    @keyframes item-out-2 {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(250px) rotateX(90deg);
        }
    }

    @keyframes item-in {
        0% {
            transform: translateY(-250px) rotateX(-90deg);
        }
        100% {
            transform: translateY(0) rotateX(0);
        }
    }
    @keyframes item-in-2 {
        0% {
            transform: translateY(-100px) rotateX(-90deg);
        }
        100% {
            transform: translateY(0) rotateX(0);
        }
    }

    .clients {
        padding: 0;
        &__title {
            color: #242428;
            margin: 0 0 30px 0;
        }

        &__wrapper {
            display: flex;
            justify-content: flex-start;
        }

        &__column {
            width: calc(100% / 7);
            height: 290px;
            padding:15px 0;
            display: flex;
            flex-direction: column;
            /*justify-content: space-around;*/
            justify-content: flex-start;
            align-items: center;
            border-left: 1px solid rgba(36, 36, 40, 0.1);
            position: relative;
            &:last-child {
                border-right: 1px solid rgba(36, 36, 40, 0.1);
            }

            &-type {
                padding-top: 340px;
                margin-top: -340px;

                & .clients__group {
                    position: absolute;
                    left: 0;
                    right: auto;
                    bottom: auto;
                    width:100%;
                    &:nth-child(odd) {
                        top: 340px
                    }
                    &:nth-child(even) {
                        top: 500px;
                    }
                    /*&:nth-child(3) {*/
                        /*top:340px;*/
                    /*}*/
                    /*&:nth-child(4) {*/
                        /*top:500px;*/
                    /*}*/
                }

                &-cols {
                    padding-bottom: 378px;
                    margin-bottom: -282px;
                }
            }
        }

        &__logo {
            //transform: translateY(0);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 150px;
            //&:nth-child(2n - 1) {
            //  animation: item-in-2 2s;
            //  animation-play-state: paused;
            //}
            //&:nth-child(2n) {
            //  animation: item-in 2s;
            //  animation-play-state: paused;
            //}

            /*& > img {*/
                transition: 0.3s;
                filter: url('./../../../assets/image/filters.svg#grayscale');
                -webkit-filter: grayscale(1); /* Webkit Nightlies, Google Chrome Canary and Microsoft Edge*/
                -webkit-transform: translateZ(0);
                -webkit-perspective: 1000px;
                -webkit-backface-visibility: hidden;
                opacity: 0.3;
            /*}*/
            img {
                max-width: 100%;
                max-height: 100%;
            }

            &--hover, &:hover {
                /*& > img {*/
                    -webkit-filter: grayscale(0);
                    opacity: 1;
                /*}*/
            }

        }

    }

    /* for IE 10+ */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .clients__logo {
            opacity: 1;
        }
    }

    .gallery-top, .gallery-thumbs {
        display: none;
    }

    @media (max-width: 1200px) {
        .clients__logo > img {
            /*max-width: calc(100% - 24px);*/
            max-width: 100%;
        }
        .clients__column-type .clients__group, .clients__group{
            padding: 0 12px;
            width: calc(100% - 24px);
        }
        .clients__logo > img {
            max-height: 64px;
        }

        .clients__logo {
            height: 120px;
        }
        /*.clients__group {*/
            /*width: 100%;*/
        /*}*/

        .clients__column-type-cols {
            padding-bottom: 350px;
        }
        .clients__column-type .clients__group:nth-child(odd) {
            top: 320px;
        }
        .clients__column-type .clients__group:nth-child(even) {
            top: 450px;
        }
        .clients__column {
            height: 240px;
        }
    }

    @media (max-width: 767px) {
        .clients__wrapper, .main-page-client-cols {
            display: none;
        }
        .gallery-top, .gallery-thumbs {
            display: block;
        }
        .main__clients .gallery-top {
            padding: 55px 0 0 !important;
            margin: 0;
        }
        .main__clients .gallery-thumbs {
            padding: 55px 26px 0 25px !important;
            margin: 0 -26px 0 -25px;
        }
        .main-page-client-wrap {
            border-left: 1px solid transparent;
        }
        .main__clients .gallery-top {
            //width: calc(100% - 2px);
        }

        .clients__logo {
            height: 150px;
        }
        .clients__logo > img {
            max-height: 90px;
        }

        .clients__column-type-cols {
            padding-bottom: 370px;
        }
        .clients__column-type .clients__group:nth-child(odd) {
            top: 340px;
        }
        .clients__column-type .clients__group:nth-child(even) {
            top: 500px;
        }
        .clients__column {
            height: 290px;
        }

        .clients .swiper-container {
            overflow: visible !important;
            position: relative;
            height: 386px;
        }
        .gallery-thumbs .main-page-client-wrap {
            align-self: flex-end;
        }

        .work-clients .clients .swiper-container {
            padding-bottom: 90px;
            height: 320px;
        }
    }



    .flip-enter-active, .flip-leave-active {
        transition: all 0.15s ease-in-out; //ease-in-quad
    }

    .flip-enter-active {
        opacity:1;
    }

    .flip-enter {
        //transform: translateY(-300px);
        opacity:0;
    }

    .flip-leave-to {
        //transform: translateY(200px);
        opacity:0;

    }

    .flip1-enter-active, .flip1-leave-active {
        transition: all 0.15s ease-in-out; //ease-in-quad
    }

    .flip1-enter-active {
        opacity:1;
    }

    .flip1-enter {
        //transform: translateY(-250px);
        opacity:0;
    }

    .flip1-leave-to {
        //transform: translateY(250px);
        opacity:0;

    }


</style>
<script>
    import "swiper/dist/css/swiper.css";

    import { swiper, swiperSlide } from "vue-awesome-swiper";

    export default {
        name: "component_clients",

        props: {
            clients_arr: {
                default: () => {
                    return []
                }
            },
            type: {
                default: () => {
                    return false;
                }
            },
            main: {
                default: () => {
                    return false;
                }
            },
            hover_id: {
                default: () => {
                    return null;
                }
            },
            cols: {
                default: () => {
                    return true;
                }
            },
            max: {
                default: () => {
                    return 4;
                }
            },
            cols_arr: {
                default: () => {
                    return [];
                }
            }
        },
        components: {
            swiper,
            swiperSlide
        },
        data: function() {
            return {
                swiperOptionTop: {
                    // slidesPerView: 2,
                    // spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next-clients',
                        prevEl: '.swiper-button-prev-clients'
                    },
                    breakpoints: {
                        767: {
                            slidesPerView: 3,
                        },
                        480: {
                            slidesPerView: 2,
                        }
                    }
                },
                swiperOptionThumbs: {
                    // spaceBetween: 10,
                    // slidesPerView: 2,
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    breakpoints: {
                        767: {
                            slidesPerView: 3,
                        },
                        480: {
                            slidesPerView: 2,
                        }
                    }
                },
                main_client: {
                    status: true,
                    hover_id: null,

                    height: ["216", "246", "276", "298", "276", "246", "216"],
                    opacity: ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"],

                    now: []
                },

                nowUrl: window.location.pathname,
                now: [],
                nodes: [],
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            }
        },
        mounted: function() {
            // Генерируем массив для "слайдера"
            for (let i = 0; i < this.clients_arr.length; i++) {
                this.now.push({
                    now: 0,
                    max: this.clients_arr[i].length
                });
            }

            this.$emit("getnow", this.now);
            this.getNow(this.now);


            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.swiper;
                const swiperThumbs = this.$refs.swiperThumbs.swiper;
                swiperTop.controller.control = swiperThumbs;
                swiperThumbs.controller.control = swiperTop;
            });
        },
        methods: {
            // дублирование методов из main-page-client/script.js, нужно другое решение для слайдера на мобилке
            HoverEnter(id) {
                this.main_client.hover_id = id;
                this.setOpacity(id - 1);
            },
            HoverLeave() {
                this.main_client.hover_id = null;
                this.emptyOpacity();
            },
            getHeightCols(n) {
                return this.main_client.height[n];
            },
            getOpacity(n) {
                return this.main_client.opacity[n];
            },
            setOpacity(n) {
                let arr = ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"];


                switch(n) {
                    case 0:
                        arr = ["1", "0.65", "0.45", "0.35", "0.25", "0.15", "0.1"];
                        break;
                    case 1:
                        arr = ["0.65", "1", "0.65", "0.45", "0.35", "0.25", "0.15"];
                        break;
                    case 2:
                        arr = ["0.45", "0.65", "1", "0.65", "0.45", "0.35", "0.25"];
                        break;
                    case 3:
                        arr = ["0.35", "0.45", "0.65", "1", "0.65", "0.45", "0.35"];
                        break;
                    case 4:
                        arr = ["0.25", "0.35", "0.45", "0.65", "1", "0.65", "0.45"];
                        break;
                    case 5:
                        arr = ["0.15", "0.25", "0.35", "0.45", "0.65", "1", "0.65"];
                        break;
                    case 6:
                        arr = ["0.1", "0.15", "0.25", "0.35", "0.45", "0.65", "1"];
                        break;
                }
                this.main_client.opacity = arr;
            },
            emptyOpacity() {
                let arr = ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"];

                this.main_client.opacity = arr;
            },
            clickMainClientHandler(n) {
                if (this.main_client.now[n].max > this.main_client.now[n].now + 2) {
                    this.main_client.now[n].now = this.main_client.now[n].now + 2;
                } else {
                    this.main_client.now[n].now = 0;
                }
            },
            getNow(array) {
                this.main_client.now = array;
            }
        }
    };

</script>