var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "modal",
      attrs: { name: "call_back-rec", width: _vm.getWidth, height: "auto" },
      on: { closed: _vm.beforeClose, opened: _vm.opened }
    },
    [
      _c("div", { staticClass: "modal__closer" }, [
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.$modal.hide("call_back-rec")
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "18",
                  height: "18",
                  viewBox: "0 0 18 18",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    opacity: "0.3",
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d:
                      "M9 8.30769L17.3077 0L18 0.692308L9.69231 9L18 17.3077L17.3077 18L9 9.69231L0.692308 18L0 17.3077L8.30769 9L0 0.692308L0.692308 0L9 8.30769Z",
                    fill: "#0E131F"
                  }
                })
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__container" }, [
        _c("h3", { staticClass: "modal__title h3" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal__switch" },
          [
            _c("switch-button", {
              attrs: {
                id: "modal",
                type: true,
                text_1: _vm.switchTextLeft,
                text_2: _vm.switchTextRight
              },
              on: { status: _vm.getFormStatus }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("form", { staticClass: "modal__form" }, [
          _c("div", { staticClass: "modal__form-row" }, [
            _c("div", { staticClass: "modal__form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name.value,
                    expression: "form.name.value"
                  }
                ],
                staticClass: "modal__form-input",
                class: { "modal__form-input--error": _vm.form.name.error },
                attrs: {
                  id: "CallBackModalName",
                  type: "text",
                  name: "name",
                  placeholder: _vm.form.name.placeholder ? _vm.textName : ""
                },
                domProps: { value: _vm.form.name.value },
                on: {
                  blur: function($event) {
                    _vm.form.name.placeholder = true
                  },
                  focus: function($event) {
                    return _vm.NameFocus()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form.name, "value", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "modal__form-label modal__form-label--dot",
                  attrs: { for: "CallBackModalName" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.enterName) +
                      "\n                        "
                  ),
                  _c("svg", { attrs: { width: "6", height: "6" } }, [
                    _c("circle", {
                      attrs: { r: "2", fill: "#E5190A", cx: "3", cy: "3" }
                    })
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal__form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.phone.value,
                    expression: "form.phone.value"
                  }
                ],
                staticClass: "modal__form-input",
                class: { "modal__form-input--error": _vm.form.phone.error },
                attrs: {
                  id: "CallBackModalPhone",
                  type: "text",
                  name: "name",
                  placeholder: _vm.form.phone.placeholder
                    ? "+7 (___)___-__-__*"
                    : ""
                },
                domProps: { value: _vm.form.phone.value },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form.phone, "value", $event.target.value)
                    },
                    _vm.PhoneInputHandler
                  ],
                  blur: function($event) {
                    _vm.form.phone.placeholder = true
                  },
                  focus: function($event) {
                    return _vm.PhoneFocus()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "modal__form-label modal__form-label--dot",
                  attrs: { for: "CallBackModalPhone" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.enterPhone) +
                      "\n                        "
                  ),
                  _c("svg", { attrs: { width: "6", height: "6" } }, [
                    _c("circle", {
                      attrs: { r: "2", fill: "#E5190A", cx: "3", cy: "3" }
                    })
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal__form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email.value,
                    expression: "form.email.value"
                  }
                ],
                staticClass: "modal__form-input",
                class: { "modal__form-input--error": _vm.form.email.error },
                attrs: {
                  id: "CallBackModalEmail",
                  type: "text",
                  name: "name",
                  placeholder: _vm.form.email.placeholder ? "Email*" : "",
                  disabled: _vm.form_status
                },
                domProps: { value: _vm.form.email.value },
                on: {
                  blur: function($event) {
                    _vm.form.email.placeholder = true
                  },
                  focus: function($event) {
                    return _vm.EmailFocus()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form.email, "value", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "modal__form-label modal__form-label--dot",
                  attrs: { for: "CallBackModalEmail" }
                },
                [
                  _vm._v(
                    "\n                        Email\n                        "
                  ),
                  _c("svg", { attrs: { width: "6", height: "6" } }, [
                    _c("circle", {
                      attrs: { r: "2", fill: "#E5190A", cx: "3", cy: "3" }
                    })
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__form-row" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.textarea.value,
                  expression: "form.textarea.value"
                }
              ],
              staticClass: "modal__form-textarea",
              attrs: {
                name: "message",
                placeholder: _vm.placeholder,
                disabled: _vm.form_status
              },
              domProps: { value: _vm.form.textarea.value },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form.textarea, "value", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__form-row" }, [
            _c(
              "p",
              { staticClass: "modal__form-text very-small _textNotMobile" },
              [_vm._v(_vm._s(_vm.description))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__form-row" }, [
            _c(
              "div",
              {
                staticClass: "modal__form-column",
                attrs: { disabled: _vm.form_status }
              },
              [
                _c(
                  "div",
                  { staticClass: "modal__form-dropzone" },
                  [
                    _c("drag-and-drop", {
                      ref: "draganddrop",
                      attrs: {
                        title: _vm.DragAndDropTitle,
                        description: _vm.DragAndDropDescription,
                        disabled: _vm.form_status,
                        "else-description": _vm.elseDescription,
                        "file-zone": _vm.fileZone,
                        "click-to-download": _vm.clickToDownload,
                        "load-more-files": _vm.loadMoreFiles,
                        "error-msg": _vm.errorMsg,
                        "files-text": _vm.filesText,
                        "too-big": _vm.tooBig,
                        "other-format": _vm.otherFormat
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal__form-column",
                attrs: { disabled: _vm.form_status }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "modal__form-item modal__form-item",
                    attrs: { href: _vm.file, download: "download" }
                  },
                  [
                    _c("img", {
                      staticClass: "modal__form-image",
                      attrs: {
                        src: require("./../../../../assets/image/icon/modal-download.png"),
                        alt: "Прикрепить файл"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "modal__form-image _gray",
                        attrs: {
                          width: "22",
                          height: "20",
                          viewBox: "0 0 22 20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M11.0025 15C10.8314 15 10.6552 14.937 10.5243 14.8201L6.2001 10.961C5.9333 10.7226 5.9333 10.3403 6.2001 10.1019C6.4669 9.86357 6.89479 9.86357 7.16159 10.1019L10.3229 12.9265V0.607196C10.3229 0.269865 10.625 0 11.0025 0C11.3801 0 11.6821 0.269865 11.6821 0.607196V12.9265L14.8384 10.1019C15.1052 9.86357 15.5331 9.86357 15.7999 10.1019C16.0667 10.3403 16.0667 10.7226 15.7999 10.961L11.4807 14.8201C11.3499 14.9415 11.1737 15 11.0025 15ZM20.7394 10.6864C20.7394 10.3543 21.0195 10.0886 21.3697 10.0886C21.7199 10.0886 22 10.3587 22 10.6864V16.1249C22 18.2595 20.1698 20 17.9147 20H4.08531C1.83489 20 0 18.264 0 16.1249V10.5979C0 10.2657 0.280136 10 0.630306 10C0.980475 10 1.26061 10.2657 1.26061 10.5979V16.1249C1.26061 17.5996 2.52589 18.8043 4.08531 18.8043H17.9147C19.4694 18.8043 20.7394 17.6041 20.7394 16.1249V10.6864Z",
                            fill: "#D3D3D4"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal__form-title" }, [
                      _vm._v(_vm._s(_vm.brifTitle))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal__form-item" }, [
                  _c(
                    "p",
                    {
                      staticClass: "modal__form-text very-small _textNotMobile"
                    },
                    [_vm._v(_vm._s(_vm.brifDescription))]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__form-row" }, [
            _c(
              "button",
              {
                staticClass: "modal__form-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.onSubmit()
                  }
                }
              },
              [_c("span", [_vm._v(" " + _vm._s(_vm.textSend) + " ")])]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__form-row" }, [
            _c(
              "div",
              { staticClass: "modal__form-text very-small" },
              [_vm._t("under-text")],
              2
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }