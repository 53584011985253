<template>
    <g v-tooltip.top="getOptions()" transform="translate(0, 50)" class="svg__group"
       v-bind:class="{'svg__group--active': true}">
        <text font-size="18" class="svg__text" width="100" :x="text_x" :y="text_y" fill="#242428"
              text-anchor="middle">{{title}}</text>
        <circle class="hover" fill="#E5190A" :cx="circle_cx" :cy="circle_cy" r="8" fill-opacity="0.12" />
        <circle class="hover opacity" fill="#E5190A" :cx="circle_cx" :cy="circle_cy" r="8" fill-opacity="0.12">
            <animate attributeType="SVG" attributeName="r" begin="0s" dur="1.5s" repeatCount="indefinite" from="0%"
                     to="2%" />
            <animate attributeType="CSS" attributeName="opacity" begin="0s" dur="1.5s" repeatCount="indefinite"
                     from="1" to="0" />
        </circle>
        <circle class="hover " fill="#E5190A" :cx="circle_cx" :cy="circle_cy" r="4" />
    </g>
</template>
<style lang="scss" scoped>
    .svg__text {
        font-family: 'PT Root UI', sans-serif;
        font-size: 18px;
        line-height: 36px;
        font-weight: 700;
    }
</style>
<script>
    export default {
        props: ['title', 'description', 'text_x', 'text_y', 'circle_cx', 'circle_cy'],

        data: function () {
          return {
              options: {
                  content: "",
                  hideOnTargetClick: true,
                  targetClasses: "tooltip",
                  trigger: "hover"
              },
          }
        },

        methods: {
            getOptions() {
                let a = this.options;
                let b = `
                    <div class=custom_tooltip>
                        <h4 class=h4>${
                            this.title
                    }</h4>
                        <p class=p>${this.description}</p>
                    </div>
                    `;

                return (a.content = b);
            }
        }
    }
</script>