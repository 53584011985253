var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top",
          value: _vm.getOptions(),
          expression: "getOptions()",
          modifiers: { top: true }
        }
      ],
      staticClass: "svg__group",
      class: { "svg__group--active": true },
      attrs: { transform: "translate(0, 50)" }
    },
    [
      _c(
        "text",
        {
          staticClass: "svg__text",
          attrs: {
            "font-size": "18",
            width: "100",
            x: _vm.text_x,
            y: _vm.text_y,
            fill: "#242428",
            "text-anchor": "middle"
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c("circle", {
        staticClass: "hover",
        attrs: {
          fill: "#E5190A",
          cx: _vm.circle_cx,
          cy: _vm.circle_cy,
          r: "8",
          "fill-opacity": "0.12"
        }
      }),
      _vm._v(" "),
      _c(
        "circle",
        {
          staticClass: "hover opacity",
          attrs: {
            fill: "#E5190A",
            cx: _vm.circle_cx,
            cy: _vm.circle_cy,
            r: "8",
            "fill-opacity": "0.12"
          }
        },
        [
          _c("animate", {
            attrs: {
              attributeType: "SVG",
              attributeName: "r",
              begin: "0s",
              dur: "1.5s",
              repeatCount: "indefinite",
              from: "0%",
              to: "2%"
            }
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              attributeType: "CSS",
              attributeName: "opacity",
              begin: "0s",
              dur: "1.5s",
              repeatCount: "indefinite",
              from: "1",
              to: "0"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("circle", {
        staticClass: "hover ",
        attrs: { fill: "#E5190A", cx: _vm.circle_cx, cy: _vm.circle_cy, r: "4" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }