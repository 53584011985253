<template>
    <div class="info-block" v-bind:class="{'info-block--white': color === 'white'}">
        <div class="info-block__box">

            <img :alt="title" :src="icon"/>

        </div>

        <span class="info-block__title" v-if="title !== 'none'">{{ title }}</span>
        <span class="info-block__subtitle" v-html="subtitle"></span>
    </div>


</template>
<script>
    export default {
        name: "info-block",
        components: {},
        props: {
            icon: {
                default: () => {
                    return require("./../../../assets/image/Shape.png");
                }
            },

            title: {
                default: () => {
                    return "Default";
                }
            },

            subtitle: {
                default: () => {
                    return "Default";
                }
            },

            color: {
                default: () => {
                    return "";
                }
            }
        },
        data() {
            return {};
        },
        computed: {},
        mounted() {},
        methods: {}
    };

</script>
<style lang="scss" scoped>
    .info-block {
        display: flex;
        flex-direction: column;


        &__box {
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E5190A;
            box-shadow: 0px 14px 14px rgba(229, 25, 10, 0.15942);
        }

        &__title {
            font-size: 20px;
            line-height: 24px;
            font-family: 'PT Root UI', sans-serif;
            font-weight: 500;
            display: block;
            margin-top: 30px;

            color: #242428;

            .info-block--white & {
                color: #fff;
            }
        }

        &__subtitle {
            display: block;
            margin-top: 17px;
            font-size: 16px;
            line-height: 24px;
            font-family: 'PT Root UI', sans-serif;
            font-weight: 400;

            color: #242428;

            .info-block--white & {
                color: #fff;
            }

        }
    }
</style>

