export const data = {
    methods: {
        list: {
            title: "Исследование Customer Journey позволяет:",
            arr: [
                "Выявить все существующие точки контакта потенциальных и действующих клиентов с компанией / брендом;",
                "Проанализировать возникающие барьеры на каждой точке контакта;",
                "Проанализировать наиболее узкие места продажи, выявить причины обращения клиентов в конкурентные компании; ",
                "Выявить неудовлетворенные потребности клиента на этапе выбора и после совершения покупки;",
                "Протестировать коммуникации заказчика, сопровождающие каждый этап продажи в сравнении с конкурентами;",
                "Выявить причины отсутствия повторного спроса (разовая покупка) у определенных сегментов потребителей;",
                "Выявить способы увеличения времени жизни клиента (LTV, Lifetime Value);",
                "Разработать пути повышения лояльности клиентов, вовлечение в совершение регулярных покупок."
            ]
        },

        page_text: {
            title:
                "Customer Journey ― методология маркетингового исследования, позволяющая детально изучить процесс выбора продукта или компании клиентом, начиная от появления идеи до принятия окончательного решения и совершения покупки.",
            subtitle:
                "Детальное понимание пути клиента позволяет верно выстраивать коммуникации на всех точках контакта с потенциальным клиентом и исправить существующие «узкие места» продаж, таким образом увеличив конверсию между этапами воронки продаж. Стоит отметить особую ценность данной методики для компаний, продукты которых можно охарактеризовать «долгой продажей» (например, услуги b2b, дорогостоящие товары, недвижимость и т.д.). Нередко на таких предприятиях существуют различные гипотезы относительно того, как формируется решение о совершении покупки, но отсутствует ясная определенность. Данный вид исследований позволяет взглянуть на процесс выбора глазами клиента и принимать управленческие решения на основе фактов."
        }
    }

};