import axios from 'axios';

export const data = {
    footer: {
        type: true,
        form: {
            submit: false,
            msg: "",
            items: {
                email: {
                    type: "email",
                    id: "FooterFormInputEmail",
                    autocomplete: "on",
                    name: "email",
                    msg: "",
                    old_value: "",
                    value: "",
                    valid: true,
                    placeholder: (location.href.split('/')[3] == 'en') ? "Enter your e-mail" : "Введите E-mail",
                    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                }
            }
        }
    }

};

export const methods = {
    Submit(form) {
        let field = this.footer.form.items.email;
        field.msg = "";
        this.footer.form.msg = "";
        field.valid = true;

        // valid

        if (field.value === field.old_value && field.value !== "") {
            field.value = "";
            return false;
        }

        field.valid = field.value !== "";
        if (!field.valid) {
            (location.href.split('/')[3] == 'en') ?
                field.msg = "Please enter your e-mail" :
                field.msg = "Пожалуйста, введите e-mail";
            //field.msg = "Пожалуйста, введите e-mail";
            field.old_value = field.value;
            return false;
        }
        field.valid = field.reg.test(field.value);
        if (!field.valid) {
            (location.href.split('/')[3] == 'en') ?
                field.msg = "Enter a valid e-mail" :
                field.msg = "Введите корректный e-mail";
            //field.msg = "Введите корректный e-mail";
            field.old_value = field.value;
            return false;
        }
        let body = 'email=' + encodeURIComponent(document.querySelector('#FooterFormInputEmail').value) +
            '&DATE=' + encodeURIComponent(document.querySelector('#footer_date').value) +
            '&PAGE=' + encodeURIComponent(document.querySelector('#footer_page').value);
       
        let _this = this;
    
        axios({
            method: 'post', 
            url: '/ajax/subscription.php',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: body
        }).then(response => {
            (location.href.split('/')[3] == 'en') ?
                _this.footer.form.msg = "Thank you, your e-mail has been sent successfully" :
                _this.footer.form.msg = "Спасибо, ваш e-mail успешно отправлен";
            //_this.footer.form.msg = "Спасибо, ваш e-mail успешно отправлен";
        }).then(error => {
            console.log(error);
        });

        return false;
    }
};

