var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question" },
    [
      _c(
        "div",
        {
          staticClass: "question__problem h5",
          on: {
            click: function($event) {
              _vm.question_now === _vm.n
                ? (_vm.question_now = null)
                : (_vm.question_now = _vm.n)
            }
          }
        },
        [
          _c("div", { staticClass: "question__head" }, [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "question__button",
              class: { "question__button--active": _vm.question_now === _vm.n },
              attrs: { type: "button" }
            },
            [
              _c("img", {
                staticClass: "question__minus",
                attrs: {
                  alt: "close-answer",
                  src: require("./../../../assets/image/minus.svg")
                }
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "question__plus",
                attrs: {
                  alt: "close-answer",
                  src: require("./../../../assets/image/minus.svg")
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("transition-height", [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.question_now === _vm.n,
              expression: "question_now === n"
            }
          ],
          staticClass: "question__answer p html",
          domProps: { innerHTML: _vm._s(_vm.description) }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }