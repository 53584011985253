<template>
    <modal
            name="pdf-modal"
            :height="getHeight" :width="getWidth"
            class="modal__carousel modal__pdf"
            @before-open="beforeOpen"
            @opened="opened"
            @closed="closed"
            :adaptive="true"
    >
        <div class="modal__pdf-container">
            <div class="modal__carousel--close" slot="top-right"  @click="$modal.hide('pdf-modal')">
                <img src="./../../../../assets/image/icon/plus.svg" alt="close-modal">
            </div>
            <img :src="item.src" :alt="item.alt" />
        </div>
    </modal>
</template>
<script>
    export default {
        name: "pdf-modal",
        props: [],
        data() {
            return {
                now: null,
                item: {
                    src: null,
                    alt: null
                }
            };
        },
        computed: {
            getHeight: function() {
                return parseInt(window.innerHeight - 100);
            },
            getWidth: function() {
                if(window.innerWidth <= 768) {
                    return window.innerWidth - 20;
                } else {
                    return this.getHeight / 1.41;
                }
            }
        },

        methods: {
            beforeOpen: function ($args) {
                this.item.src = $args.params.item.src;
                this.item.alt = $args.params.item.alt;
            },
            opened: function ($args) {
                if (window.innerWidth > 767) {
                    document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                }

                let heightScroll = window.pageYOffset;
                let elem = document.querySelector('#app');

                elem.style.position = "fixed";
                elem.style.width = "100%";
                elem.style.top = -heightScroll + 'px';
            },
            closed: function () {
                if (window.innerWidth > 767) {
                    document.getElementsByTagName('html')[0].style.overflowY = "auto";
                }

                let elem = document.querySelector('#app');
                let heightScroll = elem.style.top;

                elem.style.position = "static";
                elem.style.width = "100%";
                elem.style.top = '';

                window.scrollTo({
                    top: -parseInt(heightScroll),
                    behavior: "instant"
                });
            }
        }
    };

</script>
<style scoped lang="scss">
    .modal__pdf {
        margin: 0 auto;
    }
    .modal__pdf-container {
        position: relative;
    }
    .modal__carousel {
        background-color:rgba(0,0,0,0.6) !important;

        & * {
            user-select: none; -moz-user-select: none;
        }
        & img {
            width:100%;
        }

        &--close {
            right: -50px;
            top: 0;
            position: absolute;
            background-color: transparent;
            border: none;
            cursor: pointer;
            width:25px;
            & > img {
                transition: transform 0.3s;

                transform: rotate(45deg);
            }
            &:hover {
                & > img {
                    transform: rotate(calc(45deg * 3));
                }
            }
        }
        &--prev, &--next {
            width:70px;
            height:70px;
            border-radius: 100%;
            border:1px solid rgba(255,255,255, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.25s;
            position: absolute;
            top:calc((100vh / 2) - (70px / 2));
            cursor: pointer;
            &:hover {

                border-color:#fff;
            }
        }

        &--prev {
            transform: rotate(180deg);
            right:calc(100vw - 302px);
        }

        &--next {
            right:232px;
        }

    }

    .v--modal-overlay {

        background-color:rgba(0,0,0,0.7) !important;
    }

    @media (max-width: 767px) {
        .modal__pdf--close {
            right: 10px;
            top: -38px;
        }
    }

</style>

