import Vivus from "vivus";
export const data = {
    about: {
        svg_link: null,
        page: {
            text1:
                "«Русопрос» — маркетинговое агентство, специализирующееся на проведении качественных и количественных маркетинговых исследований, аналитике в области потребительских предпочтений.",
            text2:
                "Компания существует с 2011 года и специализируется на проведении качественных (фокус-группы, глубинные/экспертные интервью) и количественных (уличные/павильонные опросы, телефонные опросы, опросы методом онлайн-панели) видов маркетинговых исследований, аналитике в области потребительских предпочтений.",
            text3:
                "Компания входит в 5-ку лучших маркетинговых агентств России по версии рейтинга <a href='https://google.com/'>MottonPik</a>. Рейтинг составлен на основании проверенных (письменных) отзывов клиентов о качестве работы маркетинговых агентств. Чем больше рекомендаций от клиентов, тем выше позиция маркетингового агентства в списке.",
            topSlider: [
                {
                    img: require("./../../../assets/image/company-page-slide1.png"),
                    text:
                        "Более 8 лет успешно выполняем исследовательские проекты в интересах российских и зарубежных компаний."
                },
                {
                    img: require("./../../../assets/image/company-page-slide1.png"),
                    text:
                        "Более 8 лет успешно выполняем исследовательские проекты в интересах российских и зарубежных компаний."
                },
                {
                    img: require("./../../../assets/image/company-page-slide1.png"),
                    text:
                        "Более 8 лет успешно выполняем исследовательские проекты в интересах российских и зарубежных компаний."
                },
                {
                    img: require("./../../../assets/image/company-page-slide1.png"),
                    text:
                        "Более 8 лет успешно выполняем исследовательские проекты в интересах российских и зарубежных компаний."
                },
                {
                    img: require("./../../../assets/image/company-page-slide1.png"),
                    text:
                        "Более 8 лет успешно выполняем исследовательские проекты в интересах российских и зарубежных компаний."
                }
            ]
        },
    }

};

export const mounted = () => {
    if(document.querySelector('#about_svg')) {
        new Vivus("about_svg", { type: "sync" }, methods.myCallback);
    }

    if(document.querySelector('.container-about')) {
        if (document.querySelector(".tiles__item")) {
            if (window.pageYOffset > document.querySelector(".tiles__item").offsetTop) {
                document.querySelector(".company__wrap").classList.add('show');
            }
        }
    }
};

export const methods = {
    myCallback() {},
    /**
     * @return {boolean}
     */
    AnimScroll() {
        if (window.pageYOffset > document.querySelector(".tiles__item").offsetTop) {
            document.querySelector(".company__wrap").classList.add('show');
        }
        return window.pageYOffset > document.querySelector(".tiles__item").offsetTop + 100;
    }


};