<template>
    <div>
        <svg class="svg-container" id="svg1" preserveAspectRatio="none" viewBox="0 0 1440 768" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path transform="translate(0, 50)" :fill-opacity="svg_hide ? '1':'0'" style="transition: 0.5s"
                  d="M0.71783 746L0 265.303C56.3087 207.187 122.899 182.13 199.728 190.155C320.675 202.787 427.082 183.768 516.247 130.175C547.336 111.49 568.651 99.3179 591.686 87.5615C621.302 72.4472 649.232 60.6164 676.939 51.9261C743.796 30.9561 807.42 29.0201 870.381 50.0658C893.092 57.6572 913.96 68.3591 934.918 82.5639C951.224 93.616 965.191 104.826 988.269 124.679C989.651 125.867 997.242 132.41 999.412 134.274C1018.23 150.447 1030.15 160.118 1043.26 169.409C1059.82 181.149 1075.8 190.225 1092.63 197.075C1131.91 213.064 1175.95 216.764 1230.36 206.517C1270.1 199.033 1304.99 185.573 1337.21 166.368C1363.53 150.674 1386.93 132.069 1413.81 106.785C1421.09 99.9378 1459.1 62.629 1469.67 52.9973C1488.62 35.7131 1504.65 23.7109 1521.25 15.0987C1540.55 5.09288 1560.31 0 1582 0V481.001C1560.47 481.001 1540.87 486.053 1521.71 495.987C1505.2 504.553 1489.24 516.507 1470.34 533.737C1459.79 543.354 1421.79 580.655 1414.5 587.515C1387.56 612.844 1364.11 631.491 1337.72 647.227C1305.41 666.492 1270.4 679.994 1230.54 687.501C1175.95 697.782 1131.72 694.065 1092.25 678.002C1075.35 671.122 1059.31 662.008 1042.68 650.226C1029.54 640.912 1017.6 631.225 998.76 615.034C996.59 613.168 988.999 606.626 987.617 605.438C964.566 585.609 950.623 574.418 934.357 563.393C913.471 549.236 892.684 538.576 870.064 531.015C807.318 510.041 743.905 511.971 677.238 532.881C649.589 541.553 621.709 553.363 592.141 568.453C569.13 580.197 547.832 592.359 516.762 611.033C427.396 664.746 320.774 683.804 199.625 671.15C123.104 663.158 56.8157 688.101 0.71783 746Z"
                  fill="url(#paint0_linear)" />
            <defs>
                <linearGradient id="paint0_linear" x1="508.649" y1="0" x2="508.649" y2="350.737"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E5190A" stop-opacity="0.1">
                        <!--        <animate attributeName="stop-color" values="#FFF; #E5190A" dur="2s" repeatCount="1" ></animate>-->
                    </stop>
                    <stop offset="1" stop-color="#E5190A" stop-opacity="0.01">
                        <!--        <animate attributeName="stop-color" values="#FFF; #E5190A" dur="2s" repeatCount="1"></animate>-->
                    </stop>
                </linearGradient>
            </defs>



<!--            <g v-tooltip.top="getOptions(1)" transform="translate(0, 50)" class="svg__group"-->
<!--               v-bind:class="{'svg__group&#45;&#45;active': svg_hide}">-->
<!--                <text class="svg__text" x="550" y="91" fill="#242428" text-anchor="middle">Достоверность</text>-->
<!--                <circle class="hover" fill="#E5190A" cx="550" cy="111" r="8" fill-opacity="0.12" />-->
<!--                <circle class="hover opacity" fill="#E5190A" cx="550" cy="111" r="8" fill-opacity="0.12">-->
<!--                    <animate attributeType="SVG" attributeName="r" begin="0s" dur="1.5s" repeatCount="indefinite" from="0%"-->
<!--                             to="2%" />-->
<!--                    <animate attributeType="CSS" attributeName="opacity" begin="0s" dur="1.5s" repeatCount="indefinite"-->
<!--                             from="1" to="0" />-->
<!--                </circle>-->
<!--                <circle class="hover" fill="#E5190A" cx="550" cy="111" r="4" />-->

<!--            </g>-->
            <slot>

            </slot>


        </svg>
    </div>
</template>
<script>
    export default {
        name: "line-chart",
        components: {},
        data: function() {
            return {
                width: window.innerWidth,
                height: 746,
                svg_hide: true,
                wave_tooltip: {
                    options: {
                        content: "",
                        hideOnTargetClick: true,
                        targetClasses: "tooltip",
                        trigger: "hover"
                    },

                    data: [
                        {
                            title: "Экспертность",
                            text:
                                "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                        },
                        {
                            title: "Достоверность",
                            text:
                                "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                        },
                        {
                            title: "Охват",
                            text:
                                "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                        },
                        {
                            title: "Онлайн-панель",
                            text:
                                "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                        },
                        {
                            title: "Рекрутинг",
                            text:
                                "Нашим данным доверяют ФАС России, УФАС Москвы, Арбитражные суды"
                        }
                    ]
                }
            };
        },
        methods: {
            // getOptions: function(iterator) {
            //     let a = this.wave_tooltip.options;
            //     let b = `
            //         <div class=custom_tooltip>
            //             <h4 class=h4>${
            //         this.wave_tooltip.data[iterator].title
            //         }</h4>
            //             <p class=p>${this.wave_tooltip.data[iterator].text}</p>
            //         </div>
            //         `;
            //
            //     return (a.content = b);
            // }
        }
    };

</script>
<style scoped lang="scss">
    .svg-container {
        position: relative;
        //top: 50px;
    }
    #svg1 {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 211%; // магическое число
    }
    .svg__text {
        font-family: 'PT Root UI', sans-serif;
        font-size: 18px;
        line-height: 36px;
        font-weight: 700;
    }
    .svg__group {
        opacity: 0;
        //transform: translate(0px,50px);
        transition: 1s;

        &--active {
            opacity:1;
            //transform: translate(0px,0px);
        }
    }

    .svg-container .opacity {
        opacity: 0.0;

        //-webkit-animation: circle-anim 1000ms ease-out;
        //-webkit-animation-iteration-count: infinite;

        -webkit-transition: background-color 300ms linear;
        -moz-transition: background-color 300ms linear;
        -o-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }

    @keyframes circle-anim {
        0% {opacity: 0.1;}
        40% {opacity: 1.0;}
        60% {opacity: 1.0;}
        100% {opacity: 0.1;}
    }
    svg * {
        //transform: translate(0, 50px)
    }


</style>

