export const data = {
    optionSwiperMobile: {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        spaceBetween: 80,
        centeredSlides: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiperMobile .swiper-button-next',
            prevEl: '.swiperMobile .swiper-button-prev',
        },
        // on: {
        //     slideChange() {
        //         methods.activeImgMobile();
        //     }
        // }
    },
    work: {
        page: {
            scrollto: "#vacancy",
        },
        work_culture: {
            title:
                "Мы помогаем компаниям с их продуктами или услугами, что делает <br/>окружающий нас мир лучше.",
            color: "white",
            background_show: false
        },
        work_culture_item: [
            {
                active: false,
                title: "Честность",
                text:
                    "Мы держим слово. <br>" +
                    "Вне зависимости от формы договоренностей <br>" +
                    "(заключенный договор <br>" +
                    "или рукопожатие — не важно). <br>" +
                    "Каждый сотрудник честен перед коллегами, клиентами и подрядчиками.",
                background: "./../assets/image/background-image/work-culture/honesty.jpg"
            },
            {
                active: false,
                title: "Ответственность",
                text:
                    "Каждый сотрудник стремится развивать самостоятельность и принимать решения <br>" +
                    "в рамках своей компетенции.  При общении <br>" +
                    "с руководителем сотрудник предлагает <br>" +
                    "2-3 варианта решения проблемы, <br>" +
                    "а лишь потом спрашивает, как поступить. <br>",
                background: "./../assets/image/background-image/work-culture/responsibility.jpg"
            },
            {
                active: false,
                title: "Спокойствие",
                text:
                    "В нашем агентстве кричат только от радости<br>" +
                    "или когда зовут кого-то (например, коллегу, который в наушниках слушает музыку).<br>" +
                    "В спорах мы не кричим друг <br>" +
                    "на друга, используем аргументы, <br>" +
                    "а не силу голоса. <br>",
                background: "./../assets/image/background-image/work-culture/calm.jpg"
            },
            {
                active: false,
                title: "Самоотдача",
                text:
                    "Мы всегда стремимся<br>" +
                    "сделать работу с нами<br>" +
                    "предельно удобной для клиента<br>" +
                    "и ставим это выше<br>" +
                    "собственного комфорта. <br>",
                background: "./../assets/image/background-image/work-culture/dedication.jpg"
            },
            {
                active: false,
                title: "Чувство юмора",
                text:
                    "Мы часто шутим и смеемся. <br>" +
                    "При этом наши шутки направлены,<br>" +
                    "чтобы развлечь, а не обидеть кого-то из коллег. <br>" +
                    "А если что-то пошло не так, <br>" +
                    "мы обязательно попросим прощения.",
                background: "./../assets/image/background-image/work-culture/humor.jpg"
            },
            {
                active: false,
                title: "Взаимопомощь",
                text:
                    "Мы всегда приходим на помощь друг другу<br>" +
                    "и не стесняемся просить о помощи.<br>" +
                    "Нам не стыдно<br>" +
                    "не знать чего-либо, зато стыдно<br>" +
                    "не желать разобраться.<br>",
                background: "./../assets/image/background-image/work-culture/help.jpg"
            }
        ],
        work_office: [
            {
                icon: require("./../../../assets/image/icon/sedan.svg"),
                title: "none",
                subtitle: "Легко добраться от метро и МЦК, 10 минут пешком"
            },
            {
                icon: require("./../../../assets/image/icon/crossed-knife-and-fork.svg"),
                title: "none",
                subtitle:
                    "Небольшой холодильник в каждом помещении и микроволновая печь\n" +
                    "(можно взять еду из дома)"
            },
            {
                icon: require("./../../../assets/image/icon/coffee-cup.svg"),
                title: "none",
                subtitle:
                    "Кулер в каждом помещении, также всегда доступны разные виды чая и кофе. Бесплатный буфет с разнообразными видами печенья"
            },
            {
                icon: require("./../../../assets/image/icon/dish.svg"),
                title: "none",
                subtitle:
                    "Корпоративный ресторан рядом с БЦ \n" + "с вкусными бизнес-ланчами"
            },
            {
                icon: require("./../../../assets/image/icon/laptop-work-page.svg"),
                title: "none",
                subtitle: "Современная компьютерная техника, удобная мебель"
            },
            {
                icon: require("./../../../assets/image/icon/gift.svg"),
                title: "none",
                subtitle: "А также, мы дарим подарки \n" + "на дни рождения"
            }
        ],
        vacancy: [
            {
                problem: "Ассистент аналитика 1-ой категории",
                answer:
                    "<h5>Требования</h5><ul><li>Законченное высшее образование, предпочтительно – социология или маркетинг;</li><li>Коммуникабельность, хорошая дикция, готовность и умение много общаться с людьми;</li><li>Опыт работы с офисными программами (предпочтительно на рабочем месте);</li><li>Аккуратность и внимательность к деталям;</li><li>Готовность к активным рабочим темпам, желание развиваться в области маркетинговых исследований.</li></ul>"
            },
            {
                problem: "Менеджер проектов (количественные исследования)",
                answer:
                    "<h5>Требования</h5><ul><li>Законченное высшее образование, предпочтительно – социология или маркетинг;</li><li>Коммуникабельность, хорошая дикция, готовность и умение много общаться с людьми;</li><li>Опыт работы с офисными программами (предпочтительно на рабочем месте);</li><li>Аккуратность и внимательность к деталям;</li><li>Готовность к активным рабочим темпам, желание развиваться в области маркетинговых исследований.</li></ul>"
            },
            {
                problem: "Руководитель исследовательских проектов",
                answer:
                    "<h5>Требования</h5><ul><li>Законченное высшее образование, предпочтительно – социология или маркетинг;</li><li>Коммуникабельность, хорошая дикция, готовность и умение много общаться с людьми;</li><li>Опыт работы с офисными программами (предпочтительно на рабочем месте);</li><li>Аккуратность и внимательность к деталям;</li><li>Готовность к активным рабочим темпам, желание развиваться в области маркетинговых исследований.</li></ul>"
            },
            {
                problem: "Ассистент аналитика 1-ой категории",
                answer:
                    "<h5>Требования</h5><ul><li>Законченное высшее образование, предпочтительно – социология или маркетинг;</li><li>Коммуникабельность, хорошая дикция, готовность и умение много общаться с людьми;</li><li>Опыт работы с офисными программами (предпочтительно на рабочем месте);</li><li>Аккуратность и внимательность к деталям;</li><li>Готовность к активным рабочим темпам, желание развиваться в области маркетинговых исследований.</li></ul>"
            }
        ],
        clients: {
            title:
                "Мы работаем с лучшими компаниями и гордимся своей работой. Присоединяйся к большому делу уже сейчас.",
            color: "#242428"
        },
        feedback_mini: {
            img: require("./../../../assets/image/work-page-face.png"),
            title: "Контакты HR",
            text:
                "Направьте свое резюме и пожелания относительно вакансии нам на почту",
            email: "job@rus-opros.com"
        },
    },
    activeItemCultureArr: -1,
    CultureArr: [
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок1',
            text: 'Описание',
        },
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок2',
            text: 'Описание',
        },
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок3',
            text: 'Описание',
        },
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок4',
            text: 'Описание',
        },
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок5',
            text: 'Описание',
        },
        {
            image: './images/company-page-slide1.png',
            title: 'Заголовок6',
            text: 'Описание',
        },

    ],
};

export const methods = {
    workCultureEnter(index) {
        this.activeItemCultureArr = index;
    },
    workCultureLeave() {
        this.activeItemCultureArr = -1;
    },

    activeImgMobile() {
        setTimeout(() => {
            document.querySelectorAll('.work-culture__container-mobile .swiper-slide').forEach((item, index) => {
                if (item.classList.contains('swiper-slide-active')) {
                    //CultureArr.forEach((item2) => {
                        //data.activeItemCultureArr = -1;
                    //});

                    this.activeItemCultureArr = index;
                    //console.log(this.activeItemCultureArr);
                }
            });
        }, 100);
    }
};
