export const data = {
    data: [
        {
            problem: "Как работает онлайн-панель?",
            answer:
                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
        },
        {
            problem: "Как осуществляется отбор нужных респондентов?",
            answer:
                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию.  что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию.  что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию.  что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию.  что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию.  что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
        },
        {
            problem: "Как часто респонденты могут принимать участие в опросах?",
            answer:
                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
        }
    ],
    question: {
        question_now: null
    }
};

export const mounted = () => {
    let problem = document.querySelectorAll('.question__problem');
    let question = document.querySelectorAll('.question');

    for (let i = 0; i < question.length; i++) {
        question[i].children[0].children[1].classList.remove('question__button--active');
        question[i].children[1].classList.remove('_open');
        question[i].children[1].style.height = '';
    }

    for (let i = 0; i < problem.length; i++) {
        problem[i].addEventListener('click', (e) => {
            for (let j = 0; j < question.length; j++) {
                if (e.target !== question[j].children[0].children[0]) {

                    question[j].children[0].children[1].classList.remove('question__button--active');
                    question[j].children[1].classList.remove('_open');

                    question[j].children[1].style.height = '';
                }

                else if (!question[j].children[0].children[1].classList.contains('question__button--active')) {
                    question[j].children[0].children[1].classList.add('question__button--active');
                    question[j].children[1].classList.add('_open');

                    question[j].children[1].style.height = question[j].children[1].children[0].offsetHeight + 'px';
                }

                else {
                    question[j].children[0].children[1].classList.remove('question__button--active');
                    question[j].children[1].classList.remove('_open');

                    question[j].children[1].style.height = '';
                }
            }
        }, false)
    }
};