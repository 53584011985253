<template>
	<div class="screen" v-bind:class="{'screen__main': url === '/' || url === '/en/'}"
		 :style="{'clip-path': 'polygon(0 0, 100% 0, 100% ' + getClipPath + ', 0% 100%)','-webkit-clip-path': 'polygon(0 0, 100% 0, 100% ' + getClipPath + ', 0% 100%)'}">

		<div class="screen__container container">
			<slot name="information" v-bind:current="CurrentTime" v-bind:iteration="Iteration">

			</slot>
		</div>

		<slot name="backgrounds">

		</slot>


		<div class="screen__cols" v-if="url === '/' || url === '/en/'">
			<div v-for="(item, n) in getCols"
				 :data-n="n" 
				 class="screen__col" 
				 :class="{'screen__col--hover': n == col_now}"
				 :key="`item_${n}`"				
				 :style="{'opacity': cols_arr[n].opacity, 'transform': `translateY(${cols_arr[n].data}px`}">
				<span v-show="cols_arr[n].percent">{{getPercent(n)}}</span>
			</div>
		</div>

		<div class="screen__animation"></div>
	</div>

</template>
<style scoped lang="scss">
	@keyframes bg-anim {
		from {
		  transform: translateX(0);
		}

		to {
			display: none;
		  	transform: translateX(200vw);
		}
	}
	@keyframes bg-zoom-anim {
		from {
			transform: scale(1.0);
		}
		to {
			transform: scale(1.4);
		}
	}
	@keyframes bg-zoom-anim-small {
		from {
			transform: scale(1.0);
		}
		to {
			transform: scale(1.05);
		}
	}
	@keyframes content-anim {
		from {
			top:-100px;
			opacity:0;
		}
		to {
			top:0;
			opacity: 1;
		}
	}


	@keyframes button-anim {
		from {
			top: -70px;
			opacity:0;
		}
		to {
			top:-15px;
			opacity:1;
		}
	}

	@keyframes cols-anim {
		from {
			transition: 1s;
		}
		to {
			transition: 1s;
		}
	}

	.screen {
		background-position: center center;
		width:100%;
		height:640px;
		display: flex;
		align-items: flex-end;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		overflow: hidden;


		&__main {
			height:744px;

			& .screen__container {
				padding: 99px 40px 142px;
			}
		}

		&__background {
			transition: all 0.3s;
			position: absolute;
			background-repeat: no-repeat;
			background-size:100% 100%;
			left:0;
			top:0;
			width: 100%;
			height: 100%;

			&--small {
				animation: bg-zoom-anim-small 3s;
				animation-timing-function: ease-in-out;
				animation-fill-mode: forwards;
			}
			&--big {
				animation: bg-zoom-anim 13s;
				animation-timing-function: linear;
				animation-fill-mode: forwards;
				/*animation-iteration-count: infinite;*/
			}
			&__gradient {
				animation-delay: 1s;
			}
			&__gradient:after {
				content:'';
				position:absolute;
				left:0; top:0;
				width:100%; height:100%;
				display:inline-block;
				background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.09) 100%)
			}

			& > img {
				width:100%;
				height:100%;
				object-fit: cover;
				object-position: bottom;
			}
		}

		&__cols {
			contain: layout;
			position: absolute;
			left:0;
			bottom:0;
			height:800px;
			overflow: hidden;
			display: flex;
			align-items: flex-end;

			z-index: 1;

		}
		&__col {
			contain: layout;
			width:59px;
			will-change: transform;
			height: 700px;
			backface-visibility: hidden;
			transition: transform .3s;
			opacity: 0.1;
			background-image: url("./../../../assets/image/dot_pattern.png");
			//background-color:#000;
			background-position: bottom left;
			//background-repeat: space;
			display: flex;
			justify-content: center;
			
			& > span {
				position: relative;
				top:-30px;
				color:#fff;
				font-family: 'PT Root UI', sans-serif;
				font-size: 18px;
				line-height: 23px;
				font-weight: 300;
			}

			&--hover {
				// transition-delay: 0s;
				// will-change: transform;
				transition: transform 0.1s;
			}
		}

		&__container {
			position: relative;
			animation: content-anim 1s linear;
			animation-fill-mode: forwards;
			animation-delay: 0.5s;
			margin-bottom: 2px;
			padding: 59px 40px 123px;
			z-index: 2;
			opacity: 0;
		}

		&__title {
			color: #fff;
			z-index: 5;
			position: relative;
			left:-4px;
		}

		&__subtitle {
			font-weight: 300;
			margin-top: 40px;
			/*font-size: 24px;*/
			line-height: 36px;
			color: #fff;
			z-index: 5;
			position: relative;
		}

		&__text {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			line-height: 25px;
			font-weight: 500;
			font-family: 'PT Root UI', sans-serif;
			color: #fff;
			background-color: transparent;
			border: 1px solid #FFFFFF;
			box-sizing: border-box;
			border-radius: 35px;
			width:auto;
			padding:0 50px;
			height: 70px;
			cursor: pointer;
			transition: background-color 0.25s, border 0.25s, color 0.25s;
			z-index: 5;
		}

		&__tooltip {
			position: absolute;
			right: -80px;
			top:-15px;
			opacity:1;
			padding: 7px 15px;
			background-color: #E5190A;
			display: block;
			color: #fff;
			font-size: 16px;
			font-weight: 400;
			font-family: 'PT Root UI', sans-serif;
			border-radius: 22.5px;
			pointer-events: none;


			&--animation {
				animation: button-anim .5s linear;
				animation-fill-mode: forwards;
				animation-delay: 1s;
				animation-iteration-count: 1;
				opacity:0;
			}
		}

		&__button {
			margin-top: calc(40px + 15px);
			position: relative;
			font-family: 'PT Root UI', sans-serif;
			padding:0;
			background-color: transparent;
			border: none;

			&--main {
				border-color:rgba(151, 151, 151, 0.4);
				width:auto;
				padding:0 50px;

				& > span:first-child {

					font-weight: 500;
				}

			}

			&:hover {
				.screen__text {
					will-change: background-color, border, color;
					background-color:#fff;
					border:1px solid transparent;
					color:#242428;
				}
			}

		}

		&__animation {
			width:100%;
			height:750px;
			background-color:#fff;
			position: absolute;
			left:0;
			top:0;
			z-index: 5;
			animation: bg-anim 2s linear;
			animation-fill-mode: forwards;
		}

	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}


	@media (max-width: 1200px) {
		.screen__main  .screen__container, .screen__container {
			padding: 30px 40px 205px;
			margin-bottom: 0;
		}
		.screen__button {
			padding: 0;
		}
	}

	@media (max-width: 767px) {
		.screen__title {
			left: 0;
			font-size:26px; // Заказчик так захотел
		}
		.screen__main  .screen__container, .screen__container {
			padding: 140px 25px 80px;
			height: 100%;
		}
		.screen__text {
			font-size: 16px;
			line-height: 18px;
			height: 50px;
			padding: 0 29px;
		}
		.screen__button--main {
			padding: 0 20px;
		}
		.screen__button {
			margin-top: 40px;
		}
		.screen__subtitle {
			margin-top: 24px;
			line-height: 20px;
		}
		.screen__tooltip {
			right: -40px;
			font-size: 13px;
			line-height: 16px;
		}
		.screen__cols {
			display: none !important;
		}
		.screen {
			height: auto;
			clip-path: polygon(0 0, 100% 0, 100% (100% - 4.3%), 0% 100%) !important;
			-webkit-clip-path: polygon(0 0, 100% 0, 100% (100% - 4.3%), 0% 100%) !important;
		}
		.recruting {
			.screen__tooltip {
				right: -21px;
			}
		}
	}

</style>
<script>
	export default {
		props: {
			mouseevent: {
				default: () => {
					return {
						clientX: 0,
						clientY: 0
					};
				}
			},
			background: {
				default: () => {
					return [
						require("./../../../assets/image/background-image/done-page-bg-image.png")
					];
				}
			},
			now: {
				default: () => {
					return 0;
				}
			},
			title: {
				default: () => {
					return "Customer Journey (Путь клиента)";
				}
			},
			subtitle: {
				default: () => {
					return "Качественный рекрутинг респондентов для маркетинговых<br/>агентств и UX-лабораторий";
				}
			},
			button_text: {
				default: () => {
					return "Получить предложение";
				}
			},
			tooltip: {
				// if you need hidden this tooltip write 'none';
				default: () => {
					return "завтра, в 14:30";
				}
			},
			main_page: {
				default: () => {
					return false;
				}
			},
			background__bool: {
				default: () => {
					return false;
				}
			},
			scrollto: {
				default: () => {
					return "none";
				}
			},
			words_current_time: {
				default: () => {
					return ["понедельник, до 19:00", "завтра, до 19:00", "завтра, в 11:00", "завтра, в ", ""];
				}
			}
		},


		name: "component_first_page_screen",

		data() {
			return {
				CurrentTime: '00:00',
				Iteration: 0,
				cols_arr: [],
				col_now: null,
				timeout: true,
				time: 500,
				innerWidth: window.innerWidth,
				min: 0,
				max: 0,
				globalSkipCounter: 0,
				globalSkipRate: 5,
				evt: {
					clientX: '',
					clientY: ''
				},
				url: window.location.pathname,
				isCooldown: false,
				mounted: false
			};
		},
		watch: {
			mouseevent: function(e) {
				if (!this.mounted) return;
				this.mousemoveCols(e);
			}
		},
		computed: {
			// ...mapGetters(['isSupport']),

			getCols: function() {
				let n = 59;
				let result = this.innerWidth / n + 1;
				for (let i = 0; i < result; i++) {
					this.cols_arr.push({
						data: 700
					});
				}
				return parseInt(result);
			},
			getClipPath: function() {
				let a = (Math.atan(90 / 1440) * 180) / Math.PI;
				let b = (a * 180) / 100;

				return 100 - b * 2 + "%";
			}
		},
		methods: {
			mousemove($event) {
				// this.debounce($event, 50);
				if (this.isCooldown) return;
				this.mousemoveCols($event);
				this.isCooldown = true;
				setTimeout(() => this.isCooldown = false, 50);
			},
			// debounce($event,  ms) {
			// 	if (this.isCooldown) return;
			// 	this.mousemoveCols($event);
			// 	this.isCooldown = true;
			// 	setTimeout(() => this.isCooldown = false, ms);
			// },
			randomize: function(min, max, multiple = 5) {
				return (
					Math.floor(Math.random() * ((max - min) / multiple)) * multiple + min
				);
			},

			getCurrentTime() {
				setTimeout(() => {
					let now = this.getMoscowTime('msc');
					let hours = parseInt(now.split(':')[0]) + 2;
					let minutes = now.split(':')[1];

					if(this.getWeekend()) {
						// this.CurrentTime = 'понедельник, до 19:00';
						this.CurrentTime = this.words_current_time[0];
						return;
					}

					if(hours - 2 >= 17) {
						// this.CurrentTime = 'завтра, до 19:00';
						this.CurrentTime = this.words_current_time[1];
						return;
					}
					if(0 === hours - 2 && hours - 2 < 9) {
						// this.CurrentTime = 'завтра, в 11:00';

						this.CurrentTime = this.words_current_time[2];
						return;
					}

					// this.CurrentTime = 'завтра, в ' + hours + ':' + minutes;

					this.CurrentTime = this.words_current_time[3] + hours + ':' + minutes + this.words_current_time[4];

					this.getCurrentTime();
				}, 1000)
			},

			getMoscowTime(name) {
				let d = new Date(), utc = 3;
				d.setHours( d.getHours() + utc, d.getMinutes() + d.getTimezoneOffset()  );
				if(name === 'msc') {
					return d.toTimeString().substring(0,8);
				} else {
					return d;
				}

			},

			getWeekend() {
				let day = this.getMoscowTime().getDay();
				return day === 0 || day === 5 || day === 6;

			},

			// ColsHover: function(n) {
			// 	this.cols_arr[n].data = 0;
			// },

			mousemoveCols(evt) {
				// Ширина одного дива 59 высота от 250 до 650
				if (this.url === '/' || this.url === '/en/') {
					// console.log(`client X  ${evt.clientX}`)
					let a = parseInt(evt.clientX / 59);

					if (a !== this.col_now) {
						this.cols_arr.map((item, n) => {
							if (n !== a) {
								let min = item.data - 20;
								let max = item.data + 20;
								if (item.data <= 150) {
									min = item.data;
								}
								if (item.data >= 650) {
									max = item.data;
								}

								if (a + 7 >= n && n >= a - 7) {
									item.data = this.randomize(min, max);
								}
							}
						});
						this.col_now = a;
					} 

					if(evt.clientY - 50 <= 94) {
						this.cols_arr[a].data = 94;
					} else {
						// if (evt.clientY > this.cols_arr[a].data ){
						// 	this.cols_arr[a].data+= 5;
						// } else {
						// 	this.cols_arr[a].data-= 5;
						// }
						this.cols_arr[a].data = evt.clientY - 50;
					}					

					// this.loop();
				}
			},

			getPercent(n) {
				let percent = parseInt(((700 - this.cols_arr[n].data) * 100) / 650);
				if (
					n === this.max - 6 ||
					n === this.max + 2 ||
					n === this.min + 11 ||
					n === this.min + 8
				) {
					return (this.cols_arr[n].percent = percent + "%");
				}
			},

			// loop: function() {
			// 	window.requestAnimationFrame(() => this.mousemove(this.evt));
			// }
		},
		created() {
			window.addEventListener("resize", () => {
				this.innerWidth = window.innerWidth;
			});
		},
		mounted() {
			setTimeout(() => {
				this.cols_arr.forEach((item, n) => {
					this.cols_arr[n].data = this.randomize(250, 650);
					let start = document.querySelector(".screen__container").offsetLeft;
					let stop =
						document.querySelector(".screen__container").offsetLeft +
						document.querySelector(".screen__container").clientWidth;
					this.min = parseInt(start / 59); // первая
					this.max = parseInt(stop / 59); // последняя
					if (n <= this.min || n >= this.max) {
						this.cols_arr[n].opacity = "0.1";
					} else {
						this.cols_arr[n].opacity = "0.78";
					}
					this.cols_arr[this.max - 1].opacity = "0.2";
					this.cols_arr[this.max - 2].opacity = "0.3";
					this.cols_arr[this.max - 3].opacity = "0.4";
					this.cols_arr[this.max - 4].opacity = "0.78";
					this.cols_arr[this.max - 5].opacity = "0.6";

					this.cols_arr[this.min + 1].opacity = "0.2";
					this.cols_arr[this.min + 2].opacity = "0.2";
					this.cols_arr[this.min + 3].opacity = "0.2";
					this.cols_arr[this.min + 4].opacity = "0.2";
					this.cols_arr[this.min + 5].opacity = "0.2";

					this.cols_arr[this.min + 6].opacity = "0.3";
					this.cols_arr[this.min + 7].opacity = "0.3";
					this.cols_arr[this.min + 8].opacity = "0.3";
					this.cols_arr[this.min + 9].opacity = "0.3";
					this.cols_arr[this.min + 10].opacity = "0.3";
					this.cols_arr[this.min + 11].opacity = "0.3";

					this.cols_arr[this.min + 12].opacity = "0.4";
					this.cols_arr[this.min + 13].opacity = "0.5";
					this.cols_arr[this.min + 14].opacity = "0.6";

					this.getPercent(n);
				});

				// if(window.isSupport) {
				// 	this.loop();
				// }

				this.mounted = true;
			}, 3000);

			setTimeout(() => {
				this.Iteration = 1;
			}, 3500);

			this.getCurrentTime();
		}
	};

</script>
