var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "feedback",
      class: {
        "feedback--type": _vm.type,
        "feedback--white": _vm.color === "white"
      }
    },
    [
      _c(
        "swiper",
        {
          ref: "mySwiper",
          staticClass: "feedback__check",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._t("items"),
          _vm._v(" "),
          _c("div", {
            staticClass: "feedback__nav",
            attrs: { slot: "pagination" },
            slot: "pagination"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "swiper-button-prev",
              attrs: { slot: "button-prev" },
              slot: "button-prev"
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "25",
                    height: "13",
                    viewBox: "0 0 25 13",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z",
                      fill: _vm.color !== "white" ? "#E5190A" : "white"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "swiper-button-next",
              attrs: { slot: "button-next" },
              slot: "button-next"
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "25",
                    height: "13",
                    viewBox: "0 0 25 13",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z",
                      fill: _vm.color !== "white" ? "#E5190A" : "white"
                    }
                  })
                ]
              )
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      !_vm.type
        ? _c(
            "div",
            { staticClass: "feedback__cols" },
            _vm._l(_vm.countCols, function(n) {
              return _c(
                "div",
                { staticClass: "feedback__cols-row" },
                _vm._l(2, function(m) {
                  return _c("div", {
                    key: m,
                    staticClass: "feedback__col",
                    style: { opacity: _vm.now_hover === n ? "0" : "1" }
                  })
                }),
                0
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }