var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "info-block",
      class: { "info-block--white": _vm.color === "white" }
    },
    [
      _c("div", { staticClass: "info-block__box" }, [
        _c("img", { attrs: { alt: _vm.title, src: _vm.icon } })
      ]),
      _vm._v(" "),
      _vm.title !== "none"
        ? _c("span", { staticClass: "info-block__title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        staticClass: "info-block__subtitle",
        domProps: { innerHTML: _vm._s(_vm.subtitle) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }