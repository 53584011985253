export const data = {
    main_client: {
        modal_arr: [],
        status: true,
        hover_id: null,

        cols_arr: [
            {
                text: ""
            },
            {
                text: ""
            },
            {
                text: ""
            },
            {
                text: ""
            },
            {
                text: ""
            },
            {
                text: ""
            },
            {
                text: ""
            }
        ],

        clients_arr: [],

        height: ["216", "246", "276", "296", "276", "246", "216"],
        opacity: ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"],

        now: []
    }
};

export const methods = {
    setToArr(n, $args) {
        data.main_client.modal_arr[n] = $args;


        //console.log('31');

    },
    setArr($args) {
      // data.main_client.modal_arr.push($args);
    },
    show_modal(n) {
        this.$modal.show(
            "foo",
            {
                data: data.main_client.modal_arr,
                n: n
            },
            {
                width: "35%",
                height: "auto"
            }
        );
    },
    HoverEnter(id) {
        data.main_client.hover_id = id;
        methods.setOpacity(id - 1);

    },
    HoverLeave() {
        data.main_client.hover_id = null;
        methods.emptyOpacity();
    },
    getHeightCols(n) {
        return data.main_client.height[n];
    },
    getOpacity(n) {
        return data.main_client.opacity[n];
    },
    setOpacity(n) {
        let arr = ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"];


        switch(n) {
            case 0:
                arr = ["1", "0.65", "0.45", "0.35", "0.25", "0.15", "0.1"];
                // arr = ["1", "0.8", "0.6", "0.35", "0.35", "0.35", "0.35"];
                break;
            case 1:
                arr = ["0.65", "1", "0.65", "0.45", "0.35", "0.25", "0.15"];
                // arr = ["0.8", "1", "0.8", "0.6", "0.35", "0.35", "0.35"];
                break;
            case 2:
                arr = ["0.45", "0.65", "1", "0.65", "0.45", "0.35", "0.25"];
                // arr = ["0.6", "0.8", "1", "0.8", "0.6", "0.35", "0.35"];
                break;
            case 3:
                arr = ["0.35", "0.45", "0.65", "1", "0.65", "0.45", "0.35"];
                // arr = ["0.35", "0.6", "0.8", "1", "0.8", "0.6", "0.35"];
                break;
            case 4:
                arr = ["0.25", "0.35", "0.45", "0.65", "1", "0.65", "0.45"];
                // arr = ["0.35", "0.35", "0.6", "0.8", "1", "0.8", "0.6"];
                break;
            case 5:
                arr = ["0.15", "0.25", "0.35", "0.45", "0.65", "1", "0.65"];
                // arr = ["0.35", "0.35", "0.35", "0.6", "0.8", "1", "0.8"];
                break;
            case 6:
                arr = ["0.1", "0.15", "0.25", "0.35", "0.45", "0.65", "1"];
                // arr = ["0.35", "0.35", "0.35", "0.35", "0.6", "0.8", "1"];
                break;
        }
        data.main_client.opacity = arr;
    },
    emptyOpacity() {
        let arr = ["0.35", "0.35", "0.35", "0.35", "0.35", "0.35", "0.35"];

        data.main_client.opacity = arr;
    },
    clickMainClientHandler(n) {
        if (data.main_client.now[n].max > data.main_client.now[n].now + 2) {
            data.main_client.now[n].now = data.main_client.now[n].now + 2;
        } else {
            data.main_client.now[n].now = 0;
        }
    },
    getNow(array) {
        data.main_client.now = array;
    },


    setMainClientColumn(i, $json) {
        data.main_client.cols_arr[i].text = $json.columnName;
        data.main_client.clients_arr[i] = [];

    },

    setMainClientItems(i, j , $json) {
        data.main_client.clients_arr[i][j] = $json;
    }

};
