<template>
  <!--<div>-->
    <vue-custom-scrollbar class="scroll-area" :settings="settings">
      <slot></slot>
    </vue-custom-scrollbar>
  <!--</div>-->
</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar'
    export default {
        components: {
            vueCustomScrollbar
        },
        data() {
            return {
                settings: {
                    //maxScrollbarLength: 60
                }
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
@media (max-width: 1200px) {
  .scroll-area {
    height: calc(100vh - 134px);
  }
  .ps__rail-y {
    margin: 50px 0
  }
  .ps--active-y>.ps__rail-y {
    background-color: transparent !important;
  }
}
@media (max-width: 767px) {
  .scroll-area {
    height: 100vh;
  }
  .ps__rail-y {
    margin: 100px 0 50px;
  }
  .ps__thumb-y {
    background-color: transparent !important;
  }
}

</style>