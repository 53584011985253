var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "modal__carousel modal__pdf",
      attrs: {
        name: "pdf-modal",
        height: _vm.getHeight,
        width: _vm.getWidth,
        adaptive: true
      },
      on: {
        "before-open": _vm.beforeOpen,
        opened: _vm.opened,
        closed: _vm.closed
      }
    },
    [
      _c("div", { staticClass: "modal__pdf-container" }, [
        _c(
          "div",
          {
            staticClass: "modal__carousel--close",
            attrs: { slot: "top-right" },
            on: {
              click: function($event) {
                return _vm.$modal.hide("pdf-modal")
              }
            },
            slot: "top-right"
          },
          [
            _c("img", {
              attrs: {
                src: require("./../../../../assets/image/icon/plus.svg"),
                alt: "close-modal"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("img", { attrs: { src: _vm.item.src, alt: _vm.item.alt } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }