<template>

  <transition-height>
    <div class="t-read-more">
      <transition-group
              class="t-read-more__wrapper"
              mode="out-in"
              tag="div"
              name="read-more-fade"
              ref="textWrapper"
      >
        <p v-show="!showText" class="t-read-more__text" key="1">
          <slot name="text"></slot>
        </p>
        <p v-show="showText" class="t-read-more__text t-read-more__text_open" key="2">
          <slot name="text"></slot>
        </p>
      </transition-group>
      <button
              @click="toggleMore()"
              type="button"
              class="t-read-more__text-button"
              :class="{'t-read-more__text-button-active': time_active}"
      >{{ button_text }}</button>
      <div class="t-read-more__hidden" ref="text"><div><slot name="text"></slot></div></div>
    </div>
  </transition-height>

  <!--<transition-height>-->
    <!--<div class="t-read-more" :class="{'t-read-more-open': isOpen}">-->
      <!--<transition-group class="t-read-more__wrapper" :class="{'t-read-more__wrapper-open': isOpen}" mode="out-in" tag="div" name="read-more-fade">-->
        <!--<p v-show="!showText" class="t-read-more__text" key="1">-->
          <!--<slot name="text"></slot>-->
        <!--</p>-->
        <!--<p v-show="showText" class="t-read-more__text t-read-more__text_open" key="2">-->
          <!--<slot name="text"></slot>-->
        <!--</p>-->
      <!--</transition-group>-->
      <!--<button v-on:click="show_more()" type="button" class="t-read-more__text-button" :class="{'t-read-more__text-button-active': time_active}">{{ button_text }}</button>-->
    <!--</div>-->
  <!--</transition-height>-->

</template>

<style lang="scss" scoped>
  .t-read-more {
    position: relative;
    background-color:#fff;

    &__wrapper {
      transition: height .7s;
      height: 80px;
      overflow: hidden;
      margin-bottom: 20px;
      &-open {
        height: auto;
      }
    }

    &-open {

    }
    &__text {
      height: 80px;
      column-gap: 20px;
      columns: 2 auto;
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #242428;
      text-overflow: ellipsis;
      overflow: hidden;
      //transition: height .8s;
      &_open {
        height: auto !important;
      }
    }

    &__text-button {
      padding-left: 0;
      padding-right:0;
      font-size: 14px;
      line-height: 18px;
      font-family: 'PT Root UI', sans-serif;
      font-weight: 500;
      color: rgba(#242428, 0.6);
      text-align: left;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(#242428, 0.2);
      /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.0); // Фикс остаточных пикселей при анимации в Сафари*/
      transform: translateZ(0px);
      cursor: pointer;
      &-active {
        opacity:0;
      }
    }
  }
  .read-more-fade-enter-active, .read-more-fade-leave-active {
    transition: opacity .6s;
    position: absolute;
    left:0;
    top:0;
  }
  .read-more-fade-enter, .read-more-fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }


  .t-read-more__hidden {
    height: 0;
    overflow: hidden;
    & > div {
      column-gap: 20px;
      columns: 2 auto;
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #242428;
    }
  }


  @media (max-width: 1200px) {
    .t-read-more__wrapper {
      height: 62px;
    }
    .t-read-more__text, .t-read-more__hidden > div {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
      height: auto;
      columns: 1;
    }
    .read-more-fade-enter-active, .read-more-fade-leave-active {
      transition: opacity .0s;
    }
  }

  @media (max-width: 767px) {
    .t-read-more__wrapper {
      height: 284px;
    }
  }

</style>
<script>
import transition_height from "./../transition-height/index.vue";

export default {
  data() {
    return {
      isOpen: false,
      max_show2: this.max_show,
      time_active: false,

      showText: false,
      langEn: false
    };
  },
  props: {
    text: {
      default: "",
      requried: true,
      closeHeight: '',
      openHeight: '',
    }
  },
  name: "component_read_more",
  computed: {
    button_text() {
      if (this.langEn) {
        return this.isOpen ? "Collapse" : "Read more";
      } else {
        return this.isOpen ? "Свернуть" : "Читать подробнее";
      }
    }
  },
  components: {
    "transition-height": transition_height
  },
  mounted() {
      if (location.href.split('/')[3] == 'en') {
        this.langEn = true;
      }

      this.closeHeight = this.$refs.textWrapper.$el.clientHeight;
      this.openHeight = this.$refs.text.children[0].clientHeight;

      // вставка ... в конце последнего слова
      var box = document.querySelector('.t-read-more__wrapper > .t-read-more__text'),
          text = box.innerHTML,
          clone = document.createElement('div');

      clone.style.position = 'absolute';
      clone.style.visibility = 'hidden';
      clone.style.columns = '2 auto';
      clone.style.lineHeight = '24px';
      clone.style.fontSize = '16px';
      clone.style.columnGap = '20px';
      clone.style.lineHeight = '24px';
      clone.style.width = box.clientWidth + 'px';
      clone.innerHTML = text;
      document.body.appendChild(clone);

      text = text.replace(/\s{2,}/g, " ");

      var l = text.length - 1;
      for (; l >= 0 && clone.clientHeight > box.clientHeight; --l) {
          clone.innerHTML = text.substring(0, l) + '...';
      }

      for (l; l >= 0 ; --l) {
          if (text[l] === ' ') {
              clone.innerHTML = text.substring(0, l) + '...';
              break;
          }
      }
      box.innerHTML = clone.innerHTML;
      clone.remove();

  },
  methods: {
    toggleMore: function() {
      let container = this.$refs.textWrapper.$el;

      this.isOpen = !this.isOpen;
      this.time_active = true;
      this.timeActive();
      this.showText = !this.showText;

      if (this.isOpen) {
        container.style.height = `${this.openHeight}px`;

        setTimeout(function() {
          container.style.height = `${this.openHeight + 10}px`;
        }, 0);
      } else {
        container.style.height = `${this.closeHeight}px`;
      }

    },
    timeActive: function() {
      setTimeout(() => {
        this.time_active = false;
      }, 600);
    },
    show_more: function() {
      this.isOpen = !this.isOpen;
      this.time_active = true;
      this.timeActive();
      this.showText = !this.showText;
    },
    // timeActive: function() {
    //   if (window.innerWidth > 1200) {
    //     setTimeout(() => {
    //       this.time_active = false;
    //     }, 710);
    //   }
    //   else {
    //     setTimeout(() => {
    //       this.time_active = false;
    //     }, 400);
    //   }
    //
    // }
  }
};
</script>