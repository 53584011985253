export const data = {
    optionSwiperMobile: {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        spaceBetween: 80,
        centeredSlides: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiperMobile .swiper-button-next',
            prevEl: '.swiperMobile .swiper-button-prev',
        },
    },
    optionSwiperMobileTrust: {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        spaceBetween: 40,
        slidesPerView: 2,
        slidesPerColumn: 2,
        navigation: {
            nextEl: '.trust-block-mobile .swiper-button-next-clients',
            prevEl: '.trust-block-mobile .swiper-button-prev-clients',
        },
    },
    rec: {
        page: {
            background: [
                require("./../../../assets/image/background-image/recruting-page-bg-image.png")
            ],
            title: "Рекрутинг респондентов",
            subtitle:
                "Качественный рекрутинг респондентов для маркетинговых<br/>и UX исследований",
            button_text: "Получить расчет стоимости",
            tooltip: "в течение 2 часов"
        },
        content_arr: [
            {
                text: `Собираем данные о важности тех или иных характеристик товаров или услуг заказчика с точки зрения потребителей, 
          используя современные методы. Используя актуальные методы статистического анализа, прогнозируем спрос на товар или услугу 
          при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, которые позволяют улучшить товар. 
          прогнозируем спрос на товар или услугу при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, 
          которые позволяют улучшить товар. </br> Собираем данные о важности тех или иных характеристик товаров или услуг заказчика с точки зрения потребителей, 
          используя современные методы. Используя актуальные методы статистического анализа, прогнозируем спрос на товар или услугу 
          при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, которые позволяют улучшить товар. 
          прогнозируем спрос на товар или услугу при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, 
          которые позволяют улучшить товар </br> Собираем данные о важности тех или иных характеристик товаров или услуг заказчика с точки зрения потребителей, 
          используя современные методы. Используя актуальные методы статистического анализа, прогнозируем спрос на товар или услугу 
          при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, которые позволяют улучшить товар. 
          прогнозируем спрос на товар или услугу при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, 
          которые позволяют улучшить товар </br> Собираем данные о важности тех или иных характеристик товаров или услуг заказчика с точки зрения потребителей, 
          используя современные методы. Используя актуальные методы статистического анализа, прогнозируем спрос на товар или услугу 
          при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, которые позволяют улучшить товар. 
          прогнозируем спрос на товар или услугу при выводе его на рынок и в зависимости от различных значений его параметров. Предоставляем стратегические рекомендации, 
          которые позволяют улучшить товар`
            }
        ],
        text: {
            title:
                "Качественный подбор респондентов — важнейшая составляющая маркетинговых и UX исследований. Наша компания поставила своей целью вывод процесса поиска респондентов на принципиально новый уровень в интересах исследовательской отрасли и всех участников сообщества.  "
        },

        info: {
            benefits: [
                {
                    icon: require("./../../../assets/image/icon/searchnew.png"),
                    title: "Поиск сложных ЦА",
                    subtitle:
                        "Находим респондентов даже<br/>по наиболее сложным критериям."
                },
                {
                    icon: require("./../../../assets/image/icon/timer.png"),
                    title: "Оперативность",
                    subtitle:
                        "Высокая скорость рекрутмента,<br/>от 1-2 дней для поиска массового сегмента."
                },
                {
                    icon: require("./../../../assets/image/icon/bestsearch.png"),
                    title: "Качественный поиск",
                    subtitle: "Отсутствуют профессиональные респонденты."
                },
                {
                    icon: require("./../../../assets/image/icon/user.png"),
                    title: "Только штатный персонал",
                    subtitle:
                        "Собственный отдел рекрутмента, мы не привлекаем рекрутеров-фрилансеров."
                },
                {
                    icon: require("./../../../assets/image/icon/book.png"),
                    title: "Самая большая база",
                    subtitle: "Респондентов и экспертов,<br/>более 25 000 человек."
                },
                {
                    icon: require("./../../../assets/image/icon/switch.png"),
                    title: "2-х уровневый контроль",
                    subtitle:
                        "На 1 уровне за контроль соответствия респондента требованиям отвечает рекрутер, на 2 — руководитель команды."
                },
                {
                    icon: require("./../../../assets/image/icon/discount.png"),
                    title: "Высокий показатель явки",
                    subtitle:
                        "91,7% записанных респондентов пришли на исследования,<br/>по данным за 1 кв. 2019 г (СПР)."
                },
                {
                    icon: require("./../../../assets/image/icon/discount.png"),
                    title: "Соблюдение ICC/ESOMAR",
                    subtitle:
                        "Взаимодействие регламентируется кодексом международной ассоциации ESOMAR."
                }
            ],
            system: [
                {
                    icon: require("./../../../assets/image/icon/laptop.svg"),
                    title: "none",
                    subtitle:
                        "Единственная в России<br/>встроенная рекрутинг-панель<br/>для таргетированных рассылок"
                },
                {
                    icon: require("./../../../assets/image/icon/history.svg"),
                    title: "none",
                    subtitle:
                        "Вся история взаимодействия с каждым респондентом хранится в одном месте"
                },
                {
                    icon: require("./../../../assets/image/icon/blacklist.svg"),
                    title: "none",
                    subtitle: "Черный список недобросовестных респондентов"
                },
                {
                    icon: require("./../../../assets/image/icon/up.svg"),
                    title: "none",
                    subtitle:
                        "Показатели успешности работы каждого рекрутера позволяют работать над эффективностью команды"
                },
                {
                    icon: require("./../../../assets/image/icon/gears.svg"),
                    title: "none",
                    subtitle:
                        "Автоматизация бизнес-процесса по согласованию респондентов"
                }
            ]
        },

        system: {
            title:
                "В 2017 году компания разработала и внедрила «Систему по подбору респондентов» (СПР) — уникальную IT-платформу, позволяющую вывести работу по поиску и согласованию респондентов на новый уровень в части качества",
            color: "white"
        },

        feedback_mini: {
            img: require("./../../../assets/image/recruting-page-face.png"),
            title: "Анна Ившина",
            subtitle: "Руководитель направления",
            department: "Консалтинг по построению отдела рекрутмента",
            text:
                "Напишите требования по проекту на электронную почту \n" +
                "или позвоните и получите персональное предложение",
            email: "anna_ivshina@rus-opros.com",
            phone: "+7 915 130 44 39"
        },

        flightpath: {
            entry: {
                curviness: 1.25,
                autoRotate: false,
                values: [{ x: window.innerWidth / 2 - 550 / 2, y: 0 }]
            }
        },
        tween: null

    }
};

export const mounted = () => {

};