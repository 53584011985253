<template>
    <ul class="breadcrumbs" v-bind:class="{'breadcrumbs-white': type}">
        <li class="breadcrumbs__item">
            <a class="breadcrumbs__link" href="/">Главная</a>
        </li>
        <li class="breadcrumbs__item">
            <img alt="triangle" src="./../../../assets/image/Triangle.svg"/>
        </li>
        <li class="breadcrumbs__item">
            <a class="breadcrumbs__link" href="link">{{ title }}</a>
        </li>
    </ul>
</template>
<style lang="scss" scoped>
    .breadcrumbs {
        list-style-type: none;
        display: flex;


        &__item {
            margin-right: 7px;
            margin-left: 7px;
            font-family: 'PT Root UI', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

        }

        &__link {

            border-bottom: 1px solid #D3D3D4;
            text-decoration: none;
            color: #242428;
            transition: 0.15s;
            cursor: pointer;

            &:hover {
                border-bottom: 1px solid transparent;
            }
        }

        &-white {

            & .breadcrumbs__link {
                color: #fff;
            }
        }
    }
</style>
<script>
    export default {
        name: "component_breadcrumbs",

        props: {
            type: {
                default: () => {
                    return false;
                }
            },
            link: {
                default: () => {
                    return "/";
                }
            },
            title: {
                default: () => {
                    return "";
                }
            }
        }
    };

</script>