var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-slider t-article-slider" },
    [
      _c(
        "swiper",
        { ref: "mySwiper", attrs: { options: _vm.swiperOption } },
        [_vm._t("slide")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "t-article-slider-nav" }, [
        _c(
          "svg",
          {
            ref: "btn_prev",
            staticClass:
              "t-article-slider-nav__btn t-article-slider-nav__btn_prev",
            attrs: {
              slot: "button_prev",
              viewBox: "0 0 16 32",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            slot: "button_prev"
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M16 0.702104L15.2839 0L0 16L15.2839 32L16 31.2979L1.38682 16L16 0.702104Z"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", {
          ref: "nav__num",
          staticClass: "t-article-slider-nav__num",
          attrs: { slot: "pagination" },
          slot: "pagination"
        }),
        _vm._v(" "),
        _c(
          "svg",
          {
            ref: "btn_next",
            staticClass:
              "t-article-slider-nav__btn t-article-slider-nav__btn_next",
            attrs: {
              slot: "button-next",
              viewBox: "0 0 16 32",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            slot: "button-next"
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M0 0.702104L0.716136 0L16 16L0.716136 32L0 31.2979L14.6132 16L0 0.702104Z"
              }
            })
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }