<template>
    <div class="questions">
        <h2 class="questions__title h2" v-if="show_title">{{title}}</h2>
        <div class="questions__wrapper">
            <div class="question" 
                 v-for="(item , n) in data"
                 :key="n">
                <div class="question__problem h5" 
                     @click="question_now === n ? question_now = null : question_now = n">
                    {{ item.problem }}
                    <button class="question__button" type="button"
                            :class="{'question__button--active': question_now === n}">
                        <img alt="close-answer" class="question__minus" src="./../../../assets/image/minus.svg"/>
                        <img alt="close-answer" class="question__plus" src="./../../../assets/image/minus.svg"/>
                    </button>
                </div>
                <transition-height>
                    <div class="question__answer p html" 
                         v-show="question_now === n"
                         v-html="item.answer"></div>
                </transition-height>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .questions {
        margin-top: 40px;
        margin-bottom: 30px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);

        &__wrapper {
            width: 960px;
        }
    }

    .question {
        border-top: 1px solid rgba(36, 36, 40, 0.1);
        border-bottom: 1px solid rgba(36, 36, 40, 0.1);
        padding-top: 28px;
        padding-bottom: 28px;
        mix-blend-mode: normal;

        &:first-child {
            border-bottom: 1px solid rgba(36, 36, 40, 0.1);
        }

        &__head {
            width: calc(100% - 36px);
            padding-right: 36px;
        }

        &__problem {
            color: #242428;
            position: relative;
            cursor: pointer;
            //line-height: 36px;
            padding-right: 36px;

            & h5 {
                margin: 0;
            }
        }

        &__answer {
            color: #242428;
            max-width: 860px;
            padding-bottom:12px;
        }

        &__button {
            background-color: transparent;
            border: none;
            position: absolute;
            right: 0;
            top: 0;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            width:39px;
            padding-top: 0;
            padding-bottom: 0;
            &--active {

                & .question__plus {
                    transform: rotate(0deg);
                }
            }
        }

        &__minus {
            width:100%;
            height:100%;

        }

        &__plus {
            position: absolute;
            right: 7px;
            transform: rotate(90deg);
            transition: 0.5s;
        }


    }

</style>
<script>
    import transitionHeight from './../transition-height/index.vue';
    export default {
        props: {
            title: {
                default: () => {
                    return "Ответы на часто задаваемые вопросы";
                }
            },
            show_title: {
                default: () => {
                    return true;
                }
            },
            data: {
                default: () => {
                    return [
                        {
                            problem: "Как работает онлайн-панель?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        },
                        {
                            problem: "Как осуществляется отбор нужных респондентов?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        },
                        {
                            problem: "Как часто респонденты могут принимать участие в опросах?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        },
                        {
                            problem:
                                "Что мотивирует респондентов принимать участие в исследованиях?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        },
                        {
                            problem:
                                "Почему респонденты не всегда приходят? Что норма, а что нет?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        },
                        {
                            problem: "Какие типы опросов могут использоваться?",
                            answer:
                                "Онлайн-панель базируется на автоматизированных e-mail рассылках приглашений респондентов к участию \n" +
                                "в опросах, при этом применяется таргетирование основных атрибутов, что уже на данном этапе отфильтровывает заведомо не подходящую аудиторию. "
                        }
                    ];
                }
            }
        },

        name: "component_questions",

        components: {
            transitionHeight,
        },

        data: function() {
            return {
                question_now: null
            };
        },

        methods: {}
    };

</script>