<template>

    <modal
            name="call_back_success"
            :width="'100%'"
            :height="'auto'"
            class="modal modal__success"
            v-on:before-open="beforeOpen"
            @closed="beforeClose"
            @opened="opened"
    >
        <div class="modal__closer">
            <div>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="$modal.hide('call_back_success')">
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9 8.30769L17.3077 0L18 0.692308L9.69231 9L18 17.3077L17.3077 18L9 9.69231L0.692308 18L0 17.3077L8.30769 9L0 0.692308L0.692308 0L9 8.30769Z" fill="#0E131F"/>
                </svg>
            </div>
        </div>
        <div class="modal__container">
            <h3 class="modal__title h3">{{title}}</h3>
            <p class="modal__text p" v-html="text[status]"></p>
            <button class="modal__button" type="button" v-on:click="$modal.hide('call_back_success')"><span>Ок</span></button>
        </div>
    </modal>

</template>
<script>
    export default {
        name: "call-back-success-modal",
        components: {},
        props: {
            title: {
                default: "Спасибо!"
            }
        },
        data() {
            return {
                status: null,
                text: {
                    true: "Заявка на обратный звонок получена. <br> Мы свяжемся с вами в ближайшее время.",
                    false: "Спасибо, ваша заявка принята и передана в работу.<br/>Коммерческое предложение будет направлено вам<br/>до конца следующего рабочего дня!"
                }
            };
        },
        computed: {},
        mounted() {},
        methods: {
            beforeOpen: function ($event) {
                this.status = $event.params.status;
                this.text.false = $event.params.textLeft;
                this.text.true = $event.params.textRight;
            },
            beforeClose: function() {

                if (window.innerWidth > 767) {
                    document.getElementsByTagName("html")[0].style.overflowY = "auto";
                }
                // else {
                let elem = document.querySelector("#app");
                let heightScroll = elem.style.top;

                elem.style.position = "static";
                elem.style.width = "100%";
                elem.style.top = "";

                window.scrollTo({
                    top: -parseInt(heightScroll),
                    behavior: "instant"
                });
                // }
            },
            opened: function() {
                if (window.innerWidth > 767) {
                    document.getElementsByTagName("html")[0].style.overflowY = "scroll";
                }
                // else {
                let heightScroll = window.pageYOffset;
                let elem = document.querySelector("#app");

                elem.style.position = "fixed";
                elem.style.width = "100%";
                elem.style.top = -heightScroll + "px";
                // }
            }
        }
    };


</script>
<style lang="scss">
    .modal__success {
        &.v--modal-overlay .v--modal-box {
            max-width:470px !important;
            margin:0 auto;
        }
    }
    .modal {

        &__closer {
            display: flex;
            justify-content: flex-end;
            //margin:20px 20px 0 0;

            & div {

                cursor: pointer;
                padding: 20px;
            }
            & div:hover {
                & svg {
                    transform: rotate(90deg);
                }
            }
            & svg {
                cursor: pointer;
                transition: transform 0.5s;

            }
        }
        &__title {
            text-align: center;
            margin: 0 0 0 0;
        }
        &__text {
            width: 100%;
            margin-top: 15px;
            line-height: 20px;
            text-align: center;

            color: #242428;
        }
        &__button {
            margin-top:40px;
            mix-blend-mode: normal;
            border: 1px solid rgba(151, 151, 151, 0.4);
            box-sizing: border-box;
            border-radius: 35px;
            font-family: "PT Root UI", sans-serif;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            background-color: transparent;
            width: 175px;
            height: 70px;
            font-weight: 700;
            cursor: pointer;

            color: #242428;
            transition: 0.25s;

            &:hover {
                background-color: #E5190A;
                box-shadow: 0px 20px 20px rgba(229, 25, 10, 0.166667);
                color: #fff;
                border: 1px solid transparent;

                span {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                }
            }
        }
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px 29px;
        }
    }
</style>

