<template>
    <div class="question">
        <div class="question__problem h5" 
            @click="question_now === n ? question_now = null : question_now = n">
            <div class="question__head">
                {{ title }}
            </div>
            <button class="question__button" type="button"
                    :class="{'question__button--active': question_now === n}">
                <img alt="close-answer" class="question__minus" src="./../../../assets/image/minus.svg"/>
                <img alt="close-answer" class="question__plus" src="./../../../assets/image/minus.svg"/>
            </button>
        </div>
        <transition-height>
            <div class="question__answer p html" 
                 v-show="question_now === n" 
                 v-html="description"></div>
        </transition-height>
    </div>
</template>
<script>
    export default {
        props: ['n', 'title', 'description']
    }
</script>