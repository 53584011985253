<template>
    <modal
            @opened="opened"
            @before-open="beforeOpen"
            @closed="closed"
            @before-close="beforeClose"
            :height="getHeight"
            :width="getWidth"
            name="foo"
            class="modal__carousel">

        <div class="modal__carousel--prev unselectable" slot="top-right" v-on:click="delta(-1)">
            <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z"
                      fill="#fff"/>
            </svg>
        </div>
        <div class="modal__carousel--next unselectable" slot="top-right" v-on:click="delta(1)">
            <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.0753 5.98931L17.8902 0.722222L18.6012 0L25 6.5L18.6012 13L17.8902 12.2778L23.0753 7.01069H0V5.98931H23.0753Z"
                      fill="#fff"/>
            </svg>
        </div>
        <div class="modal__carousel-container" ref="feedbackModalContainer">
            <div class="modal__carousel--close unselectable" slot="top-right"  @click="$modal.hide('foo')">
                <img src="./../../../../assets/image/icon/plus.svg" alt="close-modal">
            </div>
            <swiper ref="feedbackModal">
                <swiper-slide v-for="(item, n) in data">
                    <img :src="item.src" :alt="item.alt"/>
                </swiper-slide>
            </swiper>
        </div>
    </modal>
</template>
<script>

    import { swiper, swiperSlide } from "vue-awesome-swiper";
    export default {
        name: "feedback-modal",
        components: {
            swiper, swiperSlide
        },
        data() {
            return {
                data: [],
                n: null,
            };
        },
        watch: {},
        computed: {
            getHeight: function() {
                if(window.innerWidth > 768) {
                    return parseInt(window.innerHeight - 100);
                } else {
                    return 'auto'
                }
            },
            getWidth: function() {
                if(window.innerWidth <= 768) {
                    return window.innerWidth - 20;
                } else {
                    return this.getHeight / 1.41;
                }
            }
        },
        mounted() {
        },
        methods: {
            beforeOpen: function($args) {
                this.data = $args.params.data;
                this.n = $args.params.n;
            },
            beforeClose: function () {
                // это нужно, так как перед закрытием открывается первый слайд
                this.$refs.feedbackModalContainer.style.opacity = '0';

            },
            closed: function () {
                if (window.innerWidth > 767) {
                    document.getElementsByTagName('html')[0].style.overflowY = "auto";
                }
                // else {
                    let elem = document.querySelector('#app');
                    let heightScroll = elem.style.top;

                    elem.style.position = "static";
                    elem.style.width = "100%";
                    elem.style.top = '';

                    window.scrollTo({
                        top: -parseInt(heightScroll),
                        behavior: "instant"
                    });
                // }

            },
            opened: function ($args) {
                this.$refs.feedbackModal.swiper.slideTo(this.n, 0, false);

                if (window.innerWidth > 767) {
                    document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                }
                // else {
                    let heightScroll = window.pageYOffset;
                    let elem = document.querySelector('#app');

                    elem.style.position = "fixed";
                    elem.style.width = "100%";
                    elem.style.top = -heightScroll + 'px';
                // }
            },
            delta(n) {
                switch (n) {
                    case 1:
                        this.$refs.feedbackModal.swiper.slideNext(500);
                        break;
                    case -1:
                        this.$refs.feedbackModal.swiper.slidePrev(500);
                        break;
                }
            }
        }
    };

</script>
<style lang="scss">
    .modal__carousel {
        background-color:rgba(0,0,0,0.6) !important;

        .modal__carousel-container {
            position: relative;
            .swiper-container {
                padding: 0;
                margin: 0;
            }
        }

        & .v--modal-box {
            margin: 0 auto;
            left: 0 !important;
        }

        & * {
            user-select: none; -moz-user-select: none;
        }
        img {
            width: 100%;
            height: 100%;
        }

        &--close {
            right: -50px;
            top: 0;
            position: absolute;
            background-color: transparent;
            border: none;
            cursor: pointer;
            width:25px;
            height: 25px;
            

            & > img {
                transition: transform 0.3s;
                transform: rotate(45deg);
                pointer-events: none;
            }
            &:hover {
                & > img {
                    transform: rotate(calc(45deg * 3));
                }
            }
        }
        &--prev, &--next {
            width:70px;
            height:70px;
            border-radius: 100%;
            border:1px solid rgba(255,255,255, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.25s;
            position: absolute;
            top:calc((100vh / 2) - (70px / 2));
            cursor: pointer;
            &:hover {
                border-color:#fff;
            }
        }

        &--prev {
            transform: rotate(180deg);
            right: calc(100vw - 302px);
        }

        &--next {
            right: 232px;
        }

    }

    .v--modal-overlay {
        overflow: auto !important;
        background-color:rgba(0,0,0,0.7) !important;
    }


    @media (max-width: 1200px) {
        .modal__carousel.v--modal-overlay .v--modal-box {
            max-height: 700px;
        }
        .modal__carousel--prev {
            right: calc(100vw - 220px);
        }
        .modal__carousel--next {
            right: 120px;
        }
        .modal__carousel--close {
            right: -40px;
        }
    }

    @media (max-width: 767px) {
        .v--modal-overlay .v--modal-box {
            height: auto !important;
            left: 0 !important;
            top: 0 !important;
            width: 100% !important;
            max-width: 760px;
            //min-height: 100vh;
        }
        .modal__carousel--close {
            right: 10px;
            top: -38px;
        }
        .modal__carousel.v--modal-overlay .v--modal-box {
            max-height: 500px;
        }
    }
</style>