var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.now[_vm.i]
    ? _c(
        "transition",
        { attrs: { mode: "out-in", name: _vm.n % 2 ? "flip" : "flip1" } },
        [
          _vm.n === _vm.now[_vm.i].now || _vm.n === _vm.now[_vm.i].now + 1
            ? _c("div", { staticClass: "clients__group" }, [
                _c(
                  "a",
                  {
                    staticClass: "clients__logo",
                    class: {
                      "clients__logo--hover": _vm.hover_id === _vm.i + 1
                    },
                    attrs: { href: _vm.link, target: "__blank" }
                  },
                  [_c("img", { attrs: { alt: _vm.imageAlt, src: _vm.image } })]
                )
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }