export const data = {
    type: false,

    hide_hovered: true,
    status: false,
    openMenu: false,
    openMenu2: false,
    headerZIndex: false,
    activeLinkIPad: -1,
    headerZIndexBurger: false,

    header: {
        translate: "",
        scale: "",
        opacity: 0,
        active: "",
    }
};

export const mounted = () => {
    methods.checkPage(location.pathname);
    setTimeout(() => {
        methods.MouseLeave();
    }, 300);
};

export const methods = {
    checkPage($args) {
        let route = $args.split("/")[1];
        if (route == 'en')
            route = route + '/' + $args.split("/")[2];
        data.header.active = document.querySelector(`[data-link='/${route}/']`);
    },

    HoverEffect ($event) {
        if(window.innerWidth >= 1200) {
            let link = $event.target.getBoundingClientRect();
            // let x_pos = link.left - 20;
            // let a = link.width + 40;
            let x_pos = link.left - 5;
            let a = link.width + 10;

            data.header.translate = `translateX(${x_pos}px)`;
            data.header.scale = `${a}`;

            data.header.opacity = 1;
        }

    },

    MouseLeave() {
        if (window.location.pathname === "/" || window.location.pathname === "/404.php" || window.location.pathname === "/en/") {
            if(window.innerWidth >= 1200) {
                data.header.opacity = 0;
            }
        } else {
            let link = data.header.active.getBoundingClientRect();
            // let x_pos = link.left - 20;
            // let a = link.width + 40;
            let x_pos = link.left - 5;
            let a = link.width + 10;

            data.header.translate = `translateX(${x_pos}px)`;
            data.header.scale = `${a}`;

            data.header.opacity = 1;
        }
    },
    hideMenu() {
        this.hide_hovered = false;

        setTimeout(() => {
            this.hide_hovered = true;
        }, 200);
    },
    stopScroll(e) {
        e.preventDefault();
    },
    openMenuMobile() {
        this.openMenu = !this.openMenu;
        let elem = document.querySelector('body');
        // let heightTop = elem.style.top;
        // let heightScroll = window.pageYOffset;

        if (elem.style.position === "fixed") {
            document.body.style.overflow = "auto";
            //document.body.style.pointerEvents = "auto";
            elem.style.position = "static";
            elem.style.width = "100%";
            // elem.style.top = '';

            // window.scrollTo({
            //     top: -parseInt(heightTop),
            //     behavior: "instant"
            // });

            // document.body.removeEventListener("touchmove", methods.stopScroll, {
            //     passive: false
            // });
        } else {
            document.body.style.overflow = "hidden";
            //document.body.style.pointerEvents = "none";
            elem.style.position = "fixed";
            elem.style.width = "100%";
            // elem.style.top = -heightScroll + 'px';

            // document.body.addEventListener("touchmove", methods.stopScroll, {
            //     passive: false
            // });
        }

        document.querySelectorAll('.header__hovered').forEach((item) => {
            item.style.transform = "translateX(-120vw) translate3d(0,0,0)";
        });
        setTimeout(() => {
            this.headerZIndex = false;
        }, 300);
        this.headerZIndexBurger = false;

        if (767 < window.innerWidth && window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header__basebase .header__drop')[0];
            let subMenu = memuItem.querySelector('.header__hovered');
            subMenu.style.transform = "translateX(0) translate3d(0,0,0)";
            this.headerZIndex = true;
            setTimeout(() => {
                this.headerZIndexBurger = true;
            }, 550);
            this.activeLinkIPad = 0;
        }
    },
    openMenuMobile2() {
        this.openMenu2 = !this.openMenu2;
        let elem = document.querySelector('body');
        let elemApp = document.querySelector('#app');
        let heightTop = elem.style.top;
        let heightScroll = window.pageYOffset;

        if (elem.style.position === "fixed") {
            document.body.style.overflow = "auto";
            //document.body.style.pointerEvents = "auto";

            elem.style.position = "static";
            elemApp.style.position = "static";
            elem.style.width = "100%";
            elemApp.style.width = "100%";
            elem.style.top = '';
            elemApp.style.top = '';

            window.scrollTo({
                top: -parseInt(heightTop),
                behavior: "instant"
            });

            // document.body.removeEventListener("touchmove", methods.stopScroll, {
            //     passive: false
            // });
        } else {
            document.body.style.overflow = "hidden";
            //document.body.style.pointerEvents = "none";

            elem.style.position = "fixed";
            elemApp.style.position = "fixed";
            elem.style.width = "100%";
            elemApp.style.width = "100%";
            elem.style.top = -heightScroll + 'px';
            elemApp.style.top = -heightScroll + 'px';

            // document.body.addEventListener("touchmove", methods.stopScroll, {
            //     passive: false
            // });
        }

        document.querySelectorAll('.header__hovered').forEach((item) => {
            item.style.transform = "translateX(-120vw) translate3d(0,0,0)";
        });
        setTimeout(() => {
            this.headerZIndex = false;
        }, 300);
        this.headerZIndexBurger = false;

        if (767 < window.innerWidth && window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header_small .header__drop')[0];
            let subMenu = memuItem.querySelector('.header__hovered');
            subMenu.style.transform = "translateX(0) translate3d(0,0,0)";
            this.headerZIndex = true;
            setTimeout(() => {
                this.headerZIndexBurger = true;
            }, 550);
            this.activeLinkIPad = 0;
        }
    },
    openSubMenuMobile(id, event) {
        if (window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header__basebase .header__drop')[id];
            let subMenu = memuItem.querySelector('.header__hovered');

            if (subMenu && subMenu.style.transform === "translateX(0) translate3d(0,0,0)") {
                //subMenu.style.display = "none";
                //this.headerZIndex = false;

            } else {
                if(!event.target.classList.contains('header__hovered_back')) {
                    document.querySelectorAll('.header__hovered').forEach((item) => {
                        item.style.transform = "translateX(-120vw) translate3d(0,0,0)";
                    });
                    subMenu.style.transform = "translateX(0) translate3d(0,0,0)";
                    if (!subMenu.classList.contains('_iPad')) {
                        this.headerZIndex = true;
                    }
                    setTimeout(() => {
                        this.headerZIndexBurger = true;
                    }, 550);
                }
            }

            if (767 < window.innerWidth) {
                this.activeLinkIPad = id;
            }

            // let element = subMenu.querySelector('.header__hovered--container');
            //
            // if (element) {
            //     var enabled = true;
            //     var scrollMinX = 0;
            //     var scrollMinY = 0;
            //     var scrollMaxX = document.body.scrollWidth - window.innerWidth;
            //     // var scrollMaxY = document.body.scrollHeight - window.innerHeight;
            //     var scrollMaxY = element.clientHeight;
            //
            //     console.log(element);
            //     console.log(scrollMaxX);
            //     console.log(scrollMaxY);
            //
            //     // toggles between "enabled / disabled"
            //     document.onclick = function () {
            //         enabled = !enabled;
            //     };
            //
            //     // make sure that we work with the correct dimensions
            //     window.onresize = function () {
            //         scrollMaxX = document.body.scrollWidth - window.innerWidth;
            //         scrollMaxY = document.body.scrollHeight - window.innerHeight;
            //     };
            //
            //     // where the magic happens
            //     subMenu.onscroll = function () {
            //         if (!enabled) return;
            //
            //         if (window.scrollX <= scrollMinX) scrollTo(scrollMinX, window.scrollY);
            //         if (window.scrollX >= scrollMaxX) scrollTo(scrollMaxX, window.scrollY);
            //
            //         if (window.scrollY <= scrollMinY) scrollTo(window.scrollX, scrollMinY);
            //         if (window.scrollY >= scrollMaxY) scrollTo(window.scrollX, scrollMaxY);
            //     };
            // }

        }
    },
    closeSubMenuMobile(id) {
        if (window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header__basebase .header__drop')[id - 1];
            let subMenu = memuItem.querySelector('.header__hovered');

            subMenu.style.transform = "translateX(-120vw) translate3d(0,0,0)";
            this.headerZIndexBurger = false;
            setTimeout(() => {
                this.headerZIndex = false;
            }, 550);
        }

    },
    openSubMenuMobile2(id, event) {
        if (window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header_small .header__drop')[id];
            let subMenu = memuItem.querySelector('.header__hovered');

            if (subMenu && subMenu.style.transform === "translateX(0) translate3d(0,0,0)") {
                // subMenu.style.display = "none";
                // this.headerZIndex = false;
            } else {
                if(!event.target.classList.contains('header__hovered_back')) {
                    document.querySelectorAll('.header__hovered').forEach((item) => {
                        item.style.transform = "translateX(-120vw) translate3d(0,0,0)";
                    });
                    subMenu.style.transform = "translateX(0) translate3d(0,0,0)";
                    if (!subMenu.classList.contains('_iPad')) {
                        this.headerZIndex = true;
                    }
                    setTimeout(() => {
                        this.headerZIndexBurger = true;
                    }, 550);
                }
            }

            if (767 < window.innerWidth) {
                this.activeLinkIPad = id;
            }
        }
    },
    closeSubMenuMobile2(id) {
        if (window.innerWidth < 1200) {
            let memuItem = document.querySelectorAll('.header_small .header__drop')[id - 1];
            let subMenu = memuItem.querySelector('.header__hovered');

            subMenu.style.transform = "translateX(-120vw) translate3d(0,0,0)";
            this.headerZIndex = false;
            setTimeout(() => {
                this.headerZIndexBurger = false;
            }, 550);
        }
    }

};

