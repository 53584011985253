var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._t("test"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "clients" },
        [
          _vm.main === false
            ? _c("h2", { staticClass: "clients__title h2" }, [
                _vm._v("Клиенты")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clients__wrapper" },
            _vm._l(_vm.clients_arr, function(column, i) {
              return _c(
                "div",
                {
                  staticClass: "clients__column",
                  class: {
                    "clients__column-type": _vm.type,
                    "clients__column-type-cols": _vm.type && _vm.cols
                  }
                },
                _vm._l(column, function(item, n) {
                  return _vm.now[i]
                    ? _c(
                        "transition",
                        {
                          key: "client" + n,
                          attrs: {
                            mode: "out-in",
                            name: n % 2 ? "flip" : "flip1"
                          }
                        },
                        [
                          n === _vm.now[i].now || n === _vm.now[i].now + 1
                            ? _c(
                                "div",
                                { key: n, staticClass: "clients__group" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clients__logo",
                                      class: {
                                        "clients__logo--hover":
                                          _vm.hover_id === i + 1
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: { alt: "", src: item.image }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                }),
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "swiper",
            {
              ref: "swiperTop",
              staticClass: "gallery-top",
              attrs: { options: _vm.swiperOptionTop }
            },
            [
              _vm._l(_vm.clients_arr, function(column, i) {
                return _c(
                  "swiper-slide",
                  {
                    staticClass: "clients__column",
                    class: {
                      "clients__column-type": _vm.type,
                      "clients__column-type-cols": _vm.type && _vm.cols
                    }
                  },
                  _vm._l(column, function(item, n) {
                    return _vm.now[i]
                      ? _c(
                          "transition",
                          {
                            key: "client" + n,
                            attrs: {
                              mode: "out-in",
                              name: n % 2 ? "flip" : "flip1"
                            }
                          },
                          [
                            n === _vm.now[i].now || n === _vm.now[i].now + 1
                              ? _c(
                                  "div",
                                  { key: n, staticClass: "clients__group" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "clients__logo",
                                        class: {
                                          "clients__logo--hover":
                                            _vm.hover_id === i + 1
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: item.imageAlt,
                                            src: item.image
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  }),
                  1
                )
              }),
              _vm._v(" "),
              _vm.clients_arr.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "swiper-button-next-clients",
                      attrs: { slot: "button-next" },
                      slot: "button-next"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "53",
                            height: "22",
                            viewBox: "0 0 53 22",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              opacity: "0.3",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M51.0149 10.4815L41.2671 0.733333L42.0004 0L53 11L42.0004 22L41.2671 21.2667L51.0149 11.5185H0V10.4815H51.0149Z",
                              fill: "#7A7A7C"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.clients_arr.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "swiper-button-prev-clients",
                      attrs: { slot: "button-prev" },
                      slot: "button-prev"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "53",
                            height: "22",
                            viewBox: "0 0 53 22",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              opacity: "0.3",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M1.98513 10.4815L11.7329 0.733333L10.9996 0L0 11L10.9996 22L11.7329 21.2667L1.98513 11.5185H53V10.4815H1.98513Z",
                              fill: "#7A7A7C"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "swiper",
        {
          ref: "swiperThumbs",
          staticClass: "gallery-thumbs",
          attrs: { options: _vm.swiperOptionThumbs }
        },
        _vm._l(_vm.cols_arr, function(item, n) {
          return _c(
            "swiper-slide",
            {
              staticClass: "main-page-client-wrap",
              style: { height: _vm.getHeightCols(n) + "px" }
            },
            [
              _c("div", {
                staticClass: "main-page-client-col main-page-client-col-1",
                style: {
                  height: _vm.getHeightCols(n) + "px",
                  opacity: _vm.getOpacity(n)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "main-page-client-col-text",
                domProps: { innerHTML: _vm._s(item.text) }
              })
            ]
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }