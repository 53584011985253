<template>
    <div class="t-slider t-article-slider">
        <swiper :options="swiperOption" ref="mySwiper">
            <slot name="slide"></slot>
        </swiper>

        <div class="t-article-slider-nav">
            <svg class="t-article-slider-nav__btn t-article-slider-nav__btn_prev" slot="button_prev" ref="btn_prev" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0.702104L15.2839 0L0 16L15.2839 32L16 31.2979L1.38682 16L16 0.702104Z"/>
            </svg>
            <div class="t-article-slider-nav__num" slot="pagination" ref="nav__num"></div>
            <svg class="t-article-slider-nav__btn t-article-slider-nav__btn_next" slot="button-next" ref="btn_next" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0.702104L0.716136 0L16 16L0.716136 32L0 31.2979L14.6132 16L0 0.702104Z"/>
            </svg>
        </div>
    </div>
</template>
<style lang="scss">
    .t-article-slider {
        position: relative;

        & .swiper-container {
            padding:30px 0;
            margin: 30px 0;
        }
        &__slide {

        }
        &__img {
            margin-bottom: 26px;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        &__text {
            display: block;
            width: calc(100% - 140px - 8px);
            padding-right: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.42;
            color: #242428;
        }
    }

    .t-article-slider-nav {
        position: absolute;
        right: 0;
        bottom: 26px;
        z-index: 2;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 140px;
        background: #ffffff;

        &__btn {
            display: block;
            width: 16px;
            height: 32px;
            fill: #242428;
            cursor: pointer;

            &:active, &:focus {
                outline: none;
                border: none;
            }

            &.swiper-button-disabled {
                fill: #D3D3D4;
                cursor: default;
            }
        }

        &__num {
            line-height: 1.25;
            font-size: 20px;
            color: #7A7A7C;
            text-align: center;
        }
    }
</style>
<script>
    // require styles
    import "swiper/dist/css/swiper.css";

    import { swiper, swiperSlide } from "vue-awesome-swiper";

    export default {
        name: "article-slider",
        components: {
            swiper,
            swiperSlide
        },
        props: {
            controls: {
                required: false,
                default: false
            },
            loop: {
                required: false,
                default: true
            }
        },
        data: function() {
            return {
                swiperOption: {
                    spaceBetween: 30,
                    pagination: this.controls
                        ? {
                            el: ".t-article-slider-nav .t-article-slider-nav__num",
                            type: "fraction",
                            renderFraction: function(currentClass, totalClass) {
                                return (
                                    '<span class="' +
                                    currentClass +
                                    '"></span>' +
                                    " из " +
                                    '<span class="' +
                                    totalClass +
                                    '"></span>'
                                );
                            }
                        }
                        : null,
                    navigation: this.controls
                        ? {
                            nextEl: ".t-article-slider-nav .t-article-slider-nav__btn_next",
                            prevEl: ".t-article-slider-nav .t-article-slider-nav__btn_prev"
                        }
                        : null,
                    loop: this.loop ? true : false
                }
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            }
        },
        mounted() {},
        methods: {}
    };

</script>