<template>
    <div class="social">
        <slot name="icon"></slot>
    </div>
</template>
<script>
    export default {
        name: "social-icon"
    };

</script>
<style lang="scss" scoped>
    /*.contact__info-content {*/
        /*.contacts__social .social__item {*/
            /*opacity: 1;*/
            /*filter: brightness(8) opacity(70%);*/
            /*img {*/
                /*filter: grayscale(1);*/

                /*&:hover {*/
                    /*filter: grayscale(0);*/
                /*}*/
            /*}*/
        /*}*/
    /*}*/

    /*.contacts__social {*/
        /*.social__item {*/
            /*opacity: 1;*/
        /*}*/
    /*}*/

    /*.contacts__social--form {*/
        /*.social__item {*/
            /*opacity: 0.5;*/

            /*&:hover {*/
                /*opacity: 1 !important;*/
                /*filter: brightness(100%) grayscale(0) !important;*/
            /*}*/
        /*}*/
        /*.social__item-gray {*/
            /*display: none;*/
        /*}*/
    /*}*/

    .social {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;



        &__item {
            cursor: pointer;
            //fill: rgba(#242428, .3);
            //transition: all .2s linear;
            //filter: grayscale(100%);
            //opacity:0.5;
            line-height: 0;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    ._social-gray {
        .social__item {
            position: relative;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;


            //&:hover {
            //fill: rgba(#242428, 1);
            //opacity:1 !important;
            //filter: grayscale(0) !important;
            //}

            &:first-child {
                width: 11px;
                img {
                    max-width: 11px;
                }
            }
            &:last-child {
                width: 18px;
                img {
                    max-width: 18px;
                }
            }

            img {
                max-height: 20px;
                max-width: 20px;
                position: absolute;
                bottom: 0;
            }

            &-color {
                transition: opacity .3s;
                z-index: 1;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
            }
            &-gray {
                //z-index: -1;
            }
            &-white {
                display: none;
            }
        }

        &._white {
            .social__item-white {
                display: block;
                //z-index: -1;
            }
            .social__item-gray {
                display: none;
            }
        }
    }



</style>

