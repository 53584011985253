export class GObject {
  opacity = 0;

  fade(delta) {
    this.opacity = Math.max(Math.min(this.opacity + delta, 0.78), 0.2);
  }
}

export class GNode extends GObject {
  dPosX = 0;
  dPosY = 0;
  constructor(
    posX, // Горизонтальное положение в относительных координатах, обычно в диапазоне [0.0, relWidth], где relWidth <= 1.0
    posY, // Горизонтальное положение в относительных координатах,обычно в диапазоне [0.0, relHeight], где relHeight <= 1.0
    posZ,
    radius, // Радиус узла, положительное действительное число
    velX, // Горизонтальная скорость в относительных единицах (не в пикселях)
    velY, // Вертикальная скорость в относительных единицах (не в пикселях)
    velZ,
    id,
    status
  ) {
    super();
    this.posX = posX;
    this.posY = posY;
    this.posZ = posZ; // Прозрачность (отдаленность узла)
    this.radius = radius;
    this.velX = velX;
    this.velY = velY;
    this.id = id;
    this.velZ = velZ;
    this.status = status;
  }
}

export class GEdge extends GObject {
  constructor(
    nodeA, // Ссылка на объект узла, представляющий одну сторону неориентированного ребра
    nodeB // Ссылка на объект узла, представляющий другую сторону неориентированного ребра (должна отличаться от NodeA)
  ) {
    super();
    this.nodeA = nodeA;
    this.nodeB = nodeB;
  }
}

export class DisjointSet {
  parents = [];
  ranks = [];

  constructor(size) {
    for (let i = 0; i < size; i++) {
      this.parents.push(i);
      this.ranks.push(0);
    }
  }

  mergeSets(i, j) {
    const repr0 = this.getRepr(i);
    const repr1 = this.getRepr(j);
    if (repr0 == repr1) return false;
    const cmp = this.ranks[repr0] - this.ranks[repr1];
    if (cmp >= 0) {
      if (cmp == 0) this.ranks[repr0]++;
      this.parents[repr1] = repr0;
    } else this.parents[repr0] = repr1;
    return true;
  }

  getRepr(i) {
    if (this.parents[i] !== i) this.parents[i] = this.getRepr(this.parents[i]);
    return this.parents[i];
  }
}

export let coordinates = {
    pos_arr8: [
        //left
        {
          id: 0,
          posX: 0.04,
          posY: 0.2,
          posZ: 0.3
        },
        {
          id: 1,
          posX: 0.02,
          posY: 0.4,
          posZ: 0.15
        },
        {
          id: 2,
          posX: 0.04,
          posY: 0.6,
          posZ: 0.3
        },
        {
          id: 3,
          posX: 0.06,
          posY: 0.8,
          posZ: 0.15
        },
        //right
        {
          id: 4,
          posX: 0.4,
          posY: 0.15,
          posZ: 0.3
        },
        {
          id: 5,
          posX: 0.37,
          posY: 0.3,
          posZ: 0.15
        },
        {
          id: 6,
          posX: 0.34,
          posY: 0.47,
          posZ: 0.3
        },
        {
          id: 7,
          posX: 0.4,
          posY: 0.62,
          posZ: 0.3
        },
        //static top
        {
          id: 8,
          posX: 0.01,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 9,
          posX: 0.24,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 10,
          posX: 0.4,
          posY: -0.01,
          posZ: 0.0
        },
        //static bottom
        {
          id: 11,
          posX: 0.01,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 12,
          posX: 0.24,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 13,
          posX: 0.4,
          posY: 1.01,
          posZ: 0.0
        }
      ],

      pos_arr9: [
        //left
        {
          id: 0,
          posX: 0.04,
          posY: 0.2,
          posZ: 0.3
        },
        {
          id: 1,
          posX: 0.02,
          posY: 0.4,
          posZ: 0.15
        },
        {
          id: 2,
          posX: 0.04,
          posY: 0.6,
          posZ: 0.3
        },
        {
          id: 3,
          posX: 0.06,
          posY: 0.8,
          posZ: 0.15
        },
        //right
        {
          id: 4,
          posX: 0.4,
          posY: 0.15,
          posZ: 0.3
        },
        {
          id: 5,
          posX: 0.37,
          posY: 0.3,
          posZ: 0.15
        },
        {
          id: 6,
          posX: 0.34,
          posY: 0.47,
          posZ: 0.3
        },
        {
          id: 7,
          posX: 0.4,
          posY: 0.62,
          posZ: 0.3
        },
        {
          id: 8,
          posX: 0.31,
          posY: 0.78,
          posZ: 0.3
        },
        //static top
        {
          id: 9,
          posX: 0.01,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 10,
          posX: 0.24,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 11,
          posX: 0.4,
          posY: -0.01,
          posZ: 0.0
        },
        //static bottom
        {
          id: 12,
          posX: 0.01,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 13,
          posX: 0.24,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 14,
          posX: 0.4,
          posY: 1.01,
          posZ: 0.0
        }
      ],

      pos_arr10: [
        //left
        {
          id: 0,
          posX: 0.04,
          posY: 0.2,
          posZ: 0.3
        },
        {
          id: 1,
          posX: 0.02,
          posY: 0.4,
          posZ: 0.15
        },
        {
          id: 2,
          posX: 0.04,
          posY: 0.6,
          posZ: 0.3
        },
        {
          id: 3,
          posX: 0.06,
          posY: 0.8,
          posZ: 0.15
        },
        {
          id: 4,
          posX: 0.2,
          posY: 0.27,
          posZ: 0.15
        },
        //right
        {
          id: 5,
          posX: 0.4,
          posY: 0.15,
          posZ: 0.3
        },
        {
          id: 6,
          posX: 0.37,
          posY: 0.3,
          posZ: 0.15
        },
        {
          id: 7,
          posX: 0.34,
          posY: 0.47,
          posZ: 0.3
        },
        {
          id: 8,
          posX: 0.4,
          posY: 0.62,
          posZ: 0.3
        },
        {
          id: 9,
          posX: 0.31,
          posY: 0.78,
          posZ: 0.3
        },
        //static top
        {
          id: 10,
          posX: 0.01,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 11,
          posX: 0.24,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 12,
          posX: 0.4,
          posY: -0.01,
          posZ: 0.0
        },
        //static bottom
        {
          id: 13,
          posX: 0.01,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 14,
          posX: 0.24,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 15,
          posX: 0.4,
          posY: 1.01,
          posZ: 0.0
        }
      ],

      pos_arr11: [
        //left
        {
          id: 0,
          posX: 0.04,
          posY: 0.2,
          posZ: 0.3
        },
        {
          id: 1,
          posX: 0.02,
          posY: 0.4,
          posZ: 0.15
        },
        {
          id: 2,
          posX: 0.04,
          posY: 0.6,
          posZ: 0.3
        },
        {
          id: 3,
          posX: 0.06,
          posY: 0.8,
          posZ: 0.15
        },
        {
          id: 4,
          posX: 0.2,
          posY: 0.27,
          posZ: 0.15
        },
        {
          id: 5,
          posX: 0.17,
          posY: 0.7,
          posZ: 0.15
        },
        //right
        {
          id: 6,
          posX: 0.4,
          posY: 0.15,
          posZ: 0.3
        },
        {
          id: 7,
          posX: 0.37,
          posY: 0.3,
          posZ: 0.15
        },
        {
          id: 8,
          posX: 0.34,
          posY: 0.47,
          posZ: 0.3
        },
        {
          id: 9,
          posX: 0.4,
          posY: 0.62,
          posZ: 0.3
        },
        {
          id: 10,
          posX: 0.31,
          posY: 0.78,
          posZ: 0.3
        },
        //static top
        {
          id: 11,
          posX: 0.01,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 12,
          posX: 0.24,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 13,
          posX: 0.4,
          posY: -0.01,
          posZ: 0.0
        },
        //static bottom
        {
          id: 14,
          posX: 0.01,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 15,
          posX: 0.24,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 16,
          posX: 0.4,
          posY: 1.01,
          posZ: 0.0
        }
      ],

      pos_arr12: [
        //left
        {
          id: 0,
          posX: 0.04,
          posY: 0.2,
          posZ: 0.3
        },
        {
          id: 1,
          posX: 0.02,
          posY: 0.4,
          posZ: 0.15
        },
        {
          id: 2,
          posX: 0.04,
          posY: 0.6,
          posZ: 0.3
        },
        {
          id: 3,
          posX: 0.06,
          posY: 0.8,
          posZ: 0.15
        },
        {
          id: 4,
          posX: 0.2,
          posY: 0.27,
          posZ: 0.15
        },
        {
          id: 5,
          posX: 0.17,
          posY: 0.39,
          posZ: 0.15
        },
        //right
        {
          id: 6,
          posX: 0.4,
          posY: 0.15,
          posZ: 0.3
        },
        {
          id: 7,
          posX: 0.37,
          posY: 0.3,
          posZ: 0.15
        },
        {
          id: 8,
          posX: 0.34,
          posY: 0.47,
          posZ: 0.3
        },
        {
          id: 9,
          posX: 0.4,
          posY: 0.62,
          posZ: 0.3
        },
        {
          id: 10,
          posX: 0.31,
          posY: 0.68,
          posZ: 0.3
        },
        {
          id: 10,
          posX: 0.29,
          posY: 0.73,
          posZ: 0.3
        },
        {
          id: 11,
          posX: 0.15,
          posY: 0.78,
          posZ: 0.3
        },
        //static top
        {
          id: 12,
          posX: 0.01,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 13,
          posX: 0.24,
          posY: -0.01,
          posZ: 0.0
        },
        {
          id: 14,
          posX: 0.4,
          posY: -0.01,
          posZ: 0.0
        },
        //static bottom
        {
          id: 15,
          posX: 0.01,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 16,
          posX: 0.24,
          posY: 1.01,
          posZ: 0.0
        },
        {
          id: 17,
          posX: 0.4,
          posY: 1.01,
          posZ: 0.0
        }
      ]
}